import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Navbar from '../Navbar/Navbar';
import Sidebar from '../Navbar/Sidebar';
import Footer from '../Navbar/Footer';
import Swal from 'sweetalert2';
import { API_URL } from '../../constants';

const PackageDetailsView = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [packageDetails, setPackageDetails] = useState(null);

    useEffect(() => {
        const isLoggedIn = sessionStorage.getItem('isLoggedIn');
        const storedUser = JSON.parse(sessionStorage.getItem('loggedinUser'));

        if (isLoggedIn && storedUser) {
            setUser(storedUser);
            fetchPackageDetails(id, storedUser.diagnosticCenterName);
        } else {
            navigate('/');
        }
    }, [id, navigate]);

    const fetchPackageDetails = async (packageId, diagnosticCenterName) => {
        try {
            const response = await fetch(`${API_URL}/diagnostic-package-details/${packageId}/${diagnosticCenterName}`);
            const data = await response.json();
            if (data.success === "error") {
                throw new Error(data.error);
            }
            setPackageDetails(data.diagnosticPackageDetails);
        } catch (error) {
            console.error("Error fetching package details:", error);
            Swal.fire("Error!", error.message, "error");
        }
    };

    if (!packageDetails) {
        return <div>Loading...</div>;
    }

    return (
        <div className="container-scroller">
            <Navbar />
            <div className="container-fluid page-body-wrapper">
                <Sidebar loggedInUser={user} />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="page-header">
                            <h3 className="page-title">Package Details</h3>
                        </div>
                        <div className="row grid-margin">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4>Package Name: {packageDetails.packageName}</h4>
                                        <h5>Diagnostic Center: {packageDetails.diagnosticCenterName}</h5>
                                        <ul style={{listStyle:'number'}}>
                                            {packageDetails.packageDetails.map((detail, index) => (
                                                <li key={index}>{detail}</li>
                                            ))}
                                        </ul>
                                        <button className="btn btn-primary" onClick={() => navigate(-1)}>Back</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default PackageDetailsView;
