import React, { useEffect, useState } from "react";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Navbar/Sidebar";
import Footer from "../Navbar/Footer";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { API_URL } from "../../constants";
import Swal from 'sweetalert2';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './PharmacyMedicineDetails.css'; // Import CSS file for custom styles

const PharmacyMedicineDetails = () => {
    const navigate = useNavigate();
    const { itemId } = useParams();
    const [pharmacyMedicine, setPharmacyMedicine] = useState(null);

    useEffect(() => {
        const fetchPharmacyMedicineDetails = async () => {
            try {
                const response = await axios.get(`${API_URL}/pharmacy-medicine/${itemId}`);
                if (response.data.success) {
                    setPharmacyMedicine(response.data.pharmacyMedicine);
                } else {
                    console.error('Failed to fetch pharmacy medicine details:', response.data.error);
                    Swal.fire("Error!", "Failed to fetch pharmacy medicine details. Please try again later.", "error").then(() => {
                        navigate('/PharmacyMedicine');
                    });
                }
            } catch (error) {
                console.error('Error fetching pharmacy medicine details:', error);
                Swal.fire("Error!", "Failed to fetch pharmacy medicine details. Please try again later.", "error").then(() => {
                    navigate('/PharmacyMedicine');
                });
            }
        };

        fetchPharmacyMedicineDetails();
    }, [itemId, navigate]);

    const formatDate = (dateString) => {
        if (!dateString) return "";
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    return (
        <div className="container-scroller">
            <Navbar />
            <div className="container-fluid page-body-wrapper">
                <Sidebar />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="row grid-margin">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h3>Product Details</h3>
                                        {pharmacyMedicine && (
                                            <React.Fragment>
                                                <div className="row grid-margin">
                                                    <div className="col-md-6 d-flex justify-content-center align-items-center">
                                                        {pharmacyMedicine.images && (
                                                            <Carousel showThumbs={false} showIndicators={false} showStatus={false} renderArrowPrev={(onClickHandler, hasPrev, label) =>
                                                                hasPrev && (
                                                                    <button type="button" onClick={onClickHandler} title={label} className="carousel-button carousel-button-prev">
                                                                        <i className="fas fa-chevron-left"></i>
                                                                    </button>
                                                                )
                                                            } renderArrowNext={(onClickHandler, hasNext, label) =>
                                                                hasNext && (
                                                                    <button type="button" onClick={onClickHandler} title={label} className="carousel-button carousel-button-next">
                                                                        <i className="fas fa-chevron-right"></i>
                                                                    </button>
                                                                )
                                                            }>
                                                                {pharmacyMedicine.images.map((image, index) => (
                                                                    <div key={index} style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                        <img style={{ maxHeight: '80%', maxWidth: '80%' }} src={`data:image/png;base64,${image}`} alt={`Medicine Image ${index + 1}`} />
                                                                    </div>
                                                                ))}
                                                            </Carousel>
                                                        )}
                                                    </div>

                                                    <div className="col-md-6">
                                                        <React.Fragment>
                                                            <div>
                                                                <h4>{pharmacyMedicine.medicineName}</h4>
                                                            </div>
                                                            <div>
                                                                <button className="btn btn-primary">{pharmacyMedicine.category}</button>
                                                            </div>
                                                            <div className="py-2 border-bottom">
                                                                <ul className="nav profile-navbar" style={{ display: 'flex', alignItems: 'center', listStyleType: 'none', padding: '0', margin: '0' }}>
                                                                    <li className="nav-item" style={{ marginRight: '10px' }}>
                                                                        <p style={{ fontSize: '18px', fontWeight: '700', margin: '0' }} className="nav-link d-flex align-items-center">
                                                                            ₹ {pharmacyMedicine.discountPrice}/-
                                                                        </p>
                                                                    </li>
                                                                    <li className="nav-item" style={{ marginRight: '10px' }}>
                                                                        <p style={{ fontSize: '18px', fontWeight: '600', textDecorationLine: 'line-through', textDecorationThickness: '3px', color: "darkgray", margin: '0' }} className="nav-link d-flex align-items-center">
                                                                            ₹ {pharmacyMedicine.actualPrice}/-
                                                                        </p>
                                                                    </li>
                                                                    <li className="nav-item">
                                                                        <p style={{ fontSize: '18px', fontWeight: '600', borderRadius: '30px', margin: '0' }} className="d-flex align-items-center badge badge-success">
                                                                            {pharmacyMedicine.discountOffer} % OFF
                                                                        </p>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div className="py-4">
                                                                <p className="clearfix">
                                                                    <span className="float-left">Manufacturer Name</span>
                                                                    <span className="float-right text-muted">{pharmacyMedicine.manufacturerName}</span>
                                                                </p>
                                                                <p className="clearfix">
                                                                    <span className="float-left">Manufacturer Address</span>
                                                                    <span className="float-right text-muted">{pharmacyMedicine.manufacturerAddress}</span>
                                                                </p>
                                                                <p className="clearfix">
                                                                    <span className="float-left">Country Of Origin</span>
                                                                    <span className="float-right text-muted">{pharmacyMedicine.countryOfOrigin}</span>
                                                                </p>
                                                                <p className="clearfix">
                                                                    <span className="float-left">Expires On or After</span>
                                                                    <span className="float-right text-muted">{formatDate(pharmacyMedicine.expireDate)}</span>
                                                                </p>
                                                            </div>
                                                        </React.Fragment>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default PharmacyMedicineDetails;
