import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Navbar/Sidebar";
import Footer from "../Navbar/Footer";
import axios from "axios";
import { API_URL } from "../../constants";
import Swal from 'sweetalert2';

const CreateMenuItems = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [itemName, setItemName] = useState('');
    const [price, setPrice] = useState('');
    const [category, setCategory] = useState('');
    const [type, setType] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState(null);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        // Check if user is logged in and retrieve stored user data from session storage
        const isLoggedIn = sessionStorage.getItem('isLoggedIn');
        const storedUser = JSON.parse(sessionStorage.getItem('loggedinUser'));

        if (isLoggedIn && storedUser) {
            setUser(storedUser);
            fetchCategories(storedUser._id, storedUser.restaurantName);
        } else {
            // Redirect to login page if user is not logged in or user data is not available
            Swal.fire("Error!", "No access for this page. Please login and access.", "error").then(() => {
                navigate('/');
            });
        }
    }, []);

    // Function to fetch categories for the user's restaurant
    const fetchCategories = async (userId, restaurantName) => {
        try {
            const response = await axios.get(`${API_URL}/categories`);
            if (response.data.success) {
                setCategories(response.data.categories);
            } else {
                console.error('Failed to fetch categories:', response.data.error);
            }
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    // Function to handle image file selection
    const handleImageChange = (e) => {
        // Set the selected image file
        setImage(e.target.files[0]);
    };

    // Function to handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Show creating menu item message using SweetAlert
        Swal.fire({
            icon: 'info',
            title: 'Creating Menu Item...',
            text: 'Please wait while we create the menu item.',
            showConfirmButton: false,
            allowOutsideClick: false,
        });

        try {
            // Client-side validation
            if (!itemName || !price || !category || !type || !description || !image) {
                Swal.fire("Error!", "All fields and image are required.", "error");
                return;
            }
    
            // Prepare form data to send to the server
            const formData = new FormData();
            formData.append('userId', user._id);
            formData.append('restaurantName', user.restaurantName);
            formData.append('itemName', itemName);
            formData.append('price', price);
            formData.append('category', category);
            formData.append('type', type);
            formData.append('description', description); // Adding description to form data
            formData.append('img', image); // Append image file to form data
    
            // Send POST request to create menu item
            const response = await axios.post(`${API_URL}/menu-items`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data' // Set content type as multipart/form-data
                }
            });
    
            if (response.data.success) {
                // Close the creating menu item message
                Swal.close();
    
                // Show success message
                Swal.fire("Success!", "Menu item created successfully.", "success").then(() => {
                    navigate('/menuItems'); // Redirect to menuItems page on success
                });
            } else {
                // Close the creating menu item message
                Swal.close();
    
                // Show error message from server
                Swal.fire("Error!", response.data.error, "error");
            }
        } catch (error) {
            console.error('Error creating menu item:', error);
    
            // Close the creating menu item message
            Swal.close();
    
            // Show error message
            Swal.fire("Error!", error.response.data.error, "error");
        }
    };

    return (
        <div className="container-scroller">
            <Navbar />
            <div className="container-fluid page-body-wrapper">
                <Sidebar loggedInUser={user} />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="page-header">
                            <h3 className="page-title">Create Menu Item</h3>
                        </div>
                        <div className="row grid-margin">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <form onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <label htmlFor="itemName">Item Name</label>
                                                <input type="text" className="form-control" id="itemName" value={itemName} onChange={(e) => setItemName(e.target.value)} required />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="price">Price</label>
                                                <input type="number" className="form-control" id="price" value={price} onChange={(e) => setPrice(e.target.value)} required />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="category">Category</label>
                                                <select className="form-control" id="category" value={category} onChange={(e) => setCategory(e.target.value)} required>
                                                    <option value="">Select Category</option>
                                                    {categories.map((cat, index) => (
                                                        <option key={index} value={cat.categoryName}>{cat.categoryName}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="type">Type</label>
                                                <select className="form-control" id="type" value={type} onChange={(e) => setType(e.target.value)} required>
                                                    <option value="">Select Type</option>
                                                    <option value="Veg">Veg</option>
                                                    <option value="Non-Veg">Non-Veg</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="description">Description</label>
                                                <textarea className="form-control" id="description" value={description} onChange={(e) => setDescription(e.target.value)} required />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="image">Image</label>
                                                <input type="file" className="form-control-file" id="image" name="img" accept="image/*" onChange={handleImageChange} required />
                                            </div>
                                            <button type="submit" className="btn btn-primary">Create</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default CreateMenuItems;
