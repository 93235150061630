import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Navbar/Sidebar";
import Footer from "../Navbar/Footer";
import axios from "axios";
import { API_URL } from "../../constants";
import Swal from 'sweetalert2';

const CreatePharmacyMedicine = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [pharmacyName, setPharmacyName] = useState('');
    const [medicineName, setMedicineName] = useState('');
    const [manufacturerName, setManufacturerName] = useState('');
    const [manufacturerAddress, setManufacturerAddress] = useState('');
    const [manufacturedDate, setManufacturedDate] = useState('');
    const [expireDate, setExpireDate] = useState('');
    const [countryOfOrigin, setCountryOfOrigin] = useState('');
    const [actualPrice, setActualPrice] = useState('');
    const [discountPrice, setDiscountPrice] = useState('');
    const [discountOffer, setDiscountOffer] = useState('');
    const [availabilityCount, setAvailabilityCount] = useState('');
    const [category, setCategory] = useState('');
    const [description, setDescription] = useState('');
    const [images, setImages] = useState([]); // Modified to store multiple file objects
    const [categories, setCategories] = useState([]);

    // List of countries
    const countries = [
        "United States", "United Kingdom", "Canada", "Australia", "India", "Germany", "France", "Italy", "Spain", "Japan", "China"
        // Add more countries as needed
    ];

    useEffect(() => {
        const isLoggedIn = sessionStorage.getItem('isLoggedIn');
        const storedUser = JSON.parse(sessionStorage.getItem('loggedinUser'));

        if (isLoggedIn && storedUser) {
            setUser(storedUser);
            fetchCategories(storedUser._id, storedUser.pharmacyName);
        } else {
            Swal.fire("Error!", "No access for this page. Please login and access.", "error").then(() => {
                navigate('/');
            });
        }
    }, []);

    const fetchCategories = async (userId, pharmacyName) => {
        try {
            const response = await axios.get(`${API_URL}/pharmacy-categories/${userId}/${pharmacyName}`);
            if (response.data.success) {
                setCategories(response.data.categories);
            } else {
                console.error('Failed to fetch categories:', response.data.error);
            }
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    const handleImageChange = (e) => {
        // Set the selected image files
        setImages(Array.from(e.target.files));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Client-side validation
            if (!medicineName || !manufacturerName || !actualPrice || !discountPrice || !discountOffer || !availabilityCount || !category || !description || images.length === 0 || !manufacturerAddress || !manufacturedDate || !expireDate || !countryOfOrigin) {
                Swal.fire("Error!", "All fields and at least one image are required.", "error");
                return;
            }

            const formData = new FormData();
            formData.append('userId', user._id);
            formData.append('pharmacyName', user.pharmacyName);
            formData.append('medicineName', medicineName);
            formData.append('manufacturerName', manufacturerName);
            formData.append('actualPrice', actualPrice);
            formData.append('discountPrice', discountPrice);
            formData.append('discountOffer', discountOffer);
            formData.append('availabilityCount', availabilityCount);
            formData.append('category', category);
            formData.append('description', description);
            formData.append('manufacturerAddress', manufacturerAddress);
            formData.append('manufacturedDate', manufacturedDate);
            formData.append('expireDate', expireDate);
            formData.append('countryOfOrigin', countryOfOrigin);
            images.forEach((img, index) => {
                formData.append(`imgs`, img); // Append each image file to form data
            });

            const response = await axios.post(`${API_URL}/pharmacy-medicines`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data' // Set content type as multipart/form-data
                }
            });

            if (response.data.success) {
                Swal.fire("Success!", response.data.message, "success").then(() => {
                    navigate('/PharmacyMedicine');
                });
            } else {
                Swal.fire("Error!", response.data.error, "error");
            }
        } catch (error) {
            console.error('Error creating Product:', error);
            Swal.fire("Error!", error.response.data.error, "error");
        }
    };

    const calculateDiscountPrice = () => {
        if (actualPrice && discountOffer) {
            const calculatedDiscountPrice = (actualPrice - (actualPrice * discountOffer / 100)).toFixed(2);
            setDiscountPrice(calculatedDiscountPrice);
        }
    };

    return (
        <div className="container-scroller">
            <Navbar />
            <div className="container-fluid page-body-wrapper">
                <Sidebar loggedInUser={user} />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="page-header">
                            <h3 className="page-title">Create Product</h3>
                        </div>
                        <div className="row grid-margin">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <form onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <label htmlFor="medicineName">Product Name</label>
                                                <input type="text" className="form-control" id="medicineName" value={medicineName} onChange={(e) => setMedicineName(e.target.value)} required />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="manufacturerName">Manufacturer Name</label>
                                                <input type="text" className="form-control" id="manufacturerName" value={manufacturerName} onChange={(e) => setManufacturerName(e.target.value)} required />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="manufacturerAddress">Manufacturer Address</label>
                                                <textarea className="form-control" id="manufacturerAddress" value={manufacturerAddress} onChange={(e) => setManufacturerAddress(e.target.value)} required />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="actualPrice">Actual Price</label>
                                                <input type="number" className="form-control" id="actualPrice" value={actualPrice} onChange={(e) => setActualPrice(e.target.value)} required />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="discountOffer">Discount Offer (%)</label>
                                                <input type="number" className="form-control" id="discountOffer" value={discountOffer} onChange={(e) => setDiscountOffer(e.target.value)} onBlur={calculateDiscountPrice} required />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="discountPrice">Discount Price</label>
                                                <input type="number" className="form-control" id="discountPrice" value={discountPrice} readOnly />
                                            </div>

                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <label htmlFor="manufacturedDate">Manufactured Date</label>
                                                        <input type="date" className="form-control" id="manufacturedDate" value={manufacturedDate} onChange={(e) => setManufacturedDate(e.target.value)} required />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label htmlFor="expireDate">Expire Date</label>
                                                        <input type="date" className="form-control" id="expireDate" value={expireDate} onChange={(e) => setExpireDate(e.target.value)} required />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="category">Category</label>
                                                <select className="form-control" id="category" value={category} onChange={(e) => setCategory(e.target.value)} required>
                                                    <option value="">Select Category</option>
                                                    {categories.map((cat, index) => (
                                                        <option key={index} value={cat.categoryName}>{cat.categoryName}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="availabilityCount">Availability Count</label>
                                                <input type="number" className="form-control" id="availabilityCount" value={availabilityCount} onChange={(e) => setAvailabilityCount(e.target.value)} required />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="countryOfOrigin">Country Of Origin</label>
                                                <select className="form-control" id="countryOfOrigin" value={countryOfOrigin} onChange={(e) => setCountryOfOrigin(e.target.value)} required>
                                                    <option value="">Select Country</option>
                                                    {countries.map((country, index) => (
                                                        <option key={index} value={country}>{country}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="description">Description</label>
                                                <textarea className="form-control" id="description" value={description} onChange={(e) => setDescription(e.target.value)} required />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="images">Images</label>
                                                <input type="file" className="form-control-file" id="images" name="imgs" accept="image/*" multiple onChange={handleImageChange} required />
                                            </div>
                                            <button type="submit" className="btn btn-primary">Create</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default CreatePharmacyMedicine;
