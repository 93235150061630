import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Navbar/Sidebar";
import Footer from "../Navbar/Footer";
import axios from "axios";
import { API_URL } from "../../constants";
import Swal from 'sweetalert2';
import Toggle from 'react-toggle';
import "react-toggle/style.css";
import './MenuItems.css'; // Import CSS file for styles

const MenuItems = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [menuItems, setMenuItems] = useState([]);
    const [expandedCategories, setExpandedCategories] = useState({});

    useEffect(() => {
        const isLoggedIn = sessionStorage.getItem('isLoggedIn');
        const storedUser = JSON.parse(sessionStorage.getItem('loggedinUser'));

        if (isLoggedIn && storedUser) {
            setUser(storedUser);
            fetchMenuItems(storedUser._id, storedUser.restaurantName);
        } else {
            navigate('/');
        }
    }, []);

    const fetchMenuItems = async (userId, restaurantName) => {
        try {
            const response = await axios.get(`${API_URL}/menu-items/${userId}/${restaurantName}`);
            if (response.data.success) {
                setMenuItems(response.data.menuItems);
            } else {
                console.error('Failed to fetch menu items:', response.data.error);
            }
        } catch (error) {
            console.error('Error fetching menu items:', error);
        }
    };

    const handleCreate = () => {
        navigate('/CreateMenuItems');
    };

    const handleToggle = async (itemId) => {
        try {
            const updatedMenuItems = menuItems.map(category => ({
                ...category,
                items: category.items.map(item => {
                    if (item._id === itemId) {
                        return { ...item, availability: !item.availability };
                    }
                    return item;
                })
            }));
            setMenuItems(updatedMenuItems);
            // Update availability status in the backend
            const response = await axios.put(`${API_URL}/menu-items/${itemId}/toggle-availability`);
            if (response.data.success) {
                Swal.fire("Success!", "Availability status updated successfully.", "success");
            } else {
                console.error('Failed to update availability status:', response.data.error);
                Swal.fire("Error!", "Failed to update availability status. Please try again later.", "error");
            }
        } catch (error) {
            console.error('Error updating availability status:', error);
            Swal.fire("Error!", "Failed to update availability status. Please try again later.", "error");
        }
    };

    const handleEdit = (itemId) => {
        navigate(`/editMenuItem/${itemId}`);
    };

    const handleDelete = (itemId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this menu item!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await axios.delete(`${API_URL}/menu-items/${itemId}`);
                    if (response.data.success) {
                        const updatedMenuItems = menuItems.map(category => ({
                            ...category,
                            items: category.items.filter(item => item._id !== itemId)
                        }));
                        setMenuItems(updatedMenuItems);
                        Swal.fire({
                            title: 'Deleted!',
                            text: response.data.message || 'Menu item deleted successfully.',
                            icon: 'success'
                        });
                    } else {
                        console.error('Failed to delete menu item:', response.data.error);
                        Swal.fire({
                            title: 'Error!',
                            text: response.data.error || 'Failed to delete menu item. Please try again later.',
                            icon: 'error'
                        });
                    }
                } catch (error) {
                    console.error('Error deleting menu item:', error);
                    Swal.fire({
                        title: 'Error!',
                        text: 'Failed to delete menu item. Please try again later.',
                        icon: 'error'
                    });
                }
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire({
                    title: 'Cancelled',
                    text: 'Menu item deletion was cancelled.',
                    icon: 'info'
                });
            }
        });
    };

    const decodeBase64Image = (base64String) => {
        return `data:image/jpeg;base64,${base64String}`;
    };

    const toggleCategory = (categoryId) => {
        setExpandedCategories(prevState => ({
            ...prevState,
            [categoryId]: !prevState[categoryId]
        }));

        // Smooth scroll to the category when toggling
        const element = document.getElementById(`category-${categoryId}`);
        element.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className="container-scroller">
            <Navbar />
            <div className="container-fluid page-body-wrapper">
                <Sidebar loggedInUser={user} />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="page-header">
                            <h3 className="page-title">Menu Items</h3>
                            <button className="btn btn-primary" onClick={handleCreate}>Create Menu Item</button>
                        </div>
                        <div className="row grid-margin">
                            <div className="col-12">
                                {menuItems.length === 0 ? (
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title" style={{fontWeight:'700'}}>No menu items created.</h4>
                                        </div>
                                    </div>
                                ) : (
                                    menuItems.map(category => (
                                        <div className="card" key={category._id} id={`category-${category._id}`}>
                                            <div className="card-body">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <h4 style={{fontWeight:"500", fontSize:"18px", letterSpacing:"-0.3px"}} className="card-title">{category._id}</h4>
                                                    <button
                                                        className="btn btn-link btn-sm"
                                                        onClick={() => toggleCategory(category._id)}
                                                    >
                                                        {expandedCategories[category._id] ? <i style={{color:"#000"}} className="fas fa-chevron-up"></i> : <i style={{color:"#000"}} className="fas fa-chevron-down"></i>}
                                                    </button>
                                                </div>
                                                {expandedCategories[category._id] && (
                                                    <div className="table-responsive">
                                                        <table className="table sortable-table">
                                                            <thead>
                                                                <tr>
                                                                    <th>ID</th>
                                                                    <th>Item Name</th>
                                                                    <th>Price</th>
                                                                    <th>Type</th>
                                                                    <th>Description</th>
                                                                    <th>Ratings</th>
                                                                    <th>Image</th>
                                                                    <th>Available</th>
                                                                    <th>Status</th>
                                                                    <th>Actions</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {category.items.map(item => (
                                                                    <tr key={item._id}>
                                                                        <td>{item._id}</td>
                                                                        <td>{item.itemName}</td>
                                                                        <td>₹{item.price}</td>
                                                                        <td>{item.type}</td>
                                                                        <td>{item.description}</td>
                                                                        <td>{item.ratings}</td>
                                                                        <td><img src={decodeBase64Image(item.image)} alt={item.itemName} /></td>
                                                                        <td>{item.availability ? "Available" : "Not Available"}</td>
                                                                        <td>
                                                                            <Toggle
                                                                                checked={item.availability}
                                                                                onChange={() => handleToggle(item._id)}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <button className="btn btn-primary btn-rounded btn-sm mr-2" onClick={() => handleEdit(item._id)}>Edit</button>
                                                                            <button className="btn btn-light btn-rounded btn-sm" onClick={() => handleDelete(item._id)}>Delete</button>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ))
                                )}
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default MenuItems;
