import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Navbar/Sidebar";
import Footer from "../Navbar/Footer";
import axios from "axios";
import { API_URL } from "../../constants";
import Swal from 'sweetalert2';
import Toggle from 'react-toggle';
import "react-toggle/style.css";

const PharmacyMedicineEdit = () => {
    const navigate = useNavigate();
    const { itemId } = useParams();
    const [user, setUser] = useState(null);
    const [item, setItem] = useState({
        medicineName: "",
        manufacturerName: "",
        manufacturerAddress: "",
        manufacturedDate: "",
        expireDate: "",
        countryOfOrigin: "",
        actualPrice: "",
        discountPrice: "",
        discountOffer: "",
        availabilityCount: "",
        category: "",
        description: "",
        ratings: "",
        images: [], // Changed to an array to handle multiple images
        availability: false
    });
    const [imageFiles, setImageFiles] = useState([]); // State to handle multiple image files
    const [removeImages, setRemoveImages] = useState(false); // State to track whether to remove all images
    const [categories, setCategories] = useState([]);

    // List of countries
    const countries = [
        "United States", "United Kingdom", "Canada", "Australia", "India", "Germany", "France", "Italy", "Spain", "Japan", "China"
        // Add more countries as needed
    ];

    useEffect(() => {
        const fetchPharmacyMedicine = async (itemId) => {
            try {
                const response = await axios.get(`${API_URL}/pharmacy-medicine/${itemId}`);
                if (response.data.success) {
                    const pharmacyMedicine = response.data.pharmacyMedicine;
                    setItem({
                        ...pharmacyMedicine,
                        expireDate: pharmacyMedicine.expireDate ? pharmacyMedicine.expireDate.split('T')[0] : '', // Format the date to remove time part
                        manufacturedDate: pharmacyMedicine.manufacturedDate ? pharmacyMedicine.manufacturedDate.split('T')[0] : '' // Format the date to remove time part
                    });
                } else {
                    console.error('Failed to fetch pharmacy Medicine:', response.data.error);
                    Swal.fire("Error!", "Failed to fetch pharmacy Medicine. Please try again later.", "error");
                }
            } catch (error) {
                console.error('Error fetching pharmacy Medicine:', error);
                Swal.fire("Error!", "Failed to fetch pharmacy Medicine. Please try again later.", "error");
            }
        };

        const isLoggedIn = sessionStorage.getItem('isLoggedIn');
        const storedUser = JSON.parse(sessionStorage.getItem('loggedinUser'));

        if (isLoggedIn && storedUser) {
            setUser(storedUser);
            fetchCategories(storedUser._id, storedUser.pharmacyName);
        } else {
            Swal.fire("Error!", "No access for this page. Please login and access.", "error").then(() => {
                navigate('/');
            });
        }

        if (itemId) {
            fetchPharmacyMedicine(itemId);
        }
    }, [itemId]);

    const fetchCategories = async (userId, pharmacyName) => {
        try {
            const response = await axios.get(`${API_URL}/pharmacy-categories/${userId}/${pharmacyName}`);
            if (response.data.success) {
                setCategories(response.data.categories);
            } else {
                console.error('Failed to fetch categories:', response.data.error);
            }
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === "discountOffer") {
            // Calculate discountPrice based on actualPrice and discountOffer
            const actualPrice = parseFloat(item.actualPrice);
            const discountOffer = parseFloat(value);
            const discountPrice = ((100 - discountOffer) / 100) * actualPrice;
            setItem(prevItem => ({
                ...prevItem,
                discountOffer,
                discountPrice: discountPrice.toFixed(2) // Set discountPrice with two decimal places
            }));
        } else {
            setItem(prevItem => ({
                ...prevItem,
                [name]: value
            }));
        }
    };

    const handleImageChange = (e) => {
        setImageFiles(Array.from(e.target.files)); // Store multiple image files
    };

    const handleRemoveImagesChange = (e) => {
        setRemoveImages(e.target.checked);
    };

    const handleUpdate = async () => {
        try {
            // Validate if removeImages is selected and no new images are uploaded
            if (removeImages && imageFiles.length === 0) {
                Swal.fire("Error!", "Images are required if you choose to remove all existing images.", "error");
                return;
            }
    
            const formData = new FormData();
            formData.append('medicineName', item.medicineName);
            formData.append('manufacturerName', item.manufacturerName);
            formData.append('manufacturerAddress', item.manufacturerAddress);
            formData.append('manufacturedDate', item.manufacturedDate);
            formData.append('expireDate', item.expireDate);
            formData.append('countryOfOrigin', item.countryOfOrigin);
            formData.append('actualPrice', item.actualPrice);
            formData.append('discountPrice', item.discountPrice);
            formData.append('discountOffer', item.discountOffer);
            formData.append('availabilityCount', item.availabilityCount);
            formData.append('category', item.category);
            formData.append('description', item.description);
            formData.append('ratings', item.ratings);
    
            if (removeImages) {
                formData.append('removeImages', true);
                if (imageFiles.length > 0) {
                    imageFiles.forEach(file => {
                        formData.append('images', file);
                    });
                }
            } else {
                imageFiles.forEach(file => {
                    formData.append('images', file);
                });
            }
    
            formData.append('availability', item.availability);
    
            const response = await axios.put(`${API_URL}/pharmacy-medicine/${itemId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
    
            if (response.data.success) {
                Swal.fire("Success!", response.data.message, "success");
                navigate('/PharmacyMedicine');
            } else {
                console.error('Failed to update Pharmacy Medicine:', response.data.error);
                Swal.fire("Error!", response.data.error, "error");
            }
        } catch (error) {
            console.error('Error updating Pharmacy Medicine:', error);
            Swal.fire("Error!", error.response.data.error, "error");
        }
    };
    
    return (
        <div className="container-scroller">
            <Navbar />
            <div className="container-fluid page-body-wrapper">
                <Sidebar />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="page-header">
                            <h3 className="page-title">Edit Pharmacy Medicine</h3>
                        </div>
                        <div className="row grid-margin">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="form-group">
                                            <label>Product Name</label>
                                            <input type="text" className="form-control" name="medicineName" value={item.medicineName} onChange={handleInputChange} />
                                        </div>

                                        <div className="form-group">
                                            <label>Manufacturer Name</label>
                                            <input type="text" className="form-control" name="manufacturerName" value={item.manufacturerName} onChange={handleInputChange} />
                                        </div>

                                        <div className="form-group">
                                            <label>Manufacturer Address</label>
                                            <input type="text" className="form-control" name="manufacturerAddress" value={item.manufacturerAddress} onChange={handleInputChange} />
                                        </div>

                                        <div className="form-group">
                                            <label>Manufactured Date</label>
                                            <input type="date" className="form-control" name="manufacturedDate" value={item.manufacturedDate} onChange={handleInputChange} />
                                        </div>

                                        <div className="form-group">
                                            <label>Expire Date</label>
                                            <input type="date" className="form-control" name="expireDate" value={item.expireDate} onChange={handleInputChange} />
                                        </div>

                                        <div className="form-group">
                                            <label>Actual Price</label>
                                            <input type="number" className="form-control" name="actualPrice" value={item.actualPrice} onChange={handleInputChange} />
                                        </div>

                                        <div className="form-group">
                                            <label>Discount Offer %</label>
                                            <input type="number" className="form-control" name="discountOffer" value={item.discountOffer} onChange={handleInputChange} />
                                        </div>

                                        <div className="form-group">
                                            <label>Discount Price</label>
                                            <input type="number" className="form-control" name="discountPrice" value={item.discountPrice} onChange={handleInputChange} disabled/>
                                        </div>

                                        <div className="form-group">
                                            <label>Country Of Origin</label>
                                            <select className="form-control" name="countryOfOrigin" value={item.countryOfOrigin} onChange={handleInputChange} required>
                                                <option value="">Select Country</option>
                                                {countries.map((country, index) => (
                                                    <option key={index} value={country}>{country}</option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className="form-group">
                                            <label>Description</label>
                                            <textarea type="text" rows="5" className="form-control" name="description" value={item.description} onChange={handleInputChange} />
                                        </div>
                                        <div className="form-group">
                                            <label>Ratings</label>
                                            <input type="text" className="form-control" name="ratings" value={item.ratings} onChange={handleInputChange} />
                                        </div>
                                        <div className="form-group">
                                            <label>Images</label>
                                            {item.images && item.images.map((image, index) => (
                                                <div key={index}>
                                                    <img src={`data:image/png;base64,${image}`} alt={item.medicineName} style={{ marginTop: 10, width: 200 }} />
                                                    <p>Image {index + 1}</p>
                                                </div>
                                            ))}
                                            <input type="file" className="form-control" name="images" onChange={handleImageChange} multiple />
                                        </div>
                                        <div className="form-group">
                                            <label>
                                                <input type="checkbox" checked={removeImages} onChange={handleRemoveImagesChange} />
                                                Remove All Images
                                            </label>
                                        </div>

                                        <div className="form-group">
                                            <label>Availability</label>
                                            <Toggle
                                                checked={item.availability}
                                                onChange={() => setItem(prevItem => ({ ...prevItem, availability: !prevItem.availability }))}
                                            />
                                        </div>
                                        <button className="btn btn-primary" onClick={handleUpdate}>Update</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default PharmacyMedicineEdit;
