import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Navbar/Sidebar";
import Footer from "../Navbar/Footer";
import axios from "axios";
import { API_URL } from "../../constants";
import Swal from 'sweetalert2';
import Toggle from 'react-toggle';
import "react-toggle/style.css";
import '../FoodPartner/MenuItems.css';

const PharmacyMedicine = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [pharmacyMedicine, setPharmacyMedicine] = useState([]);
    const [expandedCategories, setExpandedCategories] = useState({});

    useEffect(() => {
        const isLoggedIn = sessionStorage.getItem('isLoggedIn');
        const storedUser = JSON.parse(sessionStorage.getItem('loggedinUser'));

        if (isLoggedIn && storedUser) {
            setUser(storedUser);
            fetchPharmacyMedicine(storedUser._id, storedUser.pharmacyName);
        } else {
            navigate('/');
        }
    }, []);

    const fetchPharmacyMedicine = async (userId, pharmacyName) => {
        try {
            const response = await axios.get(`${API_URL}/pharmacy-medicine/${userId}/${pharmacyName}`);
            if (response.data.success) {
                setPharmacyMedicine(response.data.pharmacyMedicine);
            } else {
                console.error('Failed to fetch pharmacy medicine:', response.data.error);
            }
        } catch (error) {
            console.error('Error fetching pharmacy medicine:', error);
        }
    };

    const handleCreate = () => {
        navigate('/CreatePharmacyMedicine');
    };

    const handleToggle = async (itemId) => {
        try {
            const response = await axios.put(`${API_URL}/pharmacy-medicine/${itemId}/toggle-availability`);
            if (response.data.success) {
                const updatedPharmacyMedicine = pharmacyMedicine.map(category => ({
                    ...category,
                    items: category.items.map(item => {
                        if (item._id === itemId) {
                            return { ...item, availability: !item.availability };
                        }
                        return item;
                    })
                }));
                setPharmacyMedicine(updatedPharmacyMedicine);
                Swal.fire("Success!", "Availability status updated successfully.", "success");
            } else {
                console.error('Failed to update availability status:', response.data.error);
                Swal.fire("Error!", "Failed to update availability status. Please try again later.", "error");
            }
        } catch (error) {
            console.error('Error updating availability status:', error);
            Swal.fire("Error!", "Failed to update availability status. Please try again later.", "error");
        }
    };

    const handleEdit = (itemId) => {
        navigate(`/PharmacyMedicineEdit/${itemId}`);
    };

    const handleView = (itemId) => {
        navigate(`/PharmacyMedicineDetails/${itemId}`);
    };

    const handleDelete = async (itemId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this medicine!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await axios.delete(`${API_URL}/pharmacy-medicine/${itemId}`);
                    if (response.data.success) {
                        const updatedPharmacyMedicine = pharmacyMedicine.map(category => ({
                            ...category,
                            items: category.items.filter(item => item._id !== itemId)
                        }));
                        setPharmacyMedicine(updatedPharmacyMedicine);
                        Swal.fire("Deleted!", response.data.message, "success");
                    } else {
                        console.error('Failed to delete Medicine:', response.data.error);
                        Swal.fire("Error!", response.data.error, "error");
                    }
                } catch (error) {
                    console.error('Error deleting Medicine:', error);
                    Swal.fire("Error!", error.response.data.error, "error");
                }
            }
        });
    };

    const toggleCategory = (categoryId) => {
        setExpandedCategories(prevState => ({
            ...prevState,
            [categoryId]: !prevState[categoryId]
        }));

        // Smooth scroll to the category when toggling
        const element = document.getElementById(`category-${categoryId}`);
        element.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className="container-scroller">
            <Navbar />
            <div className="container-fluid page-body-wrapper">
                <Sidebar loggedInUser={user} />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="page-header">
                            <h3 className="page-title">Pharmacy Products</h3>
                            <button className="btn btn-primary" onClick={handleCreate}>Add Product</button>
                        </div>
                        <div className="row grid-margin">
                            <div className="col-12">
                                {pharmacyMedicine.map(category => (
                                    <div className="card" key={category._id} id={`category-${category._id}`}>
                                        <div className="card-body">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <h4 style={{ fontWeight: "700", fontSize: "20px", letterSpacing: "-0.3px" }} className="card-title">{category._id}</h4>
                                                <button
                                                    className="btn btn-link btn-sm"
                                                    onClick={() => toggleCategory(category._id)}
                                                >
                                                    {expandedCategories[category._id] ? <i style={{ color: "#000" }} className="fas fa-chevron-up"></i> : <i style={{ color: "#000" }} className="fas fa-chevron-down"></i>}
                                                </button>
                                            </div>
                                            {expandedCategories[category._id] && (
                                                <div className="table-responsive">
                                                    <table className="table sortable-table">
                                                        <thead>
                                                            <tr>
                                                                <th>ID</th>
                                                                <th>Medicine Name</th>
                                                                <th>Actual Price</th>
                                                                <th>Discount Price</th>
                                                                <th>Offer Discount</th>
                                                                <th>Category</th>
                                                                <th>Available</th>
                                                                <th>Available Count</th>
                                                                <th>Status</th>
                                                                <th>Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {category.items.map(item => (
                                                                <tr key={item._id}>
                                                                    <td>{item._id}</td>
                                                                    <td>{item.medicineName}</td>
                                                                    <td>₹ {item.actualPrice}</td>
                                                                    <td>₹ {item.discountPrice}</td>
                                                                    <td>{item.discountOffer}%</td>
                                                                    <td>{item.category}</td>
                                                                    <td style={{ fontWeight: "800", color: item.availability ? "green" : "red" }}>
                                                                        {item.availability ? "Available" : "Not Available"}
                                                                    </td>

                                                                    <td>{item.availabilityCount}</td>
                                                                    <td>
                                                                        <Toggle
                                                                            checked={item.availability}
                                                                            onChange={() => handleToggle(item._id)}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <button className="btn btn-primary btn-rounded btn-sm mr-2" onClick={() => handleEdit(item._id)}>Edit</button>
                                                                        <button className="btn btn-info btn-rounded btn-sm mr-2" onClick={() => handleView(item._id)}>View</button>
                                                                        <button className="btn btn-warning btn-rounded btn-sm" onClick={() => handleDelete(item._id)}>Delete</button>
                                                                    </td>

                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default PharmacyMedicine;