import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Navbar/Sidebar";
import Footer from "../Navbar/Footer";
import axios from "axios";
import { API_URL } from "../../constants";
import Swal from 'sweetalert2';
import Toggle from 'react-toggle';
import "react-toggle/style.css";

const EditMenuItem = () => {
    const navigate = useNavigate();
    const { itemId } = useParams();
    const [user, setUser] = useState(null);
    const [item, setItem] = useState({
        itemName: "",
        price: "",
        category: "",
        type: "",
        description: "",
        ratings: "",
        image: "",
        availability: false
    });
    const [imageFile, setImageFile] = useState(null);
    const [removeImage, setRemoveImage] = useState(false);
    const [categories, setCategories] = useState([]);
    const [typeOptions] = useState(["Veg", "Non-Veg"]);
    const [errors, setErrors] = useState({}); // State to hold field specific errors

    useEffect(() => {
        const fetchMenuItem = async (itemId) => {
            try {
                const response = await axios.get(`${API_URL}/menu-items/${itemId}`);
                if (response.data.success) {
                    setItem(response.data.menuItem);
                } else {
                    console.error('Failed to fetch menu item:', response.data.error);
                    Swal.fire("Error!", "Failed to fetch menu item. Please try again later.", "error");
                }
            } catch (error) {
                console.error('Error fetching menu item:', error);
                Swal.fire("Error!", "Failed to fetch menu item. Please try again later.", "error");
            }
        };

        const isLoggedIn = sessionStorage.getItem('isLoggedIn');
        const storedUser = JSON.parse(sessionStorage.getItem('loggedinUser'));

        if (isLoggedIn && storedUser) {
            setUser(storedUser);
            fetchCategories(storedUser._id, storedUser.restaurantName);
        } else {
            Swal.fire("Error!", "No access for this page. Please login and access.", "error").then(() => {
                navigate('/');
            });
        }

        if (itemId) {
            fetchMenuItem(itemId);
        }
    }, [itemId]);

    const fetchCategories = async (userId, restaurantName) => {
        try {
            const response = await axios.get(`${API_URL}/categories/${userId}/${restaurantName}`);
            if (response.data.success) {
                setCategories(response.data.categories);
            } else {
                console.error('Failed to fetch categories:', response.data.error);
            }
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setItem(prevItem => ({
            ...prevItem,
            [name]: value
        }));
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImageFile(file);
        }
    };

    const handleRemoveImageChange = (e) => {
        setRemoveImage(e.target.checked);
    };

    const handleUpdate = async (e) => {
        e.preventDefault();

        // Show creating menu item message using SweetAlert
        Swal.fire({
            icon: 'info',
            title: 'Updating Menu Item...',
            text: 'Please wait while we update the menu item.',
            showConfirmButton: false,
            allowOutsideClick: false,
        });
        try {
            const formData = new FormData();
            formData.append('itemName', item.itemName);
            formData.append('price', item.price);
            formData.append('category', item.category);
            formData.append('type', item.type);
            formData.append('description', item.description);
            formData.append('ratings', item.ratings);

            if (removeImage) {
                formData.append('removeImage', true);
            } else if (imageFile) {
                formData.append('image', imageFile);
            }

            formData.append('availability', item.availability);

            const response = await axios.put(`${API_URL}/menu-items/${itemId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data.success) {
                Swal.fire("Success!", response.data.success, "success").then(() => {
                    navigate('/menuItems');
                });
            } else {
                // Handle specific field errors from API
                if (response.data.error && response.data.error.fields) {
                    setErrors(response.data.error.fields);
                } else {
                    console.error('Failed to update menu item:', response.data.error);
                    Swal.fire("Error!", response.data.error.message || "Failed to update menu item. Please try again later.", "error");
                }
            }
        } catch (error) {
            console.error('Error updating menu item:', error);
            Swal.fire("Error!", error.response.data.error, "error");
        }
    };

    return (
        <div className="container-scroller">
            <Navbar />
            <div className="container-fluid page-body-wrapper">
                <Sidebar />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="page-header">
                            <h3 className="page-title">Edit Menu Item</h3>
                        </div>
                        <div className="row grid-margin">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="form-group">
                                            <label>Item Name</label>
                                            <input type="text" className="form-control" name="itemName" value={item.itemName} onChange={handleInputChange} required/>
                                            {errors.itemName && <p className="text-danger">{errors.itemName}</p>}
                                        </div>
                                        <div className="form-group">
                                            <label>Price</label>
                                            <input type="text" className="form-control" name="price" value={item.price} onChange={handleInputChange} required/>
                                            {errors.price && <p className="text-danger">{errors.price}</p>}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="category">Category</label>
                                            <select className="form-control" id="category" name="category" value={item.category} onChange={handleInputChange} required>
                                                <option value="">Select Category</option>
                                                {categories.map((category, index) => (
                                                    <option key={index} value={category.categoryName}>{category.categoryName}</option>
                                                ))}
                                            </select>
                                            {errors.category && <p className="text-danger">{errors.category}</p>}
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="type">Type</label>
                                            <select className="form-control" id="type" name="type" value={item.type} onChange={handleInputChange} required>
                                                <option value="">Select Type</option>
                                                {typeOptions.map((type, index) => (
                                                    <option key={index} value={type}>{type}</option>
                                                ))}
                                            </select>
                                            {errors.type && <p className="text-danger">{errors.type}</p>}
                                        </div>

                                        <div className="form-group">
                                            <label>Description</label>
                                            <textarea type="text" rows="5" className="form-control" name="description" value={item.description} onChange={handleInputChange} required/>
                                            {errors.description && <p className="text-danger">{errors.description}</p>}
                                        </div>
                                        <div className="form-group">
                                            <label>Ratings</label>
                                            <input type="text" className="form-control" name="ratings" value={item.ratings} onChange={handleInputChange} readOnly />
                                            {errors.ratings && <p className="text-danger">{errors.ratings}</p>}
                                        </div>
                                        <div className="form-group">
                                            <label>Image</label>
                                            {item.image && (
                                                <div>
                                                    <img src={`data:image/png;base64,${item.image}`} alt={item.itemName} style={{ marginTop: 10, width: 200 }} required/>
                                                    <p>Previous Image</p>
                                                </div>
                                            )}
                                            <input type="file" className="form-control" name="image" onChange={handleImageChange} />
                                            {errors.image && <p className="text-danger">{errors.image}</p>}
                                        </div>

                                        <div className="form-group">
                                            <label>Availability</label>
                                            <Toggle
                                                checked={item.availability}
                                                onChange={() => setItem(prevItem => ({ ...prevItem, availability: !prevItem.availability }))}
                                            />
                                            {errors.availability && <p className="text-danger">{errors.availability}</p>}
                                        </div>
                                        <button className="btn btn-primary" onClick={handleUpdate}>Update</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default EditMenuItem;
