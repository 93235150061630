import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { API_URL } from '../../constants';
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Navbar/Sidebar";
import Footer from "../Navbar/Footer";
import Swal from 'sweetalert2';

const UserDetails = () => {
    const { userId } = useParams();
    const navigate = useNavigate();
    const [thumbnails, setThumbnails] = useState({});
    const [userDetails, setUserDetails] = useState(null);
    const [error, setError] = useState('');

    useEffect(() => {
        const isLoggedIn = sessionStorage.getItem('isLoggedIn');
        const storedUser = sessionStorage.getItem('user');

        // Check if user is logged in and user data is available in session storage
        if (!(isLoggedIn && storedUser)) {
            // If user is not logged in, show error message and navigate back to login page
            Swal.fire("Error!", "No access for this page. Please login and access.", "error").then(() => {
                navigate('/');
            });
        }
    }, [navigate]);

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const response = await axios.get(`${API_URL}/users/${userId}`);
                setUserDetails(response.data.user);

                // Extract thumbnails based on userType
                const { userType, thumbnailFood, thumbnailLab, thumbnailPharmacy } = response.data.user;
                setThumbnails({
                    "Food Partner": thumbnailFood,
                    "Lab Technician": thumbnailLab,
                    "Pharmacy Partner": thumbnailPharmacy
                });
            } catch (error) {
                setError('Error fetching user details');
            }
        };

        fetchUserDetails();
    }, [userId]);

    const handleVerify = async () => {
        try {
            const response = await axios.put(`${API_URL}/users/${userId}/verify`, { isVerified: true, status: true });
            // Assuming successful verification, update state or perform any necessary actions
            setUserDetails({ ...userDetails, isVerified: true, status: true });
            Swal.fire("Success!", response.data.message, "success");
        } catch (error) {
            console.error('Error verifying user:', error);
            Swal.fire("Error!", error.response.data.error || "Failed to verify user. Please try again later.", "error");
        }
    };

    const handleNotVerify = async () => {
        try {
            const response = await axios.put(`${API_URL}/users/${userId}/not-verify`, { isVerified: false, status: false });
            // Assuming successful unverification, update state or perform any necessary actions
            setUserDetails({ ...userDetails, isVerified: false, status: false });
            Swal.fire("Success!", response.data.message, "success");
        } catch (error) {
            console.error('Error removing user verification:', error);
            Swal.fire("Error!", error.response.data.error || "Failed to remove user verification. Please try again later.", "error");
        }
    };

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <div className="container-scroller">
            <Navbar />
            <div className="container-fluid page-body-wrapper">
                <Sidebar />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="page-header">
                            <h3 className="page-title">Partner Details</h3>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>User ID</th>
                                                        <th>User Name</th>
                                                        <th>Mail</th>
                                                        <th>User Type</th>
                                                        <th>Phone Number</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{userDetails?._id}</td>
                                                        <td>{userDetails?.userName}</td>
                                                        <td>{userDetails?.email}</td>
                                                        <td>{userDetails?.userType}</td>
                                                        <td>{userDetails?.mobileNumber}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Render service details based on user type */}
                    {userDetails && userDetails.userType === 'Food Partner' && (
                        <div className="content-wrapper">
                            <div className="page-header">
                                <h3 className="page-title">Registered Service Details</h3>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <h4>Restaurant Image</h4>
                                            {userDetails.userType === 'Food Partner' && thumbnails['Food Partner'] &&
                                                <img width="30%" src={`data:image/png;base64,${thumbnails['Food Partner']}`} alt="Food Partner Thumbnail" />
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th>User ID</th>
                                                            <th>Restaurant Name</th>
                                                            <th>Restaurant Address</th>
                                                            <th>Restaurant Owner</th>
                                                            <th>Restaurant Email</th>
                                                            <th>Restaurant Mobile</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>{userDetails._id}</td>
                                                            <td>{userDetails.restaurantName}</td>
                                                            <td>{userDetails.restaurantAddress}</td>
                                                            <td>{userDetails.restaurantOwnerName}</td>
                                                            <td>{userDetails.restaurantEmail}</td>
                                                            <td>{userDetails.restaurantMobileNumber}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                                {/* Render document previews */}
                                                <div className="mt-3">
                                                    <h4>Document Previews</h4>
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th>Document Type</th>
                                                                <th>Preview</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>FSSAI</td>
                                                                <td>
                                                                    <button
                                                                        className="btn btn-primary"
                                                                        onClick={() => window.open(`${API_URL}/files/${encodeURIComponent(userDetails.FOODFSSAI)}`, '_blank')}
                                                                    >
                                                                        Preview
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>PAN</td>
                                                                <td>
                                                                    <button
                                                                        className="btn btn-primary"
                                                                        onClick={() => window.open(`${API_URL}/files/${encodeURIComponent(userDetails.FOODPAN)}`, '_blank')}
                                                                    >
                                                                        Preview
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>GSTIN</td>
                                                                <td>
                                                                    <button
                                                                        className="btn btn-primary"
                                                                        onClick={() => window.open(`${API_URL}/files/${encodeURIComponent(userDetails.FOODGSTIN)}`, '_blank')}
                                                                    >
                                                                        Preview
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="mt-3">
                                                <button className="btn btn-success mr-2" onClick={handleVerify}>Verify</button>
                                                <button className="btn btn-danger" onClick={handleNotVerify}>Not Verify</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    )}

                    {userDetails && userDetails.userType === 'Lab Technician' && (
                        <div className="content-wrapper">
                            <div className="page-header">
                                <h3 className="page-title">Registered Service Details</h3>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <h4>Diagnistic Center Image</h4>

                                            {userDetails.userType === 'Lab Technician' && thumbnails['Lab Technician'] &&
                                                <img width="30%" src={`data:image/png;base64,${thumbnails['Lab Technician']}`} alt="Lab Technician Thumbnail" />
                                            }

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th>User ID</th>
                                                            <th>Diagnostic Name</th>
                                                            <th>Diagnostic Owner</th>
                                                            <th>Diagnostic Email</th>
                                                            <th>Diagnostic Mobile</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>{userDetails._id}</td>
                                                            <td>{userDetails.diagnosticCenterName}</td>
                                                            <td>{userDetails.diagnosticOwnerName}</td>
                                                            <td>{userDetails.diagnosticEmail}</td>
                                                            <td>{userDetails.diagnosticMobileNumber}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                                {/* Render document previews */}
                                                <div className="mt-3">
                                                    <h4>Document Previews</h4>
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th>Document Type</th>
                                                                <th>Preview</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>DIAGNOSTIC LICENSE</td>
                                                                <td>
                                                                    <button
                                                                        className="btn btn-primary"
                                                                        onClick={() => window.open(`${API_URL}/files/${encodeURIComponent(userDetails.DIAHNOSTICLICENSE)}`, '_blank')}
                                                                    >
                                                                        Preview
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>PAN</td>
                                                                <td>
                                                                    <button
                                                                        className="btn btn-primary"
                                                                        onClick={() => window.open(`${API_URL}/files/${encodeURIComponent(userDetails.DIAHNOSTICPAN)}`, '_blank')}
                                                                    >
                                                                        Preview
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>GSTIN</td>
                                                                <td>
                                                                    <button
                                                                        className="btn btn-primary"
                                                                        onClick={() => window.open(`${API_URL}/files/${encodeURIComponent(userDetails.DIAGNOSTICGSTIN)}`, '_blank')}
                                                                    >
                                                                        Preview
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="mt-3">
                                                <button className="btn btn-success mr-2" onClick={handleVerify}>Verify</button>
                                                <button className="btn btn-danger" onClick={handleNotVerify}>Not Verify</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    )}

                    {userDetails && userDetails.userType === 'Pharmacy Partner' && (
                        <div className="content-wrapper">
                            <div className="page-header">
                                <h3 className="page-title">Registered Service Details</h3>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <h4>Pharmacy Image</h4>
                                            {userDetails.userType === 'Pharmacy Partner' && thumbnails['Pharmacy Partner'] &&
                                                <img width="30%" src={`data:image/png;base64,${thumbnails['Pharmacy Partner']}`} alt="Pharmacy Partner Thumbnail" />
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th>User ID</th>
                                                            <th>Pharmacy Name</th>
                                                            <th>Pharmacy Owner Name</th>
                                                            <th>Pharmacy Address</th>
                                                            <th>Pharmacy Mobile Number</th>
                                                            <th>Pharmacy Email</th>
                                                            <th>Pharmacy Description</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>{userDetails._id}</td>
                                                            <td>{userDetails.pharmacyName}</td>
                                                            <td>{userDetails.pharmacyOwnerName}</td>
                                                            <td>{userDetails.pharmacyAddress}</td>
                                                            <td>{userDetails.pharmacyMobileNumber}</td>
                                                            <td>{userDetails.pharmacyEmail}</td>
                                                            <td>{userDetails.pharmacyDescription}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                                {/* Render document previews */}
                                                <div className="mt-3">
                                                    <h4>Document Previews</h4>
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th>Document Type</th>
                                                                <th>Preview</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>PHARMACY LICENSE</td>
                                                                <td>
                                                                    <button
                                                                        className="btn btn-primary"
                                                                        onClick={() => window.open(`${API_URL}/files/${encodeURIComponent(userDetails.PHARMACYLICENSE)}`, '_blank')}
                                                                    >
                                                                        Preview
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>PHARMACY PAN</td>
                                                                <td>
                                                                    <button
                                                                        className="btn btn-primary"
                                                                        onClick={() => window.open(`${API_URL}/files/${encodeURIComponent(userDetails.PHARMACYPAN)}`, '_blank')}
                                                                    >
                                                                        Preview
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>PHARMACY GSTIN</td>
                                                                <td>
                                                                    <button
                                                                        className="btn btn-primary"
                                                                        onClick={() => window.open(`${API_URL}/files/${encodeURIComponent(userDetails.PHARMACYGSTIN)}`, '_blank')}
                                                                    >
                                                                        Preview
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="mt-3">
                                                <button className="btn btn-success mr-2" onClick={handleVerify}>Verify</button>
                                                <button className="btn btn-danger" onClick={handleNotVerify}>Not Verify</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    )}

                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default UserDetails;