import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Signin from './components/Signin/Signin';
import Signup from './components/Signup/Signup';
import Step2 from './components/Signup/Step2';
import Step3 from './components/Signup/Step3';
import AdminSignin from './components/Signin/AdminSignin';
import SuperAdmin from './components/Dashboard/SuperAdmin';
import FoodPartner from './components/FoodPartner/FoodPartner';
import LabTechnician from './components/LabTechnician/LabTechnician';
import Users from './components/Users/Users';
import UserDetails from './components/Users/UserDetails';
import VerifiedUsers from './components/Users/VerifiedUsers';
import NotVerifiedUsers from './components/Users/NotVerifiedUsers';
import Categories from './components/FoodPartner/Categories';
import MenuItems from './components/FoodPartner/MenuItems';
import CreateMenuItems from './components/FoodPartner/CreateMenuItems';
import EditMenuItem from "./components/FoodPartner/EditMenuItem";
import LabCategories from './components/LabTechnician/LabCategories';
import LabTests from './components/LabTechnician/LabTests';
import CreateLabTests from './components/LabTechnician/CreateLabTests';
import LabTestEdit from './components/LabTechnician/LabTestEdit';
import LabTestDetails from './components/LabTechnician/LabTestDetails';
import PackageName from './components/LabTechnician/PackageName';
import PackageDetailsSub from './components/LabTechnician/PackageDetailsSub';
import CreatePackageDetails from './components/LabTechnician/CreatePackageDetails ';
import PackageDetailsView from './components/LabTechnician/PackageDetailsView';
import EditPackageDetails from './components/LabTechnician/EditPackageDetails';

import PharmacyPartnerDashboard from './components/PharmacyPartner/PharmacyPartnerDashboard';
import PharmacyCategories from './components/PharmacyPartner/PharmacyCategories';
import PharmacyMedicine from './components/PharmacyPartner/PharmacyMedicine';
import CreatePharmacyMedicine from './components/PharmacyPartner/CreatePharmacyMedicine';
import PharmacyMedicineEdit from './components/PharmacyPartner/PharmacyMedicineEdit';
import PharmacyMedicineDetails from './components/PharmacyPartner/PharmacyMedicineDetails';


import 'react-toastify/dist/ReactToastify.css';

function App() {

  return (
    <div className="App">
      <ToastContainer theme='colored' position='top-right'></ToastContainer>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Signin/>}></Route>
          <Route path='/adminsignin' element={<AdminSignin/>}></Route>
          <Route path='/FoodPartnerDashboard' element={<FoodPartner/>}></Route>
          <Route path='/LabTechnicianDashboard' element={<LabTechnician/>}></Route>
          <Route path='/SuperAdminDashboard' element={<SuperAdmin/>}></Route>
          <Route path='/signup' element={<Signup/>}></Route>
          <Route path='/Signup-vendortype' element={<Step2/>}></Route>
          <Route path='/Signup-documents' element={<Step3/>}></Route>
          <Route path='/registeredUsers' element={<Users/>}></Route>
          <Route path="/user/:userId" element={<UserDetails />} />6
          <Route path='/VerifiedUsers' element={<VerifiedUsers/>}></Route>
          <Route path='/NotVerifiedUsers' element={<NotVerifiedUsers/>}></Route>

          <Route path='/foodCategories' element={<Categories/>}></Route>
          <Route path='/menuItems' element={<MenuItems/>}></Route>
          <Route path='/CreateMenuItems' element={<CreateMenuItems/>}></Route>
          <Route path="/editMenuItem/:itemId" element={<EditMenuItem/>}></Route>

          <Route path='/LabCategories' element={<LabCategories/>}></Route>
          <Route path='/LabTests' element={<LabTests/>}></Route>
          <Route path='/CreateLabTests' element={<CreateLabTests/>}></Route>
          <Route path='/LabTestEdit/:itemId' element={<LabTestEdit/>}></Route>
          <Route path="/LabTestDetails/:itemId" element={<LabTestDetails />} />
          <Route path='/packeages' element={<PackageName/>}></Route>
          <Route path='/package-details' element={<PackageDetailsSub/>}></Route>
          <Route path='/create-package-details' element={<CreatePackageDetails/>}></Route>
          <Route path="/package-details-view/:id" element={<PackageDetailsView />} />
          <Route path="/edit-package-details/:id" element={<EditPackageDetails />} />
          

          <Route path='/PharmacyPartnerDashboard' element={<PharmacyPartnerDashboard/>}></Route>
          <Route path='/PharmacyCategories' element={<PharmacyCategories/>}></Route>
          <Route path='/PharmacyMedicine' element={<PharmacyMedicine/>}></Route>
          <Route path='/CreatePharmacyMedicine' element={<CreatePharmacyMedicine/>}></Route>
          <Route path="/PharmacyMedicineEdit/:itemId" element={<PharmacyMedicineEdit />} />
          <Route path="/PharmacyMedicineDetails/:itemId" element={<PharmacyMedicineDetails />} />

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
