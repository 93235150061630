import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Navbar/Sidebar";
import Footer from "../Navbar/Footer";
import Swal from 'sweetalert2';
import { API_URL } from "../../constants";

const CreatePackageDetails = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [packageCategories, setPackageCategories] = useState([]);
    const [selectedPackageName, setSelectedPackageName] = useState('');
    const [packageDetails, setPackageDetails] = useState(['']);

    useEffect(() => {
        const storedUser = JSON.parse(sessionStorage.getItem('loggedinUser'));
        if (storedUser) {
            setUser(storedUser);
            fetchPackageCategories(storedUser._id, storedUser.diagnosticCenterName);
        } else {
            navigate('/');
        }
    }, [navigate]);

    const fetchPackageCategories = async (userId, diagnosticCenterName) => {
        try {
            const response = await fetch(`${API_URL}/diagnostic-package-categories/${userId}/${diagnosticCenterName}`);
            const data = await response.json();
            console.log("Fetched Categories Data: ", data); // Debugging log
            if (data.success === "error") {
                throw new Error(data.error);
            }
            setPackageCategories(data.categories);
        } catch (error) {
            console.error("Error fetching package categories:", error);
            Swal.fire("Error!", error.message, "error");
        }
    };

    const handlePackageDetailChange = (index, value) => {
        const newDetails = [...packageDetails];
        newDetails[index] = value;
        setPackageDetails(newDetails);
    };

    const handleAddMore = () => {
        setPackageDetails([...packageDetails, '']);
    };

    const handleDeleteDetail = (index) => {
        const updatedDetails = [...packageDetails];
        updatedDetails.splice(index, 1);
        setPackageDetails(updatedDetails);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!selectedPackageName || packageDetails.some(detail => !detail)) {
            Swal.fire("Error!", "All fields are required.", "error");
            return;
        }
        try {
            const response = await fetch(`${API_URL}/diagnostic-package-details`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    userId: user._id,
                    packageName: selectedPackageName,
                    diagnosticCenterName: user.diagnosticCenterName,
                    packageDetails
                })
            });
            const data = await response.json();
            console.log("Submit Response Data: ", data); // Debugging log
            if (data.success === "error") {
                throw new Error(data.error);
            }
            Swal.fire("Success!", "Package created successfully.", "success");
            navigate('/package-details');
        } catch (error) {
            Swal.fire("Error!", error.message, "error");
        }
    };

    return (
        <div className="container-scroller">
            <Navbar />
            <div className="container-fluid page-body-wrapper">
                <Sidebar loggedInUser={user} />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="page-header">
                            <h3 className="page-title">Create Package Details</h3>
                        </div>
                        <div className="row grid-margin">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <form onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <label htmlFor="packageName">Package Name</label>
                                                <select
                                                    className="form-control"
                                                    id="packageName"
                                                    value={selectedPackageName}
                                                    onChange={(e) => setSelectedPackageName(e.target.value)}
                                                >
                                                    <option value="">Select Package Name</option>
                                                    {packageCategories.map(category => (
                                                        <option key={category._id} value={category.diagnosticPackageName}>
                                                            {category.diagnosticPackageName}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            {packageDetails.map((detail, index) => (
                                                <div className="form-group" key={index}>
                                                    <label htmlFor={`detail-${index}`}>Lab Test {index + 1} in this package</label>
                                                    <div className="input-group">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id={`detail-${index}`}
                                                            value={detail}
                                                            onChange={(e) => handlePackageDetailChange(index, e.target.value)}
                                                        />
                                                        <div className="input-group-append">
                                                            <button
                                                                type="button"
                                                                className="btn btn-danger"
                                                                onClick={() => handleDeleteDetail(index)}
                                                            >
                                                                Delete
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                            <button type="button" className="btn btn-secondary" onClick={handleAddMore}>
                                                Add More
                                            </button>
                                            <button type="submit" className="btn btn-primary ml-2">
                                                Submit
                                            </button>
                                            <button className="btn btn-warning ml-2" onClick={() => navigate('/package-details')}>Back</button>

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default CreatePackageDetails;
