import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Navbar/Sidebar";
import Footer from "../Navbar/Footer";
import axios from "axios";
import { API_URL } from "../../constants";
import Swal from 'sweetalert2';
import Toggle from 'react-toggle';
import "react-toggle/style.css";
import '../FoodPartner/MenuItems.css';

const LabTests = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [labTests, setLabTests] = useState([]);
    const [expandedCategories, setExpandedCategories] = useState({});

    useEffect(() => {
        const isLoggedIn = sessionStorage.getItem('isLoggedIn');
        const storedUser = JSON.parse(sessionStorage.getItem('loggedinUser'));

        if (isLoggedIn && storedUser) {
            setUser(storedUser);
            fetchLabTests(storedUser._id, storedUser.diagnosticCenterName);
        } else {
            navigate('/');
        }
    }, []);

    const fetchLabTests = async (userId, diagnosticCenterName) => {
        try {
            const response = await axios.get(`${API_URL}/lab-tests/${userId}/${diagnosticCenterName}`);
            if (response.data.success) {
                setLabTests(response.data.labTests);
            } else {
                console.error('Failed to fetch lab tests:', response.data.error);
            }
        } catch (error) {
            console.error('Error fetching lab tests:', error);
        }
    };

    const handleCreate = () => {
        navigate('/CreateLabTests');
    };

    const handleToggle = async (itemId) => {
        try {
            const response = await axios.put(`${API_URL}/lab-tests/${itemId}/toggle-availability`);
            if (response.data.success) {
                const updatedLabTests = labTests.map(category => ({
                    ...category,
                    items: category.items.map(item => {
                        if (item._id === itemId) {
                            return { ...item, availability: !item.availability };
                        }
                        return item;
                    })
                }));
                setLabTests(updatedLabTests);
                Swal.fire("Success!", "Availability status updated successfully.", "success");
            } else {
                console.error('Failed to update availability status:', response.data.error);
                Swal.fire("Error!", "Failed to update availability status. Please try again later.", "error");
            }
        } catch (error) {
            console.error('Error updating availability status:', error);
            Swal.fire("Error!", "Failed to update availability status. Please try again later.", "error");
        }
    };

    const handleEdit = (itemId) => {
        navigate(`/LabTestEdit/${itemId}`);
    };

    const handleView = (itemId) => {
        navigate(`/LabTestDetails/${itemId}`);
    };

    // Inside your LabTests component
    const handleDelete = async (itemId) => {
        // Display confirmation modal
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this lab test!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel',
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
        });

        // If user confirms deletion
        if (result.isConfirmed) {
            try {
                const response = await axios.delete(`${API_URL}/lab-tests/${itemId}`);
                if (response.data.success) {
                    const updatedLabTests = labTests.map(category => ({
                        ...category,
                        items: category.items.filter(item => item._id !== itemId)
                    }));
                    setLabTests(updatedLabTests);
                    Swal.fire("Success!", "Lab test deleted successfully.", "success");
                } else {
                    console.error('Failed to delete lab test:', response.data.error);
                    Swal.fire("Error!", "Failed to delete lab test. Please try again later.", "error");
                }
            } catch (error) {
                console.error('Error deleting lab test:', error);
                Swal.fire("Error!", "Failed to delete lab test. Please try again later.", "error");
            }
        }
    };

    const toggleCategory = (categoryId) => {
        setExpandedCategories(prevState => ({
            ...prevState,
            [categoryId]: !prevState[categoryId]
        }));

        // Smooth scroll to the category when toggling
        const element = document.getElementById(`category-${categoryId}`);
        element.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className="container-scroller">
            <Navbar />
            <div className="container-fluid page-body-wrapper">
                <Sidebar loggedInUser={user} />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="page-header">
                            <h3 className="page-title">Lab Tests</h3>
                            <button className="btn btn-primary" onClick={handleCreate}>Create Lab Test</button>
                        </div>
                        <div className="row grid-margin">
                            <div className="col-12">
                                {labTests.map(category => (
                                    <div className="card" key={category._id} id={`category-${category._id}`}>
                                        <div className="card-body">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <h4 style={{ fontWeight: "700", fontSize: "20px", letterSpacing: "-0.3px" }} className="card-title">{category._id}</h4>
                                                <button
                                                    className="btn btn-link btn-sm"
                                                    onClick={() => toggleCategory(category._id)}
                                                >
                                                    {expandedCategories[category._id] ? <i style={{ color: "#000" }} className="fas fa-chevron-up"></i> : <i style={{ color: "#000" }} className="fas fa-chevron-down"></i>}
                                                </button>
                                            </div>
                                            {expandedCategories[category._id] && (
                                                <div className="table-responsive">
                                                    <table className="table sortable-table">
                                                        <thead>
                                                            <tr>
                                                                <th>ID</th>
                                                                <th>Lab Test Name</th>
                                                                <th>Diagnostic Center Name</th>
                                                                <th>Actual Price</th>
                                                                <th>Discount Price</th>
                                                                <th>Offer Discount</th>
                                                                <th>Category</th>
                                                                <th>Available</th>
                                                                <th>Status</th>
                                                                <th>Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {category.items.map(item => (
                                                                <tr key={item._id}>
                                                                    <td>{item._id}</td>
                                                                    <td>{item.testName}</td>
                                                                    <td>{item.diagnosticCenterName}</td>
                                                                    <td>{item.actualPrice}</td>
                                                                    <td>{item.discountPrice}</td>
                                                                    <td>{item.offerDiscount}%</td>
                                                                    <td>{item.category}</td>
                                                                    <td>{item.availability ? "Available" : "Not Available"}</td>
                                                                    <td>
                                                                        <Toggle
                                                                            checked={item.availability}
                                                                            onChange={() => handleToggle(item._id)}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <button className="btn btn-primary btn-rounded btn-sm mr-2" onClick={() => handleEdit(item._id)}>Edit</button>
                                                                        <button className="btn btn-info btn-rounded btn-sm mr-2" onClick={() => handleView(item._id)}>View</button>
                                                                        <button className="btn btn-warning btn-rounded btn-sm" onClick={() => handleDelete(item._id)}>Delete</button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default LabTests;
