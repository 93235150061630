import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import { API_URL } from '../../constants';
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Navbar/Sidebar";
import Footer from "../Navbar/Footer";


const SuperAdmin = () => {
    const currentYear = new Date().getFullYear(); // Get the current year
    const navigate = useNavigate();
    const [userCount, setUserCount] = useState(null);
    const [user, setUser] = useState(null);


    useEffect(() => {
        const isLoggedIn = sessionStorage.getItem('isLoggedIn');
        const storedUser = JSON.parse(sessionStorage.getItem('user'));
    
        // Check if user is logged in and user data is available in session storage
        if (isLoggedIn && storedUser) {
            // Set the user object directly
            setUser(storedUser);
            fetchUserCount();
        } else {
            // If user is not logged in, show error message and navigate back to login page
            Swal.fire("Error!", "No access for this page. Please login and access.", "error").then(() => {
                navigate('/');
            });
        }
    }, []);
    

    const fetchUserCount = async () => {
        try {
            const response = await fetch(`${API_URL}/user-count`);
            const data = await response.json();
            if (data.success) {
                setUserCount(data.userCount);
            } else {
                // Handle error fetching user count
                console.error('Error fetching user count:', data.error);
            }
        } catch (error) {
            console.error('Error fetching user count:', error);
        }
    };

    fetchUserCount();
    

    return (
        <div class="container-scroller">
            <Navbar />
            <div class="container-fluid page-body-wrapper">
                <Sidebar />
                <div class="main-panel">
                    <div class="content-wrapper">
                        <div class="page-header">
                            <h3 class="page-title">
                                Welcome <span style={{ paddingLeft: "5px" }}>{user ? user.userName : 'Guest'}</span>
                            </h3>
                        </div>
                        <div class="row grid-margin">
                            <div class="col-12">
                                <div class="card card-statistics">
                                    <div class="card-body">
                                        <div class="d-flex flex-column flex-md-row align-items-center justify-content-between">
                                            <div class="statistics-item">
                                                <p>
                                                    <i class="icon-sm fa fa-user mr-2"></i>
                                                    <Link to='/VerifiedUsers' style={{ color: 'white' }}>New Users</Link>
                                                </p>
                                                <h2>{userCount}</h2>
                                                <label class="badge badge-outline-success badge-pill">2.7% increase</label>
                                            </div>
                                            <div class="statistics-item">
                                                <p>
                                                    <i class="icon-sm fas fa-hourglass-half mr-2"></i>
                                                    Avg Time
                                                </p>
                                                <h2>123.50</h2>
                                                <label class="badge badge-outline-danger badge-pill">30% decrease</label>
                                            </div>
                                            <div class="statistics-item">
                                                <p>
                                                    <i class="icon-sm fas fa-cloud-download-alt mr-2"></i>
                                                    Downloads
                                                </p>
                                                <h2>3500</h2>
                                                <label class="badge badge-outline-success badge-pill">12% increase</label>
                                            </div>
                                            <div class="statistics-item">
                                                <p>
                                                    <i class="icon-sm fas fa-check-circle mr-2"></i>
                                                    Update
                                                </p>
                                                <h2>7500</h2>
                                                <label class="badge badge-outline-success badge-pill">57% increase</label>
                                            </div>
                                            <div class="statistics-item">
                                                <p>
                                                    <i class="icon-sm fas fa-chart-line mr-2"></i>
                                                    Sales
                                                </p>
                                                <h2>9000</h2>
                                                <label class="badge badge-outline-success badge-pill">10% increase</label>
                                            </div>
                                            <div class="statistics-item">
                                                <p>
                                                    <i class="icon-sm fas fa-circle-notch mr-2"></i>
                                                    Pending
                                                </p>
                                                <h2>7500</h2>
                                                <label class="badge badge-outline-danger badge-pill">16% decrease</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default SuperAdmin;
