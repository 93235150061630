import { Profiler, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import foodlogo from '../Assets/images/logo/food-logo-1.png';
import minifoodlogo from '../Assets/images/logo/food-logo-2.png';
import dialogo from '../Assets/images/logo/dia-logo-1.png';
import minidialogo from '../Assets/images/logo/dia-logo-2.png';
import pharm from '../Assets/images/logo/pharmacy-logo-1.png';
import minipharm from '../Assets/images/logo/pharmacy-logo-2.png';
import profile from '../Assets/images/logo/profile.jpg';


import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';

import { API_URL } from '../../constants';


const Navbar = () => {
    const currentYear = new Date().getFullYear(); // Get the current year
    const navigate = useNavigate();
    const location = useLocation();
    const [user, setUser] = useState(null);

    useEffect(() => {
        const isLoggedIn = sessionStorage.getItem('isLoggedIn');
        const storedUser = JSON.parse(sessionStorage.getItem('loggedinUser'));
        console.log(storedUser);
        console.log(isLoggedIn);

        // Check if user is logged in and user data is available in session storage
        if (isLoggedIn && storedUser) {
            // Set the user object directly
            setUser(storedUser);
        }
        if (!(isLoggedIn && storedUser)) {
            // If user is not logged in, show error message and navigate back to login page
            Swal.fire("Error!", "No access for this page. Please login and access.", "error").then(() => {
                navigate('/');
            });
        }
    }, []);
    const handleLogout = () => {
        // Perform logout logic, e.g., clear session storage
        sessionStorage.clear();
    };


    return (
        <nav class="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row default-layout-navbar">
            <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
                {user && user.userType === 'Food Partner' ? (
                    // Show Food Partner logo
                    <>
                        <a className="navbar-brand brand-logo">
                            <img src={foodlogo} alt="Food Partner Logo" />
                        </a>
                        <a className="navbar-brand brand-logo-mini" >
                            <img src={minifoodlogo} alt="Food Partner Logo Mini" />
                        </a>
                    </>
                ) : user && user.userType === 'Lab Technician' ? (
                    // Show Lab Technician logo
                    <>
                        <a className="navbar-brand brand-logo">
                            <img src={dialogo} alt="Lab Technician Logo" />
                        </a>
                        <a className="navbar-brand brand-logo-mini" >
                            <img src={minidialogo} alt="Lab Technician Logo Mini" />
                        </a>
                    </>
                ) 
                : user && user.userType === 'Pharmacy Partner' ? (
                    // Show Lab Technician logo
                    <>
                        <a className="navbar-brand brand-logo">
                            <img src={pharm} alt="Lab Technician Logo" />
                        </a>
                        <a className="navbar-brand brand-logo-mini" >
                            <img src={minipharm} alt="Lab Technician Logo Mini" />
                        </a>
                    </>
                ) :  (
                    // Show default logo for other user types
                    <>
                        <a className="navbar-brand brand-logo" >
                            <img src="images/default_logo.svg" alt="Default Logo" />
                        </a>
                        <a className="navbar-brand brand-logo-mini" >
                            <img src="images/default_logo_mini.svg" alt="Default Logo Mini" />
                        </a>
                    </>
                )}
            </div>
            <div class="navbar-menu-wrapper d-flex align-items-stretch">
                <button class="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize">
                    <span class="fas fa-bars"></span>
                </button>
                <ul class="navbar-nav">
                    <li class="nav-item nav-search d-none d-md-flex">
                        <div class="nav-link">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i class="fas fa-search"></i>
                                    </span>
                                </div>
                                <input type="text" class="form-control" placeholder="Search" aria-label="Search" />
                            </div>
                        </div>
                    </li>
                </ul>
                <ul class="navbar-nav navbar-nav-right">
                    <li class="nav-item nav-profile dropdown">
                        <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" id="profileDropdown">
                            <img src={profile} alt="profile" />
                        </a>
                        <div class="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="profileDropdown">
                            <a class="dropdown-item">
                                <i class="fas fa-cog text-primary"></i>
                                Settings
                            </a>
                            <div class="dropdown-divider"></div>
                            <Link to="/" className="dropdown-item" onClick={handleLogout}>
                                <i className="fas fa-power-off text-primary"></i>
                                Logout
                            </Link>
                        </div>
                    </li>
                </ul>
                <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
                    <span class="fas fa-bars"></span>
                </button>
            </div>
        </nav>
    );
}

export default Navbar;
