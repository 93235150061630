import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Navbar/Sidebar";
import Footer from "../Navbar/Footer";
import axios from "axios";
import { API_URL } from "../../constants";
import Swal from 'sweetalert2';
import Toggle from 'react-toggle';
import "react-toggle/style.css";

const LabTestEdit = () => {
    const navigate = useNavigate();
    const { itemId } = useParams();
    const [user, setUser] = useState(null);
    const [item, setItem] = useState({
        testName: "",
        actualPrice: "",
        offerDiscount: "",
        discountPrice: "",
        category: "",
        description: "",
        availability: false,
        selectedPackages: [],
        availablePackages: [],
        gender: "",
        ageGroup: ""
    });
    const [categories, setCategories] = useState([]);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${API_URL}/lab-tests/${itemId}`);
                if (response.data.success) {
                    const labTest = response.data.labTests;
                    setItem({
                        testName: labTest.testName,
                        actualPrice: labTest.actualPrice,
                        offerDiscount: labTest.offerDiscount,
                        discountPrice: labTest.discountPrice,
                        category: labTest.category,
                        description: labTest.description,
                        availability: labTest.availability,
                        selectedPackages: labTest.selectedPackages || [],
                        availablePackages: [],
                        gender: labTest.gender,
                        ageGroup: labTest.ageGroup
                    });
                    fetchCategories(storedUser._id, storedUser.diagnosticCenterName);
                    fetchPackageDetails(storedUser._id, storedUser.diagnosticCenterName);
                } else {
                    console.error('Failed to fetch Lab Tests:', response.data.error);
                    Swal.fire("Error!", "Failed to fetch Lab Tests. Please try again later.", "error");
                }
            } catch (error) {
                console.error('Error fetching Lab Tests:', error);
                Swal.fire("Error!", "Failed to fetch Lab Tests. Please try again later.", "error");
            }
        };

        const isLoggedIn = sessionStorage.getItem('isLoggedIn');
        const storedUser = JSON.parse(sessionStorage.getItem('loggedinUser'));

        if (isLoggedIn && storedUser) {
            setUser(storedUser);
            fetchData();
        } else {
            Swal.fire("Error!", "No access for this page. Please login and access.", "error").then(() => {
                navigate('/');
            });
        }
    }, [itemId]);

    const fetchPackageDetails = async (userId, diagnosticCenterName) => {
        try {
            const response = await axios.get(`${API_URL}/diagnostic-package-details/${diagnosticCenterName}`);
            if (response.data.success) {
                setItem(prevState => ({
                    ...prevState,
                    availablePackages: response.data.diagnosticPackageDetails
                }));
            } else {
                console.error('Failed to fetch diagnostic packages:', response.data.error);
            }
        } catch (error) {
            console.error('Error fetching diagnostic packages:', error);
        }
    };

    const validate = () => {
        const errors = {};
        if (!item.testName) errors.testName = "Test Name is required.";
        if (!item.actualPrice) errors.actualPrice = "Actual Price is required.";
        if (item.actualPrice <= 0) errors.actualPrice = "Actual Price must be greater than zero.";
        if (!item.offerDiscount) errors.offerDiscount = "Discount Offer is required.";
        if (item.offerDiscount < 0 || item.offerDiscount > 100) errors.offerDiscount = "Discount Offer must be between 0 and 100.";
        if (!item.category) errors.category = "Category is required.";
        if (!item.gender) errors.gender = "Gender is required.";
        if (!item.ageGroup) errors.ageGroup = "Age Group is required.";
        if (!item.description) errors.description = "Description is required.";
        if (item.selectedPackages.length === 0) errors.packages = "Please select at least one package.";

        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleUpdate = async () => {
        if (!validate()) {
            return;
        }

        try {
            const response = await axios.put(`${API_URL}/lab-tests/${itemId}`, item);

            if (response.data.success) {
                Swal.fire("Success!", "Lab Test updated successfully.", "success");
                navigate('/LabTests');
            } else {
                console.error('Failed to update Lab Test:', response.data.error);
                Swal.fire("Error!", response.data.error.message, "error");
            }
        } catch (error) {
            console.error('Error updating Lab Test:', error);
            Swal.fire("Error!", error.response.data.error, "error");
        }
    };

    const handleCheckboxChange = (e, packageItem) => {
        const isChecked = e.target.checked;
        const { selectedPackages, availablePackages } = item;

        if (isChecked) {
            const updatedSelectedPackages = [...selectedPackages, packageItem];
            const updatedAvailablePackages = availablePackages.filter(pkg => pkg.packageName !== packageItem.packageName);
            setItem(prevState => ({
                ...prevState,
                selectedPackages: updatedSelectedPackages,
                availablePackages: updatedAvailablePackages
            }));
        } else {
            const updatedSelectedPackages = selectedPackages.filter(pkg => pkg.packageName !== packageItem.packageName);
            setItem(prevState => ({
                ...prevState,
                selectedPackages: updatedSelectedPackages,
                availablePackages: availablePackages.some(pkg => pkg.packageName === packageItem.packageName)
                    ? [...availablePackages]
                    : [...availablePackages, packageItem]
            }));
        }
    };

    const fetchCategories = async (userId, diagnosticCenterName) => {
        try {
            const response = await axios.get(`${API_URL}/diagnostic-categories/${userId}/${diagnosticCenterName}`);
            if (response.data.success) {
                setCategories(response.data.categories);
            } else {
                console.error('Failed to fetch diagnostic categories:', response.data.error);
            }
        } catch (error) {
            console.error('Error fetching diagnostic categories:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setItem(prevItem => ({
            ...prevItem,
            [name]: value
        }));

        // Calculate discount price if offerDiscount and actualPrice are provided
        if (name === "offerDiscount" && item.actualPrice) {
            const discountPrice = (item.actualPrice - (item.actualPrice * value / 100)).toFixed(2);
            setItem(prevItem => ({
                ...prevItem,
                discountPrice: discountPrice
            }));
        }
    };

    return (
        <div className="container-scroller">
            <Navbar />
            <div className="container-fluid page-body-wrapper">
                <Sidebar />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="page-header">
                            <h3 className="page-title">Edit Lab Test</h3>
                        </div>
                        <div className="row grid-margin">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="form-group">
                                            <label>Test Name</label>
                                            <input type="text" className={`form-control ${errors.testName ? 'is-invalid' : ''}`} name="testName" value={item.testName} onChange={handleInputChange} />
                                            {errors.testName && <div className="invalid-feedback">{errors.testName}</div>}
                                        </div>
                                        <div className="form-group">
                                            <label>Actual Price</label>
                                            <input type="number" className={`form-control ${errors.actualPrice ? 'is-invalid' : ''}`} name="actualPrice" value={item.actualPrice} onChange={handleInputChange} />
                                            {errors.actualPrice && <div className="invalid-feedback">{errors.actualPrice}</div>}
                                        </div>
                                        <div className="form-group">
                                            <label>Discount Offer %</label>
                                            <input type="number" className={`form-control ${errors.offerDiscount ? 'is-invalid' : ''}`} name="offerDiscount" value={item.offerDiscount} onChange={handleInputChange} />
                                            {errors.offerDiscount && <div className="invalid-feedback">{errors.offerDiscount}</div>}
                                        </div>
                                        <div className="form-group">
                                            <label>Discount Price</label>
                                            <input type="number" className="form-control" name="discountPrice" value={item.discountPrice} onChange={handleInputChange} disabled />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="category">Category</label>
                                            <select className={`form-control ${errors.category ? 'is-invalid' : ''}`} id="category" name="category" value={item.category} onChange={handleInputChange} required>
                                                <option value="">Select Category</option>
                                                {categories.map((category, index) => (
                                                    <option key={index} value={category.categoryName}>{category.categoryName}</option>
                                                ))}
                                            </select>
                                            {errors.category && <div className="invalid-feedback">{errors.category}</div>}
                                        </div>
                                        <div className="form-group">
                                            <label>Gender</label>
                                            <select className={`form-control ${errors.gender ? 'is-invalid' : ''}`} name="gender" value={item.gender} onChange={handleInputChange}>
                                                <option value="">Select Gender</option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                                <option value="Unisex">Unisex</option>
                                            </select>
                                            {errors.gender && <div className="invalid-feedback">{errors.gender}</div>}
                                        </div>
                                        <div className="form-group">
                                            <label>Age Group</label>
                                            <select className={`form-control ${errors.ageGroup ? 'is-invalid' : ''}`} name="ageGroup" value={item.ageGroup} onChange={handleInputChange}>
                                                <option value="">Select Age Group</option>
                                                <option value="All age groups">All Age Groups</option>
                                                <option value="0 yrs - 10 yrs">0 yrs - 10 yrs</option>
                                                <option value="10 yrs - 20 yrs">10 yrs - 20 yrs</option>
                                                <option value="20 yrs - 30 yrs">20 yrs - 30 yrs</option>
                                                <option value="30 yrs - 40 yrs">30 yrs - 40 yrs</option>
                                                <option value="40 yrs - 99 yrs">40 yrs - 99 yrs</option>
                                                <option value="18 yrs - 40 yrs">18 yrs - 40 yrs</option>
                                                <option value="40 yrs - 99 yrs">40 yrs - 99 yrs</option>
                                                <option value="15 yrs - 99 yrs">15 yrs - 99 yrs</option>
                                            </select>
                                            {errors.ageGroup && <div className="invalid-feedback">{errors.ageGroup}</div>}
                                        </div>
                                        <div className="form-group">
                                            <label>Description</label>
                                            <textarea type="text" rows="5" className={`form-control ${errors.description ? 'is-invalid' : ''}`} name="description" value={item.description} onChange={handleInputChange} />
                                            {errors.description && <div className="invalid-feedback">{errors.description}</div>}
                                        </div>
                                        <div className="form-group">
                                            <label>Availability</label>
                                            <Toggle
                                                checked={item.availability}
                                                onChange={() => setItem(prevItem => ({ ...prevItem, availability: !prevItem.availability }))}
                                            />
                                        </div>
                                        {item.selectedPackages.length > 0 && (
                                            <div>
                                                <h5>Selected Packages:</h5>
                                                {item.selectedPackages.map((pkg, index) => (
                                                    <div className="form-group" key={index} style={{ paddingLeft: '50px' }}>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" value={pkg.packageName} checked onChange={(e) => handleCheckboxChange(e, pkg)} />
                                                            <label className="form-check-label">{pkg.packageName}</label>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                        {item.availablePackages.length > 0 && (
                                            <div>
                                                <h5>Available Packages:</h5>
                                                {item.availablePackages.map((pkg, index) => (
                                                    // Check if the package name is not present in the selected packages
                                                    !item.selectedPackages.some(selectedPkg => selectedPkg.packageName === pkg.packageName) && (
                                                        <div className="form-group" key={index} style={{ paddingLeft: '50px' }}>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" value={pkg.packageName} onChange={(e) => handleCheckboxChange(e, pkg)} />
                                                                <label className="form-check-label">{pkg.packageName}</label>
                                                            </div>
                                                        </div>
                                                    )
                                                ))}
                                            </div>
                                        )}

                                        {errors.packages && <div className="invalid-feedback" style={{ display: 'block' }}>{errors.packages}</div>}

                                        <button className="btn btn-primary" onClick={handleUpdate}>Update</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default LabTestEdit;