import React, { useState, useEffect } from "react";
import Swal from 'sweetalert2';
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from '../../constants';
import vendorlogo from '../Assets/images/logo/vendor-logo.gif';

const Step3 = () => {
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();
  const userType = sessionStorage.getItem('userType');
  const userDataFirst = JSON.parse(sessionStorage.getItem('userData'));
  const typeDetails = JSON.parse(sessionStorage.getItem('userTypeDetails'));

  const [selectedFiles, setSelectedFiles] = useState({});
  const [thumbnails, setThumbnails] = useState({});
  const [userData, setUserData] = useState(userDataFirst);
  const [userTypeDetails, setUserTypeDetails] = useState(typeDetails);
  const [errors, setErrors] = useState({});

  const handleFileChange = (e, fileType) => {
    const file = e.target.files[0];
    setSelectedFiles({ ...selectedFiles, [fileType]: file });
    setThumbnails({ ...thumbnails, [fileType]: URL.createObjectURL(file) });
  };

  const validateForm = () => {
    const newErrors = {};

    if (userType === 'Food Partner') {
      if (!selectedFiles['FOODFSSAI']) newErrors.FOODFSSAI = 'FSSAI license copy is required.';
      if (!selectedFiles['FOODPAN']) newErrors.FOODPAN = 'PAN card copy is required.';
      if (!selectedFiles['FOODGSTIN']) newErrors.FOODGSTIN = 'GSTIN copy is required.';
      if (!selectedFiles['thumbnailFood']) newErrors.thumbnailFood = 'Thumbnail for restaurant is required.';
    } else if (userType === 'Lab Technician') {
      if (!selectedFiles['DIAHNOSTICLICENSE']) newErrors.DIAHNOSTICLICENSE = 'Diagnostic license copy is required.';
      if (!selectedFiles['DIAHNOSTICPAN']) newErrors.DIAHNOSTICPAN = 'PAN card copy is required.';
      if (!selectedFiles['DIAGNOSTICGSTIN']) newErrors.DIAGNOSTICGSTIN = 'GSTIN copy is required.';
      if (!selectedFiles['thumbnailLab']) newErrors.thumbnailLab = 'Thumbnail for diagnostic center is required.';
    } else if (userType === 'Pharmacy Partner') {
      if (!selectedFiles['PHARMACYLICENSE']) newErrors.PHARMACYLICENSE = 'Pharmacy license copy is required.';
      if (!selectedFiles['PHARMACYPAN']) newErrors.PHARMACYPAN = 'PAN card copy is required.';
      if (!selectedFiles['PHARMACYGSTIN']) newErrors.PHARMACYGSTIN = 'GSTIN copy is required.';
      if (!selectedFiles['thumbnailPharmacy']) newErrors.thumbnailPharmacy = 'Thumbnail for Pharmacy is required.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleFormSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      const formData = new FormData();
      formData.append("userName", userData.userName);
      formData.append("email", userData.email);
      formData.append("mobileNumber", userData.mobileNumber);
      formData.append("userType", userData.userType);

      if (userTypeDetails && userTypeDetails.userType === "Food Partner") {
        formData.append("restaurantName", userTypeDetails.restaurantName);
        formData.append("restaurantAddress", userTypeDetails.restaurantAddress);
        formData.append("restaurantOwnerName", userTypeDetails.restaurantOwnerName);
        formData.append("restaurantEmail", userTypeDetails.restaurantEmail);
        formData.append("restaurantMobileNumber", userTypeDetails.restaurantMobileNumber);
        formData.append("restaurantDescription", userTypeDetails.restaurantDescription);
        formData.append("FOODFSSAI", selectedFiles['FOODFSSAI']);
        formData.append("FOODPAN", selectedFiles['FOODPAN']);
        formData.append("FOODGSTIN", selectedFiles['FOODGSTIN']);
        formData.append("thumbnailFood", selectedFiles['thumbnailFood']);
      } else if (userTypeDetails && userTypeDetails.userType === "Lab Technician") {
        formData.append("diagnosticCenterName", userTypeDetails.diagnosticCenterName);
        formData.append("diagnosticOwnerName", userTypeDetails.diagnosticOwnerName);
        formData.append("diagnosticMobileNumber", userTypeDetails.diagnosticMobileNumber);
        formData.append("diagnosticDescription", userTypeDetails.diagnosticDescription);
        formData.append("DIAHNOSTICLICENSE", selectedFiles['DIAHNOSTICLICENSE']);
        formData.append("DIAHNOSTICPAN", selectedFiles['DIAHNOSTICPAN']);
        formData.append("DIAGNOSTICGSTIN", selectedFiles['DIAGNOSTICGSTIN']);
        formData.append("thumbnailLab", selectedFiles['thumbnailLab']);
      } else if (userTypeDetails && userTypeDetails.userType === "Pharmacy Partner") {
        formData.append("pharmacyName", userTypeDetails.pharmacyName);
        formData.append("pharmacyOwnerName", userTypeDetails.pharmacyOwnerName);
        formData.append("pharmacyAddress", userTypeDetails.pharmacyAddress);
        formData.append("pharmacyMobileNumber", userTypeDetails.pharmacyMobileNumber);
        formData.append("pharmacyEmail", userTypeDetails.pharmacyEmail);
        formData.append("pharmacyDescription", userTypeDetails.pharmacyDescription);
        formData.append("PHARMACYLICENSE", selectedFiles['PHARMACYLICENSE']);
        formData.append("PHARMACYPAN", selectedFiles['PHARMACYPAN']);
        formData.append("PHARMACYGSTIN", selectedFiles['PHARMACYGSTIN']);
        formData.append("thumbnailPharmacy", selectedFiles['thumbnailPharmacy']);
      }

      Swal.fire({
        icon: 'info',
        title: 'Submitting...',
        text: 'Please wait while we submit your details.',
        showConfirmButton: false,
        allowOutsideClick: false,
      });

      const response = await axios.post(`${API_URL}/submit-form`, formData);
      const responseData = response.data;
      console.log(responseData);

      Swal.fire({
        icon: 'success',
        title: 'Registration Successful!',
        text: 'You have successfully registered.',
      }).then(() => {
        console.log(responseData);
        navigate('/');
      });
    } catch (error) {
      console.error("Error submitting form:", error);
      let errorMessage = 'Failed to submit the form. Please try again later.';
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: errorMessage,
      });
    }
  };


  useEffect(() => {
    const userDataFirst = JSON.parse(sessionStorage.getItem('userData'));
    const typeDetails = JSON.parse(sessionStorage.getItem('userTypeDetails'));
    setUserData(userDataFirst);
    setUserTypeDetails(typeDetails);

    const disableBackButton = () => {
      window.history.pushState(null, "", window.location.href);
      window.onpopstate = function () {
        window.history.pushState(null, "", window.location.href);
      };
    };
    disableBackButton();
  }, []);

  console.log(userDataFirst);
  console.log(typeDetails);

  return (
    <div className="container-scroller">
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex align-items-stretch auth auth-img-bg">
          <div className="row flex-grow">
            <div className="col-lg-6 d-flex align-items-center justify-content-center" style={{ paddingTop: '20px', paddingBottom: '30px' }}>
              <div className="auth-form-transparent text-left p-3" style={{ boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)', borderRadius: '20px' }}>
                <div className="brand-logo">
                  <img src={vendorlogo} alt="logo" />
                </div>
                <h4>New here?</h4>
                <h6 className="font-weight-light">Join us today! It takes only a few steps</h6>
                <form className="pt-3">
                  {userType === 'Food Partner' && (
                    <div>
                      <div className="form-group">
                        <label>FSSAI license copy</label>
                        <input type="file" name="FOODFSSAI" className={`form-control ${errors.FOODFSSAI && 'is-invalid'}`} accept=".pdf" onChange={(e) => handleFileChange(e, 'FOODFSSAI')} />
                        {errors.FOODFSSAI && <div className="invalid-feedback">{errors.FOODFSSAI}</div>}
                      </div>
                      <div className="form-group">
                        <label>PAN card copy</label>
                        <input type="file" name="FOODPAN" className={`form-control ${errors.FOODPAN && 'is-invalid'}`} accept=".pdf" onChange={(e) => handleFileChange(e, 'FOODPAN')} />
                        {errors.FOODPAN && <div className="invalid-feedback">{errors.FOODPAN}</div>}
                      </div>
                      <div className="form-group">
                        <label>Regular GSTIN</label>
                        <input type="file" name="FOODGSTIN" className={`form-control ${errors.FOODGSTIN && 'is-invalid'}`} accept=".pdf" onChange={(e) => handleFileChange(e, 'FOODGSTIN')} />
                        {errors.FOODGSTIN && <div className="invalid-feedback">{errors.FOODGSTIN}</div>}
                      </div>
                      <div className="form-group">
                        <label>Thumbnail for restaurant</label>
                        <input type="file" name="thumbnailFood" className={`form-control ${errors.thumbnailFood && 'is-invalid'}`} accept=".jpg, .jpeg, .png" onChange={(e) => handleFileChange(e, 'thumbnailFood')} />
                        {errors.thumbnailFood && <div className="invalid-feedback">{errors.thumbnailFood}</div>}
                        {thumbnails.thumbnailFood && (
                          <img src={thumbnails.thumbnailFood} alt="Thumbnail Preview" className="mt-2" style={{ maxWidth: '100%', maxHeight: '150px' }} />
                        )}
                      </div>
                    </div>
                  )}

                  {userType === 'Lab Technician' && (
                    <div>
                      <div className="form-group">
                        <label>Diagnostic license copy</label>
                        <input type="file" name="DIAHNOSTICLICENSE" className={`form-control ${errors.DIAHNOSTICLICENSE && 'is-invalid'}`} accept=".pdf" onChange={(e) => handleFileChange(e, 'DIAHNOSTICLICENSE')} />
                        {errors.DIAHNOSTICLICENSE && <div className="invalid-feedback">{errors.DIAHNOSTICLICENSE}</div>}
                      </div>
                      <div className="form-group">
                        <label>PAN card copy</label>
                        <input type="file" name="DIAHNOSTICPAN" className={`form-control ${errors.DIAHNOSTICPAN && 'is-invalid'}`} accept=".pdf" onChange={(e) => handleFileChange(e, 'DIAHNOSTICPAN')} />
                        {errors.DIAHNOSTICPAN && <div className="invalid-feedback">{errors.DIAHNOSTICPAN}</div>}
                      </div>
                      <div className="form-group">
                        <label>Regular GSTIN (if applicable)</label>
                        <input type="file" name="DIAGNOSTICGSTIN" className={`form-control ${errors.DIAGNOSTICGSTIN && 'is-invalid'}`} accept=".pdf" onChange={(e) => handleFileChange(e, 'DIAGNOSTICGSTIN')} />
                        {errors.DIAGNOSTICGSTIN && <div className="invalid-feedback">{errors.DIAGNOSTICGSTIN}</div>}
                      </div>
                      <div className="form-group">
                        <label>Thumbnail for diagnostic center</label>
                        <input type="file" name="thumbnailLab" className={`form-control ${errors.thumbnailLab && 'is-invalid'}`} accept=".jpg, .jpeg, .png" onChange={(e) => handleFileChange(e, 'thumbnailLab')} />
                        {errors.thumbnailLab && <div className="invalid-feedback">{errors.thumbnailLab}</div>}
                        {thumbnails.thumbnailLab && (
                          <img src={thumbnails.thumbnailLab} alt="Thumbnail Preview" className="mt-2" style={{ maxWidth: '100%', maxHeight: '150px' }} />
                        )}
                      </div>
                    </div>
                  )}

                  {userType === 'Pharmacy Partner' && (
                    <div>
                      <div className="form-group">
                        <label>Pharmacy license copy</label>
                        <input type="file" name="PHARMACYLICENSE" className={`form-control ${errors.PHARMACYLICENSE && 'is-invalid'}`} accept=".pdf" onChange={(e) => handleFileChange(e, 'PHARMACYLICENSE')} />
                        {errors.PHARMACYLICENSE && <div className="invalid-feedback">{errors.PHARMACYLICENSE}</div>}
                      </div>
                      <div className="form-group">
                        <label>PAN card copy of Pharmacy</label>
                        <input type="file" name="PHARMACYPAN" className={`form-control ${errors.PHARMACYPAN && 'is-invalid'}`} accept=".pdf" onChange={(e) => handleFileChange(e, 'PHARMACYPAN')} />
                        {errors.PHARMACYPAN && <div className="invalid-feedback">{errors.PHARMACYPAN}</div>}
                      </div>
                      <div className="form-group">
                        <label>Regular GSTIN of Pharmacy (if applicable)</label>
                        <input type="file" name="PHARMACYGSTIN" className={`form-control ${errors.PHARMACYGSTIN && 'is-invalid'}`} accept=".pdf" onChange={(e) => handleFileChange(e, 'PHARMACYGSTIN')} />
                        {errors.PHARMACYGSTIN && <div className="invalid-feedback">{errors.PHARMACYGSTIN}</div>}
                      </div>
                      <div className="form-group">
                        <label>Thumbnail for Pharmacy</label>
                        <input type="file" name="thumbnailPharmacy" className={`form-control ${errors.thumbnailPharmacy && 'is-invalid'}`} accept=".jpg, .jpeg, .png" onChange={(e) => handleFileChange(e, 'thumbnailPharmacy')} />
                        {errors.thumbnailPharmacy && <div className="invalid-feedback">{errors.thumbnailPharmacy}</div>}
                        {thumbnails.thumbnailPharmacy && (
                          <img src={thumbnails.thumbnailPharmacy} alt="Thumbnail Preview" className="mt-2" style={{ maxWidth: '100%', maxHeight: '150px' }} />
                        )}
                      </div>
                    </div>
                  )}

                  <div style={{ marginTop: '2rem' }}>
                    <Link to="/Signup-vendortype" className="btn btn-secondary mr-2">
                      Previous
                    </Link>
                    <button type="button" className="btn btn-primary" onClick={handleFormSubmit}>
                      Save and Register
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-6 register-half-bg d-flex flex-row">
              <p className="text-white font-weight-medium text-center flex-grow align-self-end">Copyright &copy; {currentYear}  All rights reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step3;
