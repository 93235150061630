import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Navbar/Sidebar";
import Footer from "../Navbar/Footer";
import Swal from 'sweetalert2';
import '../FoodPartner/MenuItems.css';
import { API_URL } from "../../constants";

const PackageDetailsSub = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [packages, setPackages] = useState([]);

    useEffect(() => {
        const isLoggedIn = sessionStorage.getItem('isLoggedIn');
        const storedUser = JSON.parse(sessionStorage.getItem('loggedinUser'));
        console.log(storedUser);

        if (isLoggedIn && storedUser) {
            setUser(storedUser);
        } else {
            navigate('/');
        }
    }, [navigate]);

    useEffect(() => {
        const fetchPackages = async () => {
            try {
                if (user) {
                    const response = await fetch(`${API_URL}/diagnostic-package-details/${user.diagnosticCenterName}`);
                    const data = await response.json();
                    if (data.success === "error") {
                        throw new Error(data.error);
                    }
                    setPackages(data.diagnosticPackageDetails);
                }
            } catch (error) {
                console.error("Error fetching packages:", error);
                Swal.fire("Error!", error.message, "error");
            }
        };
    
        fetchPackages();
    }, [user]);
    

    const handleCreate = () => {
        navigate('/create-package-details');
    };

    const handleEdit = (id) => {
        navigate(`/edit-package-details/${id}`);
    };
    

    const handleView = (id) => {
        navigate(`/package-details-view/${id}`);
    };
    

    const handleDelete = async (id) => {
        try {
            const result = await Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            });

            if (result.isConfirmed) {
                const response = await fetch(`${API_URL}/diagnostic-package-details/${id}`, {
                    method: 'DELETE'
                });
                const data = await response.json();
                if (data.success === "error") {
                    throw new Error(data.error);
                }
                setPackages(packages.filter(pkg => pkg._id !== id));
                Swal.fire("Success!", "Package deleted successfully.", "success");
            }
        } catch (error) {
            Swal.fire("Error!", error.message, "error");
        }
    };

    return (
        <div className="container-scroller">
            <Navbar />
            <div className="container-fluid page-body-wrapper">
                <Sidebar loggedInUser={user} />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="page-header">
                            <h3 className="page-title">Package Details</h3>
                            <button className="btn btn-primary" onClick={handleCreate}>Create Package Details</button>
                        </div>
                        <div className="row grid-margin">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table sortable-table">
                                                <thead>
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>Package Name</th>
                                                        <th>Diagnostic Center Name</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {packages.map((pkg) => (
                                                        <tr key={pkg._id}>
                                                            <td>{pkg._id}</td>
                                                            <td>{pkg.packageName}</td>
                                                            <td>{pkg.diagnosticCenterName}</td>
                                                            <td>
                                                                <button
                                                                    className="btn btn-primary btn-rounded btn-sm mr-2"
                                                                    onClick={() => handleEdit(pkg._id)}
                                                                >
                                                                    Edit
                                                                </button>
                                                                <button
                                                                    className="btn btn-info btn-rounded btn-sm mr-2"
                                                                    onClick={() => handleView(pkg._id)}
                                                                >
                                                                    View
                                                                </button>
                                                                <button
                                                                    className="btn btn-warning btn-rounded btn-sm"
                                                                    onClick={() => handleDelete(pkg._id)}
                                                                >
                                                                    Delete
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default PackageDetailsSub;
