import React, { useState, useEffect } from "react";
import Swal from 'sweetalert2';
import { Link, useNavigate } from "react-router-dom";
import { API_URL } from '../../constants';
import vendorlogo from '../Assets/images/logo/vendor-logo.gif';

const Signup = () => {
    const navigate = useNavigate();
    const currentYear = new Date().getFullYear(); // Get the current year

    // Define state variables
    const [otpSent, setOtpSent] = useState(false);
    const [otpVerified, setOtpVerified] = useState(false);
    const [mobileNumber, setMobileNumber] = useState('');
    const [otp, setOtp] = useState('');
    const [userName, setuserName] = useState('');
    const [email, setEmail] = useState('');
    const [userType, setUserType] = useState('');
    const [nextClicked, setNextClicked] = useState(false); // Track if Next button is clicked

    // State for storing errors
    const [errors, setErrors] = useState({
        userName: '',
        email: '',
        mobileNumber: '',
        otp: '',
        userType: ''
    });

    // Retrieve saved data from session storage when component mounts
    useEffect(() => {
        const storedUserData = JSON.parse(sessionStorage.getItem('userData'));

        if (storedUserData) {
            setuserName(storedUserData.userName);
            setEmail(storedUserData.email);
            setMobileNumber(storedUserData.mobileNumber);
            setUserType(storedUserData.userType);
        }
        // Disable browser back button
        const disableBackButton = () => {
            window.history.pushState(null, "", window.location.href);
            window.onpopstate = function () {
                window.history.pushState(null, "", window.location.href);
            };
        };

        disableBackButton();
    }, []);

    // Function to send OTP
    const handleSendOtp = async () => {
        // Clear existing session data
        sessionStorage.clear();

        // Define helper functions for additional validations
        const startsWithDot = (value) => {
            return value.trim().startsWith('.');
        };

        const lessThan5Letters = (value) => {
            return value.trim().length < 5;
        };

        // Validate fields before sending OTP
        const newErrors = {};
        if (!userName.trim()) {
            newErrors.userName = 'Please enter a username.';
        } else if (startsWithDot(userName)) {
            newErrors.userName = 'Username should not start with a dot';
        } else if (lessThan5Letters(userName)) {
            newErrors.userName = 'Username should have at least 5 letters';
        }

        const trimmedEmail = email.trim();
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regex to validate email format

        if (!trimmedEmail || !trimmedEmail.endsWith('@gmail.com') || !emailRegex.test(trimmedEmail)) {
            newErrors.email = 'Please enter a valid email address ending with @gmail.com without spaces.';
        }

        if (!mobileNumber.trim() || mobileNumber.trim().length !== 10 || isNaN(mobileNumber)) {
            newErrors.mobileNumber = 'Please enter a valid 10-digit mobile number.';
        }

        setErrors(newErrors);
        if (Object.keys(newErrors).length > 0) {
            return;
        }

        try {
            // Add +91 before the mobile number
            const formattedMobileNumber = "+91" + mobileNumber;

            const response = await fetch(`${API_URL}/send-otp`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ mobileNumber: formattedMobileNumber }) // Send the formatted mobile number
            });

            const data = await response.json();
            if (data.success) {
                // Save OTP to session storage
                sessionStorage.setItem('otp', data.otp);

                setOtpSent(true);
                Swal.fire("OTP Sent!", "Please check your mobile for the OTP.", "success");
                console.log(sessionStorage.getItem('otp'));

            } else {
                Swal.fire("Error!", "Failed to send OTP.", "error");
            }
        } catch (error) {
            console.error('Error sending OTP:', error);
            Swal.fire("Error!", "Failed to send OTP.", "error");
        }
    };


    // Function to verify OTP
    const handleVerifyOtp = () => {
        if (otp === sessionStorage.getItem('otp')) {
            Swal.fire({
                title: 'Verifying OTP...',
                html: 'Please wait...',
                timer: 5000,
                timerProgressBar: true,
                allowOutsideClick: false,
                showConfirmButton: false,
                willOpen: () => {
                    Swal.showLoading();
                }
            }).then(() => {
                // After the 5-second delay, verify OTP
                setOtpVerified(true);
                Swal.fire("OTP Verified!", "You can now proceed.", "success");
            });
        } else {
            setErrors({ ...errors, otp: 'Invalid OTP.' });
        }
    };

    // Function to handle Next button click
    const handleNextClick = async () => {
        // Validate user type
        if (!userType.trim()) {
            setErrors({ ...errors, userType: 'Please select a partner type.' });
            return;
        }

        // Validate email and mobile number existence
        try {
            // Show processing alert
            const processingAlert = Swal.fire({
                title: 'Processing...',
                html: 'Please wait...',
                timer: 5000,
                timerProgressBar: true,
                allowOutsideClick: false,
                showConfirmButton: false,
                willOpen: () => {
                    Swal.showLoading();
                }
            });

            // Add +91 before the mobile number
            const formattedMobileNumber = "+91" + mobileNumber;

            const response = await fetch(`${API_URL}/check-existence`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email, mobileNumber: formattedMobileNumber })
            });

            const data = await response.json();

            if (!data.success) {
                // Close processing alert
                processingAlert.close();

                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: data.error,
                    timer: 5000,
                    timerProgressBar: true,
                    showConfirmButton: false
                });

                return;
            }

            // Close processing alert
            processingAlert.close();

            // Store userType into session storage
            sessionStorage.setItem('userType', userType);

            // Store form data into session
            sessionStorage.setItem('userData', JSON.stringify({ userName, email, mobileNumber, userType }));

            // Redirect to next page or perform further actions
            navigate('/Signup-vendortype');
        } catch (error) {
            console.error("Error checking user existence:", error);
            Swal.fire("Error!", "Failed to check user existence. Please try again later.", "error");
        }
    };

    return (
        <div className="container-scroller" >
            <div class="container-fluid page-body-wrapper full-page-wrapper">
                <div class="content-wrapper d-flex align-items-stretch auth auth-img-bg">
                    <div class="row flex-grow">
                        <div class="col-lg-6 d-flex align-items-center justify-content-center" style={{ paddingTop: '20px', paddingBottom: '30px' }}>
                            <div class="auth-form-transparent text-left p-3" style={{ boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)', borderRadius: '20px' }}>
                                <div class="brand-logo">
                                    <img src={vendorlogo} alt="logo" />
                                </div>
                                <h4>New here?</h4>
                                <h6 class="font-weight-light">Join us today! It takes only a few steps</h6>
                                <form class="pt-3">
                                    <div class="form-group">
                                        <label>userName</label>
                                        <div class="input-group">
                                            <div class="input-group-prepend bg-transparent">
                                                <span class="input-group-text bg-transparent border-right-0">
                                                    <i class="fa fa-user text-primary"></i>
                                                </span>
                                            </div>
                                            <input type="text" class={`form-control form-control-lg border-left-0 ${errors.userName ? 'is-invalid' : ''}`} placeholder="userName" value={userName} onChange={(e) => setuserName(e.target.value)} />
                                            {errors.userName && <div class="invalid-feedback">{errors.userName}</div>}
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label>Email</label>
                                        <div class="input-group">
                                            <div class="input-group-prepend bg-transparent">
                                                <span class="input-group-text bg-transparent border-right-0">
                                                    <i class="far fa-envelope-open text-primary"></i>
                                                </span>
                                            </div>
                                            <input type="email" class={`form-control form-control-lg border-left-0 ${errors.email ? 'is-invalid' : ''}`} placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                            {errors.email && <div class="invalid-feedback">{errors.email}</div>}
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label>Mobile Number</label>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">+91</span>
                                            </div>
                                            <input
                                                type="text"
                                                class={`form-control ${errors.mobileNumber ? 'is-invalid' : ''}`}
                                                placeholder="Enter Mobile Number"
                                                value={mobileNumber}
                                                onChange={(e) => setMobileNumber(e.target.value)}
                                                disabled={otpVerified} // Disable the field if OTP is verified
                                            />
                                            {errors.mobileNumber && <div class="invalid-feedback">{errors.mobileNumber}</div>}
                                        </div>
                                    </div>

                                    {!otpSent ? (
                                        <button type="button" class="btn btn-block btn-primary font-weight-medium auth-form-btn" onClick={handleSendOtp}>
                                            Send OTP
                                        </button>
                                    ) : (
                                        <div class="form-group">
                                            <label>Enter OTP</label>
                                            <input type="text" class={`form-control ${errors.otp ? 'is-invalid' : ''}`} placeholder="Enter OTP" value={otp} onChange={(e) => setOtp(e.target.value)} />
                                            {errors.otp && <div class="invalid-feedback">{errors.otp}</div>}
                                            <button type="button" disabled={otpVerified} class="btn btn-block btn-primary font-weight-medium auth-form-btn mt-2" onClick={handleVerifyOtp}>
                                                {otpVerified ? "OTP Verified" : "Verify OTP"}
                                            </button>
                                        </div>
                                    )}
                                    {otpVerified && (
                                        <div class="form-group">
                                            <label>Select Partner Type You Want to Register with Us</label>
                                            <select class={`form-control form-control-lg ${errors.userType ? 'is-invalid' : ''}`} value={userType} onChange={(e) => setUserType(e.target.value)}>
                                                <option value="">Select Partner Type</option>
                                                <option value="Food Partner">Food Partner</option>
                                                <option value="Lab Technician">Lab Technician</option>
                                                <option value="Pharmacy Partner">Pharmacy Partner</option>
                                            </select>
                                            {errors.userType && <div class="invalid-feedback">{errors.userType}</div>}
                                        </div>
                                    )}
                                    <div style={{ marginTop: '2rem' }}>
                                        <button type="button" class="btn btn-primary" onClick={handleNextClick} disabled={!otpVerified || nextClicked}>
                                            Next
                                        </button>
                                    </div>
                                    <div class="text-center mt-4 font-weight-light">
                                        Already have an account? <Link to="/" class="text-primary">Login</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="col-lg-6 register-half-bg d-flex flex-row">
                            <p class="text-white font-weight-medium text-center flex-grow align-self-end">Copyright &copy; {currentYear} All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Signup;
