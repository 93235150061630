import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Navbar/Sidebar";
import Footer from "../Navbar/Footer";
import Swal from 'sweetalert2';
import { API_URL } from "../../constants";
import axios from "axios";

const Categories = () => {
    const currentYear = new Date().getFullYear();
    const location = useLocation();
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [categoryName, setCategoryName] = useState('');
    const [categoryImage, setCategoryImage] = useState(null);
    const [categories, setCategories] = useState([]);
    const [inputError, setInputError] = useState('');

    useEffect(() => {
        const isLoggedIn = sessionStorage.getItem('isLoggedIn');
        const storedUser = JSON.parse(sessionStorage.getItem('loggedinUser'));

        if (isLoggedIn && storedUser) {
            setUser(storedUser);
            fetchCategories(storedUser._id);
        } else {
            Swal.fire("Error!", "No access for this page. Please login and access.", "error").then(() => {
                navigate('/');
            });
        }
    }, []);

    const fetchCategories = async (userId) => {
        try {
            const response = await axios.get(`${API_URL}/categories/${userId}`);
            setCategories(response.data.categories);
        } catch (error) {
            console.error('Error fetching categories:', error);
            Swal.fire("Error!", error.response.data.error, "error");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!categoryName.trim()) {
            setInputError('Category Name is required.');
            return;
        }

        if (!categoryImage) {
            setInputError('Category Image is required.');
            return;
        }

        const formData = new FormData();
        formData.append('userId', user._id);
        formData.append('restaurantName', user.restaurantName);
        formData.append('categoryName', categoryName);
        formData.append('categoryImage', categoryImage);

        try {
            const response = await axios.post(`${API_URL}/categories`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (response.data.success) {
                Swal.fire("Success!", response.data.message, "success");
                setCategoryName('');
                setCategoryImage(null);
                setInputError('');
                fetchCategories(user._id);
            } else {
                Swal.fire("Error!", response.data.error || "Failed to create category. Please try again later.", "error");
            }
        } catch (error) {
            console.error('Error creating category:', error.response.data.error || error.message);
            Swal.fire("Error!", error.response.data.error || "Failed to create category. Please try again later.", "error");
        }
    };

    const handleEdit = (index) => {
        const updatedCategories = categories.map((category, i) => {
            if (i === index) {
                return { ...category, isEditing: true }; // Set isEditing to true for the category being edited
            } else {
                return { ...category, isEditing: false };
            }
        });
        setCategoryName(categories[index].categoryName);
        setCategoryImage(null); // Reset categoryImage state when editing starts
        setCategories(updatedCategories);
    };

    const handleUpdate = async (categoryId, updatedName, index) => {
        try {
            const formData = new FormData();
            formData.append('categoryName', updatedName);
            if (categoryImage) {
                formData.append('categoryImage', categoryImage);
            }

            const response = await axios.put(`${API_URL}/categories/${categoryId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data.success) {
                Swal.fire("Success!", response.data.message, "success");
                fetchCategories(user._id);
            } else {
                Swal.fire("Error!", response.data.error || "Failed to update category. Please try again later.", "error");
            }
        } catch (error) {
            console.error('Error editing category:', error);
            Swal.fire("Error!", error.response.data.error, "error");
        }
    };

    const handleCancelEdit = (index) => {
        const updatedCategories = [...categories];
        updatedCategories[index].isEditing = false;
        setCategories(updatedCategories);
    };

    const handleDelete = async (categoryId) => {
        const confirmation = await Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this category!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        });

        if (confirmation.isConfirmed) {
            try {
                const response = await axios.delete(`${API_URL}/categories/${categoryId}`);
                if (response.data.success) {
                    Swal.fire("Success!", response.data.message, "success");
                    fetchCategories(user._id);
                } else {
                    Swal.fire("Error!", response.data.error, "error");
                }
            } catch (error) {
                console.error('Error deleting category:', error);
                Swal.fire("Error!", error.response.data.error, "error");
            }
        }
    };

    return (
        <div className="container-scroller">
            <Navbar />
            <div className="container-fluid page-body-wrapper">
                <Sidebar loggedInUser={user} />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="page-header">
                            <h3 className="page-title">Categories</h3>
                        </div>
                        <div className="row grid-margin">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title">Create Category</h4>
                                        <form className="cmxform" id="commentForm" onSubmit={handleSubmit}>
                                            <fieldset>
                                                <div className="form-group">
                                                    <label htmlFor="cname">Category Name</label>
                                                    <input className="form-control" minLength="2" type="text" id="categoryName" value={categoryName} onChange={(e) => setCategoryName(e.target.value)} required />
                                                    <label htmlFor="categoryImage">Category Image</label>
                                                    {categories.every(cat => !cat.isEditing) && (
                                                        <input className="form-control" type="file" id="categoryImage" accept="image/*" onChange={(e) => setCategoryImage(e.target.files[0])} required />
                                                    )}
                                                    {inputError && (
                                                        <div className="invalid-feedback d-block">
                                                            {inputError}
                                                        </div>
                                                    )}
                                                </div>
                                                <input className="btn btn-primary" type="submit" value="Submit" />
                                            </fieldset>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Category ID</th>
                                                        <th>Category Name</th>
                                                        <th>Restaurant Name</th>
                                                        <th>Category Image</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {categories.map((category, index) => (
                                                        <tr key={category._id}>
                                                            <td>{category._id}</td>
                                                            <td>
                                                                {category.isEditing ? (
                                                                    <input
                                                                        className="form-control"
                                                                        type="text"
                                                                        value={categoryName}
                                                                        onChange={(e) => setCategoryName(e.target.value)}
                                                                    />
                                                                ) : (
                                                                    category.categoryName
                                                                )}
                                                            </td>
                                                            <td>{category.restaurantName}</td>
                                                            <td>
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <img
                                                                        src={`data:image/png;base64,${category.categoryImage}`}
                                                                        alt="Category"
                                                                        style={{ width: "50px", height: "50px", marginRight: '10px' }}
                                                                    />
                                                                    {category.isEditing && (
                                                                        <input
                                                                            type="file"
                                                                            className="form-control"
                                                                            id={`editCategoryImage-${index}`}
                                                                            accept="image/*"
                                                                            onChange={(e) => setCategoryImage(e.target.files[0])}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {category.isEditing ? (
                                                                    <>
                                                                        <button
                                                                            className="btn btn-success btn-rounded btn-sm mr-2"
                                                                            onClick={() => handleUpdate(category._id, categoryName, index)}
                                                                        >
                                                                            Update
                                                                        </button>
                                                                        <button
                                                                            className="btn btn-danger btn-rounded btn-sm"
                                                                            style={{ marginRight: '10px' }}
                                                                            onClick={() => handleCancelEdit(index)}
                                                                        >
                                                                            Cancel
                                                                        </button>
                                                                    </>
                                                                ) : (
                                                                    <button
                                                                        className="btn btn-primary btn-rounded btn-sm mr-2"
                                                                        onClick={() => handleEdit(index)}
                                                                    >
                                                                        Edit
                                                                    </button>
                                                                )}
                                                                <button
                                                                    className="btn btn-light btn-rounded btn-sm"
                                                                    onClick={() => handleDelete(category._id)}
                                                                >
                                                                    Delete
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default Categories;
