import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Navbar/Sidebar";
import Footer from "../Navbar/Footer";
import Swal from 'sweetalert2';
import axios from 'axios';
import { API_URL } from "../../constants";
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import '../FoodPartner/MenuItems.css';
import { toast, ToastContainer, toast as toastify } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PharmacyPartnerDashboard = () => {
    const location = useLocation();
    const [categoryCount, setCategoryCount] = useState(0);
    const [medicineCountResponse, setMedicineCountResponse] = useState(0);
    const currentYear = new Date().getFullYear(); // Get the current year
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [timings, setTimings] = useState(null);
    const [editRow, setEditRow] = useState(""); // State to track the row being edited
    const [formData, setFormData] = useState({}); // State to store the form data for editing
    const [pharmacyDetails, setPharmacyDetails] = useState(null); // State to store restaurant details
    const [editingPharmacyDetails, setEditingPharmacyDetails] = useState(false); // State to track whether editing restaurant details
    const [showCreateTimingsForm, setShowCreateTimingsForm] = useState(false); // State to show/hide create timings form
    const [verifiedPharmacyStatus, setVerifiedPharmacyStatus] = useState(false);

    useEffect(() => {
        const isLoggedIn = sessionStorage.getItem('isLoggedIn');
        const storedUser = JSON.parse(sessionStorage.getItem('loggedinUser'));

        // Check if user is logged in and user data is available in session storage
        if (isLoggedIn && storedUser) {
            // Set the user object directly
            setUser(storedUser);
            fetchTimings(storedUser._id);
            fetchCounts(storedUser._id, storedUser.pharmacyName);
            fetchPharmacyDetails(storedUser._id);
            // Fetch verified Pharmacy status
            fetchVerifiedPharmacyStatus(storedUser._id);
            console.log(storedUser);
        } else {
            // If user is not logged in, show error message and navigate back to login page
            Swal.fire("Error!", "No access for this page. Please login and access.", "error").then(() => {
                navigate('/');
            });
        }
        // Call checkNewOrders initially and set an interval to check periodically
        checkNewOrders(storedUser);
        const interval = setInterval(() => checkNewOrders(storedUser), 30000);

        return () => clearInterval(interval);
    }, [navigate]);

    const checkNewOrders = async (storedUser) => {
        if (!storedUser || !storedUser._id) {
            console.error('Stored user is undefined or missing _id');
            return;
        }
        console.log('checkNewOrders function called');
        try {
            const response = await axios.get(`${API_URL}/new-orders`, { params: { userId: storedUser._id } });
            console.log("API Response", response.data);
            if (response.data.success && response.data.orders.length > 0) {
                const now = new Date();
                const timezoneOffset = now.getTimezoneOffset();
                const adjustedNow = new Date(now.getTime() - timezoneOffset * 60000); // Adjust current time to UTC
                
                console.log("Adjusted current time (UTC):", adjustedNow);
                
                const orders = response.data.orders;
                orders.forEach(order => {
                    const orderTime = new Date(order.createdOn); // Order time in UTC
                    
                    console.log("Order time (UTC):", orderTime);
                    
                    const timeDiffMinutes = (adjustedNow - orderTime) / (1000 * 60); // Calculate time difference in minutes
                    console.log("Time difference (minutes):", timeDiffMinutes);
                    
                    if (timeDiffMinutes < 1) {
                        toast.success("New order received!");
                    }
                });
            }
        } catch (error) {
            console.error('Error checking new orders:', error);
        }
    };
    
    

    const fetchCounts = async (userId, pharmacyName) => {
        try {
            const categoryResponse = await axios.get(`${API_URL}/categories/count/${userId}/${pharmacyName}`);
            if (categoryResponse.data.success) {
                setCategoryCount(categoryResponse.data.count);
            } else {
                console.error('Failed to fetch category count:', categoryResponse.data.error);
            }

            const medicineCountResponse = await axios.get(`${API_URL}/pharmacy-medicine/count/${userId}/${pharmacyName}`);
            if (medicineCountResponse.data.success) {
                setMedicineCountResponse(medicineCountResponse.data.count);
            } else {
                console.error('Failed to fetch menu item count:', medicineCountResponse.data.error);
            }
        } catch (error) {
            console.error('Error fetching counts:', error);
        }
    };

    const fetchTimings = async (userId) => {
        try {
            const timingsResponse = await axios.get(`${API_URL}/timings/${userId}`);
            if (timingsResponse.data.success) {
                setTimings(timingsResponse.data.timings.timings); // Extract timings from the response
            }
        } catch (error) {
            console.error('Error fetching timings:', error);
        }
    };

    const fetchPharmacyDetails = async (userId) => {
        try {
            const response = await axios.get(`${API_URL}/pharmacy-details/${userId}`);
            if (response.data.success) {
                setPharmacyDetails(response.data.pharmacyDetails);
            } else {
                console.error('Failed to fetch pharmacy details:', response.data.error);
            }
        } catch (error) {
            console.error('Error fetching pharmacy details:', error);
        }
    };

    const handleEdit = (day) => {
        // Set the row to be edited and store current timings in the form data
        setEditRow(day);
        setFormData(timings[day]);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (day) => {
        try {
            const userId = user._id;
            const response = await axios.post(`${API_URL}/all-timings`, {
                userId,
                timings: {
                    ...timings,
                    [day]: formData
                }
            });
            if (response.data.success) {
                // Show success message or update UI accordingly
                console.log('Timings updated successfully');
                fetchTimings(userId); // Fetch updated timings
                setEditRow(""); // Clear the row being edited
            } else {
                // Show error message or update UI accordingly
                console.error('Failed to update timings:', response.data.error);
            }
        } catch (error) {
            console.error('Error updating timings:', error);
        }
    };

    const handlePharmacyDetailsEdit = () => {
        setEditingPharmacyDetails(true); // Set editing state to true
        // Optionally, you can load the existing Pharmacy details into a form for editing
        setFormData({ ...pharmacyDetails }); // Initialize form data with existing Pharmacy details
    };

    const handlePharmacyDetailsSave = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior
        try {
            const userId = user._id;
            const response = await axios.put(`${API_URL}/pharmacy-details/${userId}`, formData); // Use PUT method and correct URL
            if (response.data.success) {
                // Show success message or update UI accordingly
                Swal.fire('Success!', response.data.message, 'success');
                console.log('Pharmacy details updated successfully');
                setPharmacyDetails(formData); // Update Pharmacy details in state
                setEditingPharmacyDetails(false); // Set editing state to false after saving
            } else {
                // Show error message or update UI accordingly
                Swal.fire('Error!', response.data.error, 'error');
                console.error('Failed to update Pharmacy details:', response.data.error);
            }
        } catch (error) {
            // Show error message or update UI accordingly
            Swal.fire('Error!', error.response.data.error, 'error');
            console.error('Error updating Pharmacy details:', error);
        }
    };

    const handleCreateTimings = () => {
        setShowCreateTimingsForm(true); // Show create timings form
    };

    const handleSaveTimings = async (formData) => {
        try {
            const userId = user._id;
            const response = await axios.post(`${API_URL}/all-timings`, {
                userId,
                timings: formData
            });
            if (response.data.success) {
                // Show success message or update UI accordingly
                console.log('Timings created successfully');
                fetchTimings(userId); // Fetch updated timings
                setShowCreateTimingsForm(false); // Hide create timings form after saving
            } else {
                // Show error message or update UI accordingly
                console.error('Failed to create timings:', response.data.error);
            }
        } catch (error) {
            console.error('Error creating timings:', error);
        }
    };

    const fetchVerifiedPharmacyStatus = async (userId) => {
        try {
            const response = await axios.get(`${API_URL}/verified-pharmacy-status/${userId}`);
            if (response.data.success) {
                setVerifiedPharmacyStatus(response.data.pharmacyStatus);
            } else {
                console.error('Failed to fetch verified pharmacy status:', response.data.error);
            }
        } catch (error) {
            console.error('Error fetching verified pharmacy status:', error);
        }
    };

    const handleToggleVerifiedPharmacyStatus = async () => {
        const newStatus = !verifiedPharmacyStatus; // Toggle the status
        const statusText = newStatus ? 'open' : 'close';
    
        // Show confirmation dialog
        Swal.fire({
            title: `Are you sure?`,
            text: `You are about to ${statusText} pharmacy status.`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, do it!',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const userId = user._id;
                    const response = await axios.put(`${API_URL}/verified-pharmacy-status/${userId}`, { pharmacyStatus: newStatus });
                    if (response.data.success) {
                        // Update the verified pharmacy status state with the new status
                        setVerifiedPharmacyStatus(newStatus);
                        console.log('Verified pharmacy status updated successfully');
                        Swal.fire({
                            title: 'Success!',
                            text: `Pharmacy status updated to ${statusText}.`,
                            icon: 'success'
                        });
                    } else {
                        console.error('Failed to update verified pharmacy status:', response.data.error);
                        Swal.fire({
                            title: 'Error!',
                            text: 'Failed to update pharmacy status. Please try again later.',
                            icon: 'error'
                        });
                    }
                } catch (error) {
                    console.error('Error updating verified pharmacy status:', error);
                    Swal.fire({
                        title: 'Error!',
                        text: 'Failed to update pharmacy status. Please try again later.',
                        icon: 'error'
                    });
                }
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire({
                    title: 'Cancelled',
                    text: 'Pharmacy status update was cancelled.',
                    icon: 'info'
                });
            }
        });
    };
    

    return (
        <div className="container-scroller">
            <div>
                <Navbar />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar loggedInUser={user} /> {/* Pass user state as props */}
                    <div className="main-panel">
                        {/* Render user-specific content using user state */}
                        <div className="content-wrapper">
                            {!showCreateTimingsForm && !timings && (
                                <div className="row grid-margin">
                                    <div className="page-header">
                                        <h3 className="page-title">
                                            Pharmacy Timings
                                        </h3>
                                    </div>
                                    <div className="col-12">
                                        <div className="alert alert-info" role="alert">
                                            No timings found for this Pharmacy.
                                        </div>
                                        <button className="btn btn-primary" onClick={handleCreateTimings}>Create Timings</button>
                                    </div>
                                </div>
                            )}
                            {showCreateTimingsForm && (
                                <div className="row grid-margin">
                                    <div className="col-12">
                                        <div className="page-header">
                                            <h3 className="page-title">
                                                Pharmacy Timings
                                            </h3>
                                        </div>
                                        <div className="card">
                                            <div className="card-body">
                                                {/* Render create timings form */}
                                                <CreateTimingsForm onSave={handleSaveTimings} onCancel={() => setShowCreateTimingsForm(false)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div class="row">
                                <div class="col-12 grid-margin">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="justify-content-between align-items-center">
                                                <div className="page-header">
                                                    
                                                    <h3 className="page-title ">
                                                        <span style={{ paddingLeft: "5px", textTransform: 'capitalize' }}>
                                                            {user ? user.pharmacyName : 'Guest'}
                                                            {user && user.isVerified && ( // Check if user exists and is verified
                                                                <i className="fas fa-check-circle" style={{ color: 'green', marginLeft: '5px' }}></i>
                                                            )}
                                                        </span>
                                                    </h3>
                                                    {/* Toggle button for verified pharmacy status */}
                                                    <div className="ml-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                        <span className={verifiedPharmacyStatus ? "toggle-text-open" : "toggle-text-closed"}>
                                                            {verifiedPharmacyStatus ? (
                                                                <span style={{ paddingRight: "10px",fontSize:"18px", fontWeight:"800" ,color:"green" }}>Pharmacy Opened</span>
                                                            ) : (
                                                                <span style={{ paddingRight: "10px",fontSize:"18px", fontWeight:"800" ,color:"red" }}>Pharmacy Closed</span>
                                                            )}
                                                        </span>
                                                        <Toggle
                                                            checked={verifiedPharmacyStatus}
                                                            onChange={handleToggleVerifiedPharmacyStatus}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="row grid-margin">
                                <div className="col-12">
                                    <div className="card card-statistics">
                                        <div className="card-body">
                                            <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
                                                <div className="statistics-item">
                                                    <p>
                                                        <i className="icon-sm fa fa-list-alt mr-2"></i>
                                                        <Link style={{ color: 'white' }} to='/PharmacyCategories'>Total Categories</Link>
                                                    </p>
                                                    <h2>{categoryCount}</h2>
                                                    <label className="badge badge-outline-success badge-pill">2.7% increase</label>
                                                </div>
                                                <div className="statistics-item">
                                                    <p>
                                                        <i className="icon-sm fas fa-utensils mr-2"></i>
                                                        <Link style={{ color: 'white' }} to='/PharmacyMedicine'>Total Products</Link>
                                                    </p>
                                                    <h2>{medicineCountResponse}</h2>
                                                    <label className="badge badge-outline-success badge-pill">2.7% increase</label>
                                                </div>
                                                <div className="statistics-item">
                                                    <p>
                                                        <i className="icon-sm fa fa-user mr-2"></i>
                                                        Total Orders
                                                    </p>
                                                    <h2>2051.00</h2>
                                                    <label className="badge badge-outline-success badge-pill">2.7% increase</label>
                                                </div>
                                                <div className="statistics-item">
                                                    <p>
                                                        <i className="icon-sm fas fa-check-circle mr-2"></i>
                                                        Average Daily Orders
                                                    </p>
                                                    <h2>7500</h2>
                                                    <label className="badge badge-outline-success badge-pill">57% increase</label>
                                                </div>
                                                <div className="statistics-item">
                                                    <p>
                                                        <i className="icon-sm fas fa-chart-line mr-2"></i>
                                                        Sales
                                                    </p>
                                                    <h2>9000</h2>
                                                    <label className="badge badge-outline-success badge-pill">10% increase</label>
                                                </div>
                                                <div className="statistics-item">
                                                    <p>
                                                        <i className="icon-sm fas fa-circle-notch mr-2"></i>
                                                        Pending
                                                    </p>
                                                    <h2>7500</h2>
                                                    <label className="badge badge-outline-danger badge-pill">16% decrease</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="row grid-margin">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="page-header">
                                                <h3 className="page-title">
                                                Pharmacy Details
                                                    {editingPharmacyDetails ? (
                                                        <button className="btn btn-primary btn-rounded btn-sm ml-3" onClick={handlePharmacyDetailsSave}>Save</button>
                                                    ) : (
                                                        <button className="btn btn-dark btn-rounded btn-sm ml-3" onClick={handlePharmacyDetailsEdit}>Edit</button>
                                                    )}
                                                </h3>
                                            </div>
                                            {pharmacyDetails && !editingPharmacyDetails && (
                                                <div className="table-responsive">
                                                    <table className="table">
                                                        <tbody>
                                                            <tr>
                                                                <td><strong>Pharmacy Name:</strong></td>
                                                                <td>{pharmacyDetails.pharmacyName}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><strong>Location:</strong></td>
                                                                <td>{pharmacyDetails.pharmacyAddress}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><strong>Description:</strong></td>
                                                                <td>{pharmacyDetails.pharmacyDescription}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><strong>Owner Name:</strong></td>
                                                                <td>{pharmacyDetails.pharmacyOwnerName}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><strong>Email:</strong></td>
                                                                <td>{pharmacyDetails.pharmacyEmail}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><strong>Mobile Number:</strong></td>
                                                                <td>{pharmacyDetails.pharmacyMobileNumber}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            )}

                                            {editingPharmacyDetails && (
                                                // Render a form for editing restaurant details
                                                <div>
                                                    <form onSubmit={handlePharmacyDetailsSave}>
                                                        <div className="form-group">
                                                            <label htmlFor="pharmacyName">pharmacy Name:</label>
                                                            <input type="text" className="form-control" id="pharmacyName" name="pharmacyName" value={formData.pharmacyName} onChange={handleChange} disabled />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="pharmacyAddress">Address:</label>
                                                            <input type="text" className="form-control" id="pharmacyAddress" name="pharmacyAddress" value={formData.pharmacyAddress} onChange={handleChange} disabled />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="pharmacyOwnerName">Owner Name:</label>
                                                            <input type="text" className="form-control" id="pharmacyOwnerName" name="pharmacyOwnerName" value={formData.pharmacyOwnerName} onChange={handleChange} disabled />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="pharmacyEmail">Email:</label>
                                                            <input type="email" className="form-control" id="pharmacyEmail" name="pharmacyEmail" value={formData.pharmacyEmail} onChange={handleChange} disabled />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="pharmacyMobileNumber">Mobile Number:</label>
                                                            <input type="text" className="form-control" id="pharmacyMobileNumber" name="pharmacyMobileNumber" value={formData.pharmacyMobileNumber} onChange={handleChange} disabled />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="pharmacyDescription">Description:</label>
                                                            <input type="text" className="form-control" id="pharmacyDescription" name="pharmacyDescription" value={formData.pharmacyDescription} onChange={handleChange} />
                                                        </div>
                                                        <button type="submit" className="btn btn-primary">Save</button>
                                                        <button type="button" className="btn btn-secondary ml-2" onClick={() => setEditingPharmacyDetails(false)}>Cancel</button>
                                                    </form>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {!showCreateTimingsForm && timings && (
                                <div className="row grid-margin">
                                    <div className="col-12">

                                        <div className="card">
                                            <div className="card-body">
                                                <div className="page-header">
                                                    <h3 className="page-title">
                                                        Pharmacy Timings
                                                    </h3>
                                                </div>
                                                {/* Render timings in table format if available */}
                                                <div className="table-responsive">
                                                    <table className="table table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th>Day</th>
                                                                <th>Open From</th>
                                                                <th>Open To</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {Object.entries(timings).map(([day, { from, to }]) => (
                                                                <tr key={day}>
                                                                    <td style={{ textTransform: 'capitalize' }}>{day}</td>
                                                                    <td>{editRow === day ? <input type="time" name="from" value={formData.from} onChange={(e) => handleChange(e)} /> : from}</td>
                                                                    <td>{editRow === day ? <input type="time" name="to" value={formData.to} onChange={(e) => handleChange(e)} /> : to}</td>
                                                                    <td>
                                                                        {editRow === day ? (
                                                                            <button className="btn btn-primary btn-rounded btn-sm" onClick={() => handleSubmit(day)}>Save</button>
                                                                        ) : (
                                                                            <button className="btn btn-dark btn-rounded btn-sm" onClick={() => handleEdit(day)}>Edit</button>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </div>
    );
}

// CreateTimingsForm component for creating timings
const CreateTimingsForm = ({ onSave, onCancel }) => {
    const [formData, setFormData] = useState({
        monday: { from: "", to: "" },
        tuesday: { from: "", to: "" },
        wednesday: { from: "", to: "" },
        thursday: { from: "", to: "" },
        friday: { from: "", to: "" },
        saturday: { from: "", to: "" },
        sunday: { from: "", to: "" }
    });

    const handleChange = (e, day) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [day]: {
                ...prevState[day],
                [name]: value
            }
        }));
    };

    const handleSave = () => {
        // Check if any timing field is empty
        const isEmpty = Object.values(formData).some(({ from, to }) => from === "" || to === "");
        if (isEmpty) {
            alert("Please fill in all timing fields.");
            return;
        }

        onSave(formData);
    };

    return (
        <form>
            <table className="table">
                <thead>
                    <tr>
                        <th>Day</th>
                        <th>From</th>
                        <th>To</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(formData).map(([day, { from, to }]) => (
                        <tr key={day}>
                            <td>{day.charAt(0).toUpperCase() + day.slice(1)}</td>
                            <td>
                                <input type="time" name="from" value={from} onChange={(e) => handleChange(e, day)} required />
                            </td>
                            <td>
                                <input type="time" name="to" value={to} onChange={(e) => handleChange(e, day)} required />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <button type="button" className="btn btn-primary" onClick={handleSave}>Save</button>
            <button type="button" className="btn btn-secondary ml-2" onClick={onCancel}>Cancel</button>
        </form>
    );
}


export default PharmacyPartnerDashboard;

