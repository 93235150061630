import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import { API_URL } from '../../constants';
import vendorlogo from '../Assets/images/logo/vendor-logo.gif';


const Signin = () => {
    const navigate = useNavigate();
    const currentYear = new Date().getFullYear(); // Get the current year

    const [mobileNumber, setMobileNumber] = useState("");
    const [otpSent, setOtpSent] = useState(false);
    const [enteredOtp, setEnteredOtp] = useState("");
    const [otpVerified, setOtpVerified] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [mobileVerified, setMobileVerified] = useState(false); // New state for mobile verification

    // Function to send OTP
    const handleSendOtp = async () => {
        // Validate fields before sending OTP
        if (!mobileNumber.trim() || mobileNumber.trim().length !== 10 || isNaN(mobileNumber)) {
            Swal.fire("Error!", "Please enter a valid 10-digit mobile number.", "error");
            return;
        }

        try {
            // Add +91 before the mobile number
            const formattedMobileNumber = "+91" + mobileNumber;

            // Send request to API to send OTP
            const response = await fetch(`${API_URL}/send-otp`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ mobileNumber: formattedMobileNumber }) // Send the formatted mobile number
            });

            const data = await response.json();
            if (data.success) {
                // Save OTP to session storage
                sessionStorage.setItem('otp', data.otp);
                console.log(sessionStorage.getItem('otp'));

                setOtpSent(true);
                Swal.fire("OTP Sent!", "Please check your mobile for the OTP.", "success");
            } else {
                Swal.fire("Error!", "Failed to send OTP.", "error");
            }
        } catch (error) {
            console.error('Error sending OTP:', error);
            Swal.fire("Error!", "Failed to send OTP.", "error");
        }
    };

    // Function to verify OTP
    const handleVerifyOtp = async () => {
        if (enteredOtp === sessionStorage.getItem('otp')) {
            Swal.fire({
                title: 'Verifying OTP...',
                html: 'Please wait...',
                timer: 5000,
                timerProgressBar: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                willOpen: () => {
                    Swal.showLoading();
                }
            }).then(async () => {
                try {
                    const response = await fetch(`${API_URL}/verify-mobile-number`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ mobileNumber }) // Send the mobile number
                    });
                    const data = await response.json();
                    if (response.ok) {
                        // Mobile number verification successful
                        setMobileVerified(true);
                        sessionStorage.setItem('userType', data.user.userType);
                        sessionStorage.setItem('loggedinUser', JSON.stringify(data.user));
                        sessionStorage.setItem('isLoggedIn', true);
                        console.log(data.user);

                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: data.message,
                        }).then(() => {
                            const userType = data.user.userType;
                            switch (userType) {
                                case 'Food Partner':
                                    navigate('/FoodPartnerDashboard', { state: { user: data.user } });
                                    break;
                                case 'Lab Technician':
                                    navigate('/LabTechnicianDashboard', { state: { user: data.user } });
                                    break;
                                case 'Pharmacy Partner':
                                    navigate('/PharmacyPartnerDashboard', { state: { user: data.user } });
                                    break;
                                // Add cases for other user types as needed
                                default:
                                    navigate('/');
                                    break;
                            }
                        });
                    } else {
                        // Error in mobile number verification
                        setMobileVerified(false);
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: data.error || 'Unknown Error', // Display API error message or a default message
                        }).then(() => {
                            // Reset form fields
                            setMobileNumber('');
                            setOtpSent(false);
                            setEnteredOtp('');
                            setOtpVerified(false);
                            setEmail('');
                            setPassword('');
                        });
                    }
                } catch (error) {
                    console.error('Error verifying mobile number:', error);
                    Swal.fire("Error!", "Internal Server Error", "error");
                }
            });
        } else {
            Swal.fire("Error!", "Invalid OTP. Please try again.", "error");
        }
    };



    useEffect(() => {
        // Disable browser back button
        const disableBackButton = () => {
            window.history.pushState(null, "", window.location.href);
            window.onpopstate = function () {
                window.history.pushState(null, "", window.location.href);
            };
        };

        disableBackButton();
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent default form submission behavior
    };

    return (
        <div className="container-scroller">
            <div className="container-fluid page-body-wrapper full-page-wrapper">
                <div className="content-wrapper d-flex align-items-stretch auth auth-img-bg">
                    <div className="row flex-grow">
                        <div className="col-lg-6 d-flex align-items-center justify-content-center" style={{ paddingTop: '20px', paddingBottom: '30px' }}>
                            <div className="auth-form-transparent text-left p-3" style={{ boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)', borderRadius: '20px' }}>
                                <div className="brand-logo">
                                    <img src={vendorlogo} alt="logo" />
                                </div>
                                <h4>Welcome back!</h4>
                                <h6 className="font-weight-light">Happy to see you again!</h6>
                                <form className="pt-3" onSubmit={handleSubmit}>
                                    {!otpSent ? (
                                        <div>
                                            <div className="form-group">
                                                <label>Mobile Number</label>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">+91</span>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Mobile Number"
                                                        value={mobileNumber}
                                                        onChange={(e) => setMobileNumber(e.target.value)}
                                                    />
                                                </div>
                                            </div>

                                            <button type="button" className="btn btn-block btn-primary font-weight-medium auth-form-btn" onClick={handleSendOtp}>
                                                Send OTP
                                            </button>
                                        </div>
                                    ) : (
                                        <div className="form-group">
                                            <label>Enter OTP</label>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text"><i className="fa fa-lock text-primary"></i></span>
                                                </div>
                                                <input type="text" className="form-control" placeholder="Enter OTP" value={enteredOtp} onChange={(e) => setEnteredOtp(e.target.value)} />
                                                <button type="button" className="btn btn-block btn-primary font-weight-medium auth-form-btn mt-2" onClick={handleVerifyOtp} disabled={otpVerified}>
                                                    {otpVerified ? "OTP Verified" : "Verify OTP"}
                                                </button>
                                            </div>
                                        </div>
                                    )}

                                    <div className="text-center mt-4 font-weight-light" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div>
                                            Don't have an account? <Link to='/signup' className="text-primary">Create</Link>
                                        </div>
                                        <div>
                                            Sign in as <Link to='/adminsignin' className="text-primary"><b style={{ fontSize: "18px" }}>Admin</b></Link>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-6 login-half-bg d-flex flex-row">
                            <p className="text-white font-weight-medium text-center flex-grow align-self-end">Copyright &copy; {currentYear}  All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Signin;

