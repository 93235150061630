import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Navbar/Sidebar";
import Footer from "../Navbar/Footer";
import Swal from 'sweetalert2';
import axios from 'axios';
import { API_URL } from "../../constants";
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import '../FoodPartner/MenuItems.css';

const FoodPartner = () => {
    const location = useLocation();
    const [categoryCount, setCategoryCount] = useState(0);
    const [menuItemCount, setMenuItemCount] = useState(0);
    const currentYear = new Date().getFullYear(); // Get the current year
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [timings, setTimings] = useState(null);
    const [editRow, setEditRow] = useState(""); // State to track the row being edited
    const [formData, setFormData] = useState({}); // State to store the form data for editing
    const [restaurantDetails, setRestaurantDetails] = useState(null); // State to store restaurant details
    const [editingRestaurantDetails, setEditingRestaurantDetails] = useState(false); // State to track whether editing restaurant details
    const [showCreateTimingsForm, setShowCreateTimingsForm] = useState(false); // State to show/hide create timings form
    const [verifiedRestaurantStatus, setVerifiedRestaurantStatus] = useState(false);

    useEffect(() => {
        const isLoggedIn = sessionStorage.getItem('isLoggedIn');
        const storedUser = JSON.parse(sessionStorage.getItem('loggedinUser'));

        // Check if user is logged in and user data is available in session storage
        if (isLoggedIn && storedUser) {
            // Set the user object directly
            setUser(storedUser);
            fetchTimings(storedUser._id);
            fetchCounts(storedUser._id, storedUser.restaurantName);
            fetchRestaurantDetails(storedUser._id);
            // Fetch verified restaurant status
            fetchVerifiedRestaurantStatus(storedUser._id);
            console.log(storedUser);
        } else {
            // If user is not logged in, show error message and navigate back to login page
            Swal.fire("Error!", "No access for this page. Please login and access.", "error").then(() => {
                navigate('/');
            });
        }
        // Disable browser back button
        const disableBackButton = () => {
            window.history.pushState(null, "", window.location.href);
            window.onpopstate = function () {
                window.history.pushState(null, "", window.location.href);
            };
        };

        disableBackButton();
    }, []);

    const fetchCounts = async (userId, restaurantName) => {
        try {
            const categoryResponse = await axios.get(`${API_URL}/categories/count/${userId}/${restaurantName}`);
            if (categoryResponse.data.success) {
                setCategoryCount(categoryResponse.data.count);
            } else {
                console.error('Failed to fetch category count:', categoryResponse.data.error);
            }

            const menuItemResponse = await axios.get(`${API_URL}/menu-items/count/${userId}/${restaurantName}`);
            if (menuItemResponse.data.success) {
                setMenuItemCount(menuItemResponse.data.count);
            } else {
                console.error('Failed to fetch menu item count:', menuItemResponse.data.error);
            }
        } catch (error) {
            console.error('Error fetching counts:', error);
        }
    };

    const fetchTimings = async (userId, restaurantName) => {
        try {
            const timingsResponse = await axios.get(`${API_URL}/timings/${userId}`);
            if (timingsResponse.data.success) {
                setTimings(timingsResponse.data.timings.timings); // Extract timings from the response
            }
        } catch (error) {
            console.error('Error fetching timings:', error);
        }
    };

    const fetchRestaurantDetails = async (userId) => {
        try {
            const response = await axios.get(`${API_URL}/restaurant-details/${userId}`);
            if (response.data.success) {
                setRestaurantDetails(response.data.restaurantDetails);
            } else {
                console.error('Failed to fetch restaurant details:', response.data.error);
            }
        } catch (error) {
            console.error('Error fetching restaurant details:', error);
        }
    };

    const handleEdit = (day) => {
        // Set the row to be edited and store current timings in the form data
        setEditRow(day);
        setFormData(timings[day]);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (day) => {
        try {
            const userId = user._id;
            const response = await axios.post(`${API_URL}/all-timings`, {
                userId,
                timings: {
                    ...timings,
                    [day]: formData
                }
            });
            if (response.data.success) {
                // Show success message or update UI accordingly
                console.log('Timings updated successfully');
                fetchTimings(userId); // Fetch updated timings
                setEditRow(""); // Clear the row being edited
            } else {
                // Show error message or update UI accordingly
                console.error('Failed to update timings:', response.data.error);
            }
        } catch (error) {
            console.error('Error updating timings:', error);
        }
    };

    const handleRestaurantDetailsEdit = () => {
        setEditingRestaurantDetails(true); // Set editing state to true
        // Optionally, you can load the existing restaurant details into a form for editing
        setFormData({ ...restaurantDetails }); // Initialize form data with existing restaurant details
    };

    const handleRestaurantDetailsSave = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior
        try {
            const userId = user._id;
            const response = await axios.put(`${API_URL}/restaurant-details/${userId}`, formData); // Use PUT method and correct URL
            if (response.data.success) {
                // Show success message or update UI accordingly
                Swal.fire('Success!', response.data.success, 'success');
                setRestaurantDetails(formData); // Update restaurant details in state
                setEditingRestaurantDetails(false); // Set editing state to false after saving
            } else {
                // Show error message or update UI accordingly
                Swal.fire('Error!', response.data.error, 'error');
                console.error('Failed to update restaurant details:', response.data.error);
            }
        } catch (error) {
            // Show error message or update UI accordingly
            Swal.fire('Error!', error.response.data.error, 'error');
            console.error('Error updating restaurant details:', error);
        }
    };

    const handleCreateTimings = () => {
        setShowCreateTimingsForm(true); // Show create timings form
    };

    const handleSaveTimings = async (formData) => {
        try {
            const userId = user._id;
            const response = await axios.post(`${API_URL}/all-timings`, {
                userId,
                timings: formData
            });
            if (response.data.success) {
                // Show success message or update UI accordingly
                console.log('Timings created successfully');
                fetchTimings(userId); // Fetch updated timings
                setShowCreateTimingsForm(false); // Hide create timings form after saving
            } else {
                // Show error message or update UI accordingly
                console.error('Failed to create timings:', response.data.error);
            }
        } catch (error) {
            console.error('Error creating timings:', error);
        }
    };
    const fetchVerifiedRestaurantStatus = async (userId) => {
        try {
            const response = await axios.get(`${API_URL}/verified-restaurant-status/${userId}`);
            if (response.data.success) {
                setVerifiedRestaurantStatus(response.data.restaurantStatus);
            } else {
                console.error('Failed to fetch verified restaurant status:', response.data.error);
            }
        } catch (error) {
            console.error('Error fetching verified restaurant status:', error);
        }
    };

    const handleToggleVerifiedRestaurantStatus = async () => {
        const newStatus = !verifiedRestaurantStatus; // Determine the new status
        const statusText = newStatus ? 'open' : 'close';

        // Show confirmation dialog
        Swal.fire({
            title: `Are you sure?`,
            text: `You are about to ${statusText} your restaurant.`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, do it!',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const userId = user._id;
                    const response = await axios.put(`${API_URL}/verified-restaurant-status/${userId}`, { restaurantStatus: newStatus });
                    if (response.data.success) {
                        // Update the verified restaurant status state with the new status
                        setVerifiedRestaurantStatus(newStatus);
                        console.log('Verified restaurant status updated successfully');
                        Swal.fire({
                            title: 'Success!',
                            text: `Restaurant status updated to ${statusText}.`,
                            icon: 'success'
                        });
                    } else {
                        console.error('Failed to update verified restaurant status:', response.data.error);
                        Swal.fire({
                            title: 'Error!',
                            text: 'Failed to update restaurant status. Please try again later.',
                            icon: 'error'
                        });
                    }
                } catch (error) {
                    console.error('Error updating verified restaurant status:', error);
                    Swal.fire({
                        title: 'Error!',
                        text: 'Failed to update restaurant status. Please try again later.',
                        icon: 'error'
                    });
                }
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire({
                    title: 'Cancelled',
                    text: 'Restaurant status update was cancelled.',
                    icon: 'info'
                });
            }
        });
    };


    return (
        <div className="container-scroller">
            <div>
                <Navbar />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar loggedInUser={user} /> {/* Pass user state as props */}
                    <div className="main-panel">
                        {/* Render user-specific content using user state */}
                        <div className="content-wrapper">
                            {!showCreateTimingsForm && !timings && (
                                <div className="row grid-margin">
                                    <div className="page-header">
                                        <h3 className="page-title">
                                            Restaurant Timings
                                        </h3>
                                    </div>
                                    <div className="col-12">
                                        <div className="alert alert-info" role="alert">
                                            No timings found for this restaurant.
                                        </div>
                                        <button className="btn btn-primary" onClick={handleCreateTimings}>Create Timings</button>
                                    </div>
                                </div>
                            )}
                            {showCreateTimingsForm && (
                                <div className="row grid-margin">
                                    <div className="col-12">
                                        <div className="page-header">
                                            <h3 className="page-title">
                                                Restaurant Timings
                                            </h3>
                                        </div>
                                        <div className="card">
                                            <div className="card-body">
                                                {/* Render create timings form */}
                                                <CreateTimingsForm onSave={handleSaveTimings} onCancel={() => setShowCreateTimingsForm(false)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div class="row">
                                <div class="col-12 grid-margin">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="justify-content-between align-items-center">
                                                <div className="page-header">

                                                    <h3 className="page-title ">
                                                        <span style={{ paddingLeft: "5px", textTransform: 'capitalize' }}>
                                                            {user ? user.restaurantName : 'Guest'}
                                                            {user && user.isVerified && ( // Check if user exists and is verified
                                                                <i className="fas fa-check-circle" style={{ color: 'green', marginLeft: '5px' }}></i>
                                                            )}
                                                        </span>
                                                    </h3>
                                                    {/* Toggle button for verified restaurant status */}
                                                    <div className="ml-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                        <span className={verifiedRestaurantStatus ? "toggle-text-open" : "toggle-text-closed"}>
                                                            {verifiedRestaurantStatus ? (
                                                                <span style={{ paddingRight: "10px", fontSize: "18px", fontWeight: "800", color: "green" }}>Restaurant Opened</span>
                                                            ) : (
                                                                <span style={{ paddingRight: "10px", fontSize: "18px", fontWeight: "800", color: "red" }}>Restaurant Closed</span>
                                                            )}
                                                        </span>
                                                        <Toggle
                                                            checked={verifiedRestaurantStatus}
                                                            onChange={handleToggleVerifiedRestaurantStatus}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="row grid-margin">
                                <div className="col-12">
                                    <div className="card card-statistics">
                                        <div className="card-body">
                                            <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
                                                <div className="statistics-item">
                                                    <p>
                                                        <i className="icon-sm fa fa-list-alt mr-2"></i>
                                                        <Link style={{ color: 'white' }} to='/foodCategories'>Total Categories</Link>
                                                    </p>
                                                    <h2>{categoryCount}</h2>
                                                    <label className="badge badge-outline-success badge-pill">2.7% increase</label>
                                                </div>
                                                <div className="statistics-item">
                                                    <p>
                                                        <i className="icon-sm fas fa-utensils mr-2"></i>
                                                        <Link style={{ color: 'white' }} to='/menuItems'>Total Menu Items</Link>
                                                    </p>
                                                    <h2>{menuItemCount}</h2>
                                                    <label className="badge badge-outline-success badge-pill">2.7% increase</label>
                                                </div>
                                                <div className="statistics-item">
                                                    <p>
                                                        <i className="icon-sm fa fa-user mr-2"></i>
                                                        Total Orders
                                                    </p>
                                                    <h2>2051.00</h2>
                                                    <label className="badge badge-outline-success badge-pill">2.7% increase</label>
                                                </div>
                                                <div className="statistics-item">
                                                    <p>
                                                        <i className="icon-sm fas fa-check-circle mr-2"></i>
                                                        Average Daily Orders
                                                    </p>
                                                    <h2>7500</h2>
                                                    <label className="badge badge-outline-success badge-pill">57% increase</label>
                                                </div>
                                                <div className="statistics-item">
                                                    <p>
                                                        <i className="icon-sm fas fa-chart-line mr-2"></i>
                                                        Sales
                                                    </p>
                                                    <h2>9000</h2>
                                                    <label className="badge badge-outline-success badge-pill">10% increase</label>
                                                </div>
                                                <div className="statistics-item">
                                                    <p>
                                                        <i className="icon-sm fas fa-circle-notch mr-2"></i>
                                                        Pending
                                                    </p>
                                                    <h2>7500</h2>
                                                    <label className="badge badge-outline-danger badge-pill">16% decrease</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="row grid-margin">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="page-header">
                                                <h3 className="page-title">
                                                    Restaurant Details
                                                    {editingRestaurantDetails ? (
                                                        <button className="btn btn-primary btn-rounded btn-sm ml-3" onClick={handleRestaurantDetailsSave}>Save</button>
                                                    ) : (
                                                        <button className="btn btn-dark btn-rounded btn-sm ml-3" onClick={handleRestaurantDetailsEdit}>Edit</button>
                                                    )}
                                                </h3>
                                            </div>
                                            {restaurantDetails && !editingRestaurantDetails && (
                                                <div className="table-responsive">
                                                    <table className="table">
                                                        <tbody>
                                                            <tr>
                                                                <td><strong>Restaurant Name:</strong></td>
                                                                <td>{restaurantDetails.restaurantName}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><strong>Location:</strong></td>
                                                                <td>{restaurantDetails.restaurantAddress}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><strong>Description:</strong></td>
                                                                <td>{restaurantDetails.restaurantDescription}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><strong>Owner Name:</strong></td>
                                                                <td>{restaurantDetails.restaurantOwnerName}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><strong>Email:</strong></td>
                                                                <td>{restaurantDetails.restaurantEmail}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><strong>Mobile Number:</strong></td>
                                                                <td>{restaurantDetails.restaurantMobileNumber}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            )}

                                            {editingRestaurantDetails && (
                                                // Render a form for editing restaurant details
                                                <div>
                                                    <form onSubmit={handleRestaurantDetailsSave}>
                                                        <div className="form-group">
                                                            <label htmlFor="restaurantName">Restaurant Name:</label>
                                                            <input type="text" className="form-control" id="restaurantName" name="restaurantName" value={formData.restaurantName} onChange={handleChange} disabled />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="restaurantAddress">Address:</label>
                                                            <input type="text" className="form-control" id="restaurantAddress" name="restaurantAddress" value={formData.restaurantAddress} onChange={handleChange} disabled />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="restaurantOwnerName">Owner Name:</label>
                                                            <input type="text" className="form-control" id="restaurantOwnerName" name="restaurantOwnerName" value={formData.restaurantOwnerName} onChange={handleChange} disabled />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="restaurantEmail">Email:</label>
                                                            <input type="email" className="form-control" id="restaurantEmail" name="restaurantEmail" value={formData.restaurantEmail} onChange={handleChange} disabled />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="restaurantMobileNumber">Mobile Number:</label>
                                                            <input type="text" className="form-control" id="restaurantMobileNumber" name="restaurantMobileNumber" value={formData.restaurantMobileNumber} onChange={handleChange} disabled />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="restaurantDescription">Description:</label>
                                                            <input type="text" className="form-control" id="restaurantDescription" name="restaurantDescription" value={formData.restaurantDescription} onChange={handleChange} />
                                                        </div>
                                                        <button type="submit" className="btn btn-primary">Save</button>
                                                        <button type="button" className="btn btn-secondary ml-2" onClick={() => setEditingRestaurantDetails(false)}>Cancel</button>
                                                    </form>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {!showCreateTimingsForm && timings && (
                                <div className="row grid-margin">
                                    <div className="col-12">

                                        <div className="card">
                                            <div className="card-body">
                                                <div className="page-header">
                                                    <h3 className="page-title">
                                                        Restaurant Timings
                                                    </h3>
                                                </div>
                                                {/* Render timings in table format if available */}
                                                <div className="table-responsive">
                                                    <table className="table table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th>Day</th>
                                                                <th>Open From</th>
                                                                <th>Open To</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {Object.entries(timings).map(([day, { from, to }]) => (
                                                                <tr key={day}>
                                                                    <td style={{ textTransform: 'capitalize' }}>{day}</td>
                                                                    <td>{editRow === day ? <input type="time" name="from" value={formData.from} onChange={(e) => handleChange(e)} /> : from}</td>
                                                                    <td>{editRow === day ? <input type="time" name="to" value={formData.to} onChange={(e) => handleChange(e)} /> : to}</td>
                                                                    <td>
                                                                        {editRow === day ? (
                                                                            <button className="btn btn-primary btn-rounded btn-sm" onClick={() => handleSubmit(day)}>Save</button>
                                                                        ) : (
                                                                            <button className="btn btn-dark btn-rounded btn-sm" onClick={() => handleEdit(day)}>Edit</button>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </div>
    );
}

// CreateTimingsForm component for creating timings
const CreateTimingsForm = ({ onSave, onCancel }) => {
    const [formData, setFormData] = useState({
        monday: { from: "", to: "" },
        tuesday: { from: "", to: "" },
        wednesday: { from: "", to: "" },
        thursday: { from: "", to: "" },
        friday: { from: "", to: "" },
        saturday: { from: "", to: "" },
        sunday: { from: "", to: "" }
    });

    const handleChange = (e, day) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [day]: {
                ...prevState[day],
                [name]: value
            }
        }));
    };

    const handleSave = () => {
        // Check if any timing field is empty
        const isEmpty = Object.values(formData).some(({ from, to }) => from === "" || to === "");
        if (isEmpty) {
            alert("Please fill in all timing fields.");
            return;
        }
        onSave(formData);
    };

    return (
        <form>
            <table className="table">
                <thead>
                    <tr>
                        <th>Day</th>
                        <th>From</th>
                        <th>To</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(formData).map(([day, { from, to }]) => (
                        <tr key={day}>
                            <td>{day.charAt(0).toUpperCase() + day.slice(1)}</td>
                            <td>
                                <input type="time" name="from" value={from} onChange={(e) => handleChange(e, day)} required />
                            </td>
                            <td>
                                <input type="time" name="to" value={to} onChange={(e) => handleChange(e, day)} required />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <button type="button" className="btn btn-primary" onClick={handleSave}>Save</button>
            <button type="button" className="btn btn-secondary ml-2" onClick={onCancel}>Cancel</button>
        </form>
    );
}

export default FoodPartner;