import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import { API_URL } from '../../constants';
import vendorlogo from '../Assets/images/logo/vendor-logo.gif';


const AdminSignin = () => {
    const currentYear = new Date().getFullYear(); // Get the current year
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [enteredOtp, setEnteredOtp] = useState('');
    const [otpFromAPI, setOtpFromAPI] = useState('');
    const [vendor, setVendor] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        // Disable browser back button
        const disableBackButton = () => {
            window.history.pushState(null, "", window.location.href);
            window.onpopstate = function () {
                window.history.pushState(null, "", window.location.href);
            };
        };

        disableBackButton();
    }, []);

    const proceedLogin = (e) => {
        e.preventDefault();
        if (validate()) {
            const loginObj = {
                email,
                password
            };
    
            fetch(`${API_URL}/login`, {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(loginObj)
            })
                .then(response => response.json())
                .then(data => {
                    if (data.status === 200) {
                        // Display success alert
                        Swal.fire({
                            icon: 'success',
                            title: 'Successful',
                            text: data.message,
                            timer: 5000,
                            timerProgressBar: true,
                            showConfirmButton: false
                        });
    
                        // Set the vendor state with the received vendor data
                        setVendor(data.vendor);
    
                        // If login successful, save OTP from API response and proceed to send OTP
                        setOtpFromAPI(data.otp);
                        sessionStorage.setItem('Vendorotp', data.otp);
                        console.log(sessionStorage.getItem('Vendorotp'));

                        // If login successful, save user data and authentication status in sessionStorage
                        sessionStorage.setItem('isLoggedIn', true);
                        sessionStorage.setItem('user', JSON.stringify(data.vendor)); // Assuming data.vendor contains user details
                        sessionStorage.setItem('loggedinUser', JSON.stringify(data.vendor)); // Assuming data.vendor contains user details
                        sessionStorage.setItem('userType', data.vendor.userType);
                        sendOtpToMobile();
                    } else {
                        // Display error alert if login fails
                        Swal.fire({
                            icon: 'error',
                            title: 'Login Failed !',
                            text: data.message,
                            timer: 5000,
                            timerProgressBar: true,
                            showConfirmButton: false
                        });
                    }
                })
                .catch((err) => {
                    // Display error alert if there is an error in the fetch request
                    Swal.fire({
                        icon: 'error',
                        title: 'Login Failed',
                        text: err.message
                    });
                });
        }
    };
    

    const validate = () => {
        let result = true;
        if (!email) {
            Swal.fire({
                icon: 'warning',
                title: 'Please Enter Email'
            });
            result = false;
        }
        if (!password) {
            Swal.fire({
                icon: 'warning',
                title: 'Please Enter Password'
            });
            result = false;
        }
        return result;
    };

    const sendOtpToMobile = () => {
        // You can implement the logic to send OTP to mobile number here
        setOtpSent(true); // For demonstration purpose
    };

    const verifyOtp = () => {
        if (enteredOtp === sessionStorage.getItem('Vendorotp')) {
            // OTP verified successfully
            Swal.fire({
                icon: 'success',
                title: 'OTP Verified',
                text: 'OTP verification successful. Logging in...',
                timer: 5000,
                timerProgressBar: true,
                showConfirmButton: false
            });
    
            // Redirect to appropriate dashboard based on userType
            if (vendor && vendor.userType === 'Super Admin') {
                
                // Navigate to the SuperAdminDashboard with user details
                navigate('/SuperAdminDashboard', { state: { user: vendor } });
            } else if (vendor && vendor.userType === 'Regular User') {
                // Navigate to the RegularUserDashboard with user details
                navigate('/RegularUserDashboard', { state: { user: vendor } });
            }
        } else {
            // Display error alert if OTP verification fails
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Failed to verify OTP. Please try again.',
                timer: 5000,
                timerProgressBar: true,
                showConfirmButton: false
            });
        }
    };
    
    return (
        <div className="container-scroller">
            <div className="container-fluid page-body-wrapper full-page-wrapper">
                <div className="content-wrapper d-flex align-items-stretch auth auth-img-bg">
                    <div className="row flex-grow">
                        <div className="col-lg-6 d-flex align-items-center justify-content-center" style={{ paddingTop: '20px', paddingBottom: '30px' }}>
                            <div className="auth-form-transparent text-left p-3" style={{ boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)', borderRadius: '20px' }}>
                                <div className="brand-logo">
                                    <img src={vendorlogo} alt="logo" />
                                </div>
                                <h4>Welcome back!</h4>
                                <h6 className="font-weight-light">Happy to see you again! Signin as Admin</h6>
                                {!otpSent ? (
                                    <form className="pt-3" onSubmit={proceedLogin}>
                                        <div className="form-group">
                                            <label>Email</label>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text"><i className="fa fa-user text-primary"></i></span>
                                                </div>
                                                <input value={email} onChange={e => setEmail(e.target.value)} type="email" className="form-control form-control-lg border-left-0" id="email" name="email" placeholder="Email" />
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <label>Password</label>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text"><i className="fa fa-lock text-primary"></i></span>
                                                </div>
                                                <input value={password} onChange={e => setPassword(e.target.value)} type="password" className="form-control form-control-lg border-left-0" id="password" name="password" placeholder="Password" />
                                            </div>
                                        </div>

                                        <div className="my-2 d-flex justify-content-between align-items-center">
                                            <div className="form-check">
                                                <label className="form-check-label text-muted">
                                                    <input type="checkbox" className="form-check-input" />
                                                    Keep me signed in
                                                </label>
                                            </div>
                                            <a href="#" className="auth-link text-black">Forgot password?</a>
                                        </div>
                                        <div className="my-3">
                                            <button className="btn btn-block btn-primary font-weight-medium auth-form-btn">LOGIN</button>
                                        </div>

                                        <div className="text-center mt-4 font-weight-light">
                                            Sign in as <Link to='/' className="text-primary"><b style={{ fontSize: "18px" }}>Partner</b></Link>
                                        </div>
                                    </form>
                                ) : (
                                    <form className="pt-3" onSubmit={(e) => { e.preventDefault(); verifyOtp(); }}>
                                        <div className="form-group">
                                            <label>Enter OTP</label>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text"><i className="fa fa-lock text-primary"></i></span>
                                                </div>
                                                <input value={enteredOtp} onChange={e => setEnteredOtp(e.target.value)} type="text" className="form-control form-control-lg border-left-0" id="otp" name="otp" placeholder="Enter OTP" />
                                            </div>
                                        </div>
                                        <div className="my-3">
                                            <button className="btn btn-block btn-primary font-weight-medium auth-form-btn" disabled={!enteredOtp.trim()}>Verify OTP</button>
                                        </div>
                                    </form>
                                )}
                            </div>
                        </div>
                        <div className="col-lg-6 login-half-bg d-flex flex-row">
                            <p className="text-white font-weight-medium text-center flex-grow align-self-end">Copyright &copy; {currentYear}  All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminSignin;

