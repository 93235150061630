import React, { useEffect, useState } from "react";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Navbar/Sidebar";
import Footer from "../Navbar/Footer";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { API_URL } from "../../constants";
import Swal from 'sweetalert2';

const LabTestDetails = () => {
    const navigate = useNavigate();
    const { itemId } = useParams();
    const [user, setUser] = useState(null);
    const [labTest, setLabTest] = useState(null);
    const [expandedPackages, setExpandedPackages] = useState({});

    useEffect(() => {
        const isLoggedIn = sessionStorage.getItem('isLoggedIn');
        const storedUser = JSON.parse(sessionStorage.getItem('loggedinUser'));

        if (isLoggedIn && storedUser) {
            setUser(storedUser);
        } else {
            Swal.fire("Error!", "No access for this page. Please login and access.", "error").then(() => {
                navigate('/');
            });
        }

        const fetchLabTestDetails = async () => {
            try {
                const response = await axios.get(`${API_URL}/lab-tests/${itemId}`);
                if (response.data.success) {
                    setLabTest(response.data.labTests);
                } else {
                    console.error('Failed to fetch lab test details:', response.data.error);
                    Swal.fire("Error!", "Failed to fetch lab test details. Please try again later.", "error").then(() => {
                        navigate('/LabTests');
                    });
                }
            } catch (error) {
                console.error('Error fetching lab test details:', error);
                Swal.fire("Error!", "Failed to fetch lab test details. Please try again later.", "error").then(() => {
                    navigate('/LabTests');
                });
            }
        };

        fetchLabTestDetails();
    }, [itemId, navigate]);

    const togglePackage = (packageId) => {
        setExpandedPackages(prevState => ({
            ...prevState,
            [packageId]: !prevState[packageId]
        }));
    };

    return (
        <div className="container-scroller">
            <Navbar />
            <div className="container-fluid page-body-wrapper">
                <Sidebar />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="row grid-margin">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        {labTest && (
                                            <React.Fragment>
                                                <div className="col-lg-12 pl-lg-5">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <h3>{labTest.testName}</h3>
                                                        </div>
                                                        <div>
                                                            <button className="btn btn-primary">{labTest.category}</button>
                                                        </div>
                                                    </div>
                                                    <div className="mt-4 py-2">
                                                        <ul className="nav profile-navbar">
                                                            <li className="nav-item">
                                                                <button className="nav-link btn btn-rounded btn-inverse-danger d-flex align-items-center">
                                                                    <i className="fa fa-home mr-1"></i>
                                                                    Home Sample Collection
                                                                </button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="py-2 border-bottom">
                                                        <ul className="nav profile-navbar" style={{ display: 'flex', alignItems: 'center', listStyleType: 'none', padding: '0', margin: '0' }}>
                                                            <li className="nav-item" style={{ marginRight: '10px' }}>
                                                                <p style={{ fontSize: '18px', fontWeight: '700', margin: '0' }} className="nav-link d-flex align-items-center">
                                                                    ₹ {labTest.discountPrice}
                                                                </p>
                                                            </li>
                                                            <li className="nav-item" style={{ marginRight: '10px' }}>
                                                                <p style={{ fontSize: '18px', fontWeight: '600', textDecorationLine: 'line-through', textDecorationThickness: '3px', color: "darkgray", margin: '0' }} className="nav-link d-flex align-items-center">
                                                                    ₹ {labTest.actualPrice}
                                                                </p>
                                                            </li>
                                                            <li className="nav-item">
                                                                <p style={{ fontSize: '18px', fontWeight: '600', borderRadius: '30px', margin: '0' }} className=" d-flex align-items-center badge badge-success">
                                                                    {labTest.offerDiscount} % OFF
                                                                </p>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="mt-4">
                                                        <div>
                                                            <h5>About the Test</h5>
                                                        </div>
                                                        <ul className="nav profile-navbar">
                                                            <li className="nav-item">
                                                                <button className="nav-link btn btn-rounded d-flex align-items-center mr-2">
                                                                    <i className="fa fa-solid fa-venus-mars mr-1"></i>
                                                                    {labTest.gender}
                                                                </button>
                                                            </li>
                                                            <li className="nav-item">
                                                                <button className="nav-link btn btn-rounded d-flex align-items-center">
                                                                    <i className="fa fa-solid fa-user mr-1"></i>
                                                                    {labTest.ageGroup}
                                                                </button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="profile-feed">
                                                        <div className="d-flex align-items-start profile-feed-item">
                                                            <div>
                                                                <p style={{ fontSize: '16px', textAlign: 'justify' }}>{labTest.description}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 grid-margin stretch-card" style={{ paddingTop: '20px' }}>
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <h4 className="card-title">Lab Tests in this package</h4>
                                                                <div className="mt-4">
                                                                    <div className="accordion accordion-bordered" id="accordion-packages" role="tablist">
                                                                        {labTest.selectedPackages.map((pkg, index) => (
                                                                            <div className="card" key={index}>
                                                                                <div className="card-header" role="tab" id={`heading-${index}`}>
                                                                                    <h6 className="mb-0 expand_details" style={{ backgroundColor: 'gray', borderRadius: '10px', padding: '5px' }}>
                                                                                        <button style={{ Color: '#fff !important' }} className="expand_name btn btn-link" type="button" data-toggle="collapse" data-target={`#collapse-${index}`} aria-expanded="false" aria-controls={`collapse-${index}`}>
                                                                                            {pkg.packageName} <i className={`fas fa-chevron-${expandedPackages[pkg._id] ? 'up' : 'down'}`}></i>
                                                                                        </button>
                                                                                    </h6>
                                                                                </div>
                                                                                <div id={`collapse-${index}`} className={`collapse expand_details ${expandedPackages[pkg._id] ? 'show' : ''}`} role="tabpanel" aria-labelledby={`heading-${index}`} data-parent="#accordion-packages">
                                                                                    <div className="card-body">
                                                                                        <ul className="list-unstyled expand_details">
                                                                                            {pkg.packageDetails.map((detail, detailIndex) => (
                                                                                                <li style={{ paddingLeft: '20px' }} key={detailIndex}>
                                                                                                    <p>{detail}</p>
                                                                                                </li>
                                                                                            ))}
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </React.Fragment>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default LabTestDetails;
