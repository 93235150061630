import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Navbar/Sidebar";
import Footer from "../Navbar/Footer";
import axios from "axios";
import Swal from 'sweetalert2';
import { API_URL } from "../../constants";

const CreateLabTests = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [testName, setTestName] = useState('');
    const [actualPrice, setActualPrice] = useState('');
    const [discountOffer, setDiscountOffer] = useState('');
    const [discountPrice, setDiscountPrice] = useState('');
    const [category, setCategory] = useState('');
    const [description, setDescription] = useState('');
    const [packageCategories, setPackageCategories] = useState([]);
    const [selectedPackages, setSelectedPackages] = useState([]);
    const [categories, setCategories] = useState([]);
    const [gender, setGender] = useState('');
    const [ageGroup, setAgeGroup] = useState('');
    const [isChecked, setIsChecked] = useState(false); // State to track checkbox validation

    useEffect(() => {
        const isLoggedIn = sessionStorage.getItem('isLoggedIn');
        const storedUser = JSON.parse(sessionStorage.getItem('loggedinUser'));

        if (isLoggedIn && storedUser) {
            setUser(storedUser);
            fetchPackages(storedUser._id, storedUser.diagnosticCenterName);
            fetchCategories(storedUser._id, storedUser.diagnosticCenterName);
        } else {
            Swal.fire("Error!", "No access for this page. Please login and access.", "error").then(() => {
                navigate('/');
            });
        }
    }, []);

    const fetchCategories = async (userId, diagnosticCenterName) => {
        try {
            const response = await axios.get(`${API_URL}/diagnostic-categories/${userId}/${diagnosticCenterName}`);
            if (response.data.success) {
                setCategories(response.data.categories);
            } else {
                console.error('Failed to fetch categories:', response.data.error);
            }
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    const fetchPackages = async (userId, diagnosticCenterName) => {
        try {
            const response = await axios.get(`${API_URL}/diagnostic-package-details/${diagnosticCenterName}`);
            if (response.data.success) {
                setPackageCategories(response.data.diagnosticPackageDetails);
            } else {
                console.error('Failed to fetch packages:', response.data.error);
            }
        } catch (error) {
            console.error('Error fetching packages:', error);
        }
    };

    const calculateDiscountPrice = () => {
        if (actualPrice && discountOffer) {
            const calculatedDiscountPrice = (actualPrice - (actualPrice * discountOffer / 100)).toFixed(2);
            setDiscountPrice(calculatedDiscountPrice);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate checkbox is checked
        if (!isChecked) {
            Swal.fire("Error!", "Please select at least one package.", "error");
            return;
        }

        try {
            // Map to get selected package names and their details
            const selectedPackagesWithDetails = packageCategories
                .filter(pkg => selectedPackages.includes(pkg.packageName))
                .map(pkg => ({
                    packageName: pkg.packageName,
                    packageDetails: pkg.packageDetails
                }));

            const formData = {
                userId: user._id,
                diagnosticCenterName: user.diagnosticCenterName,
                testName,
                actualPrice,
                discountPrice,
                category,
                offerDiscount: discountOffer,
                description,
                selectedPackages: selectedPackagesWithDetails,
                gender,
                ageGroup
            };

            const response = await axios.post(`${API_URL}/lab-test-create`, formData);

            if (response.data.success) {
                Swal.fire("Success!", "Lab Test created successfully.", "success").then(() => {
                    navigate('/LabTests');
                });
            } else {
                Swal.fire("Error!", response.data.error, "error");
            }
        } catch (error) {
            console.error('Error creating lab test:', error);
            Swal.fire("Error!", error.response.data.error, "error");
        }
    };

    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            setSelectedPackages([...selectedPackages, value]);
        } else {
            setSelectedPackages(selectedPackages.filter((packageName) => packageName !== value));
        }
        
        // Update isChecked state based on whether at least one checkbox is checked
        setIsChecked(selectedPackages.length > 0 || checked);
    };

    return (
        <div className="container-scroller">
            <Navbar />
            <div className="container-fluid page-body-wrapper">
                <Sidebar loggedInUser={user} />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="page-header">
                            <h3 className="page-title">Create Lab Test</h3>
                        </div>
                        <div className="row grid-margin">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <form onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <label htmlFor="testName">Test Name</label>
                                                <input type="text" className="form-control" id="testName" value={testName} onChange={(e) => setTestName(e.target.value)} required />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="actualPrice">Actual Price</label>
                                                <input type="number" className="form-control" id="actualPrice" value={actualPrice} onChange={(e) => setActualPrice(e.target.value)} required />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="discountOffer">Discount Offer (%)</label>
                                                <input type="number" className="form-control" id="discountOffer" value={discountOffer} onChange={(e) => setDiscountOffer(e.target.value)} onBlur={calculateDiscountPrice} required />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="discountPrice">Discount Price</label>
                                                <input type="text" className="form-control" id="discountPrice" value={discountPrice} readOnly />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="category">Category</label>
                                                <select className="form-control" id="category" value={category} onChange={(e) => setCategory(e.target.value)} required>
                                                    <option value="">Select Category</option>
                                                    {categories.map((cat, index) => (
                                                        <option key={index} value={cat.categoryName}>{cat.categoryName}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div className="form-group">
                                                <label style={{ fontWeight: 'bold' }}>Package Names:</label>
                                                {packageCategories.map((diagPackage, index) => (
                                                    <div key={index} style={{ marginBottom: '5px' }}>
                                                        <input
                                                            type="checkbox"
                                                            id={`package-${index}`}
                                                            value={diagPackage.packageName}
                                                            checked={selectedPackages.includes(diagPackage.packageName)}
                                                            onChange={handleCheckboxChange}
                                                            style={{ marginRight: '5px' }}
                                                        />
                                                        <label htmlFor={`package-${index}`} style={{ marginLeft: '5px' }}>{diagPackage.packageName}</label>
                                                    </div>
                                                ))}
                                                {isChecked ? null : <div className="invalid-feedback" style={{ display: 'block' }}>Please select at least one package.</div>}
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="gender">Gender</label>
                                                <select className="form-control" id="gender" value={gender} onChange={(e) => setGender(e.target.value)} required>
                                                    <option value="">Select Gender</option>
                                                    <option value="Male">Male</option>
                                                    <option value="Female">Female</option>
                                                    <option value="Unisex">Unisex</option>
                                                </select>
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="ageGroup">Age Group</label>
                                                <select className="form-control" id="ageGroup" value={ageGroup} onChange={(e) => setAgeGroup(e.target.value)} required>
                                                    <option value="">Select Age Group</option>
                                                    <option value="All age groups">All Age Groups</option>
                                                    <option value="0 yrs - 10 yrs">0 yrs - 10 yrs</option>
                                                    <option value="10 yrs - 20 yrs">10 yrs - 20 yrs</option>
                                                    <option value="20 yrs - 30 yrs">20 yrs - 30 yrs</option>
                                                    <option value="30 yrs - 40 yrs">30 yrs - 40 yrs</option>
                                                    <option value="40 yrs - 99 yrs">40 yrs - 99 yrs</option>
                                                    <option value="18 yrs - 40 yrs">18 yrs - 40 yrs</option>
                                                    <option value="40 yrs - 99 yrs">40 yrs - 99 yrs</option>
                                                    <option value="15 yrs - 99 yrs">15 yrs - 99 yrs</option>
                                                </select>
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="description">Description</label>
                                                <textarea className="form-control" id="description" value={description} onChange={(e) => setDescription(e.target.value)} required />
                                            </div>

                                            <button type="submit" className="btn btn-primary">Create</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default CreateLabTests;
