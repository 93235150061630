import React, { useState, useEffect } from "react";
import Swal from 'sweetalert2';
import { Link, useNavigate } from "react-router-dom";
import vendorlogo from '../Assets/images/logo/vendor-logo.gif';

const Step2 = () => {
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear(); 
  const [userType, setUserType] = useState('');
  const [formData, setFormData] = useState({
    restaurantName: '',
    restaurantAddress: '',
    restaurantOwnerName: '',
    restaurantEmail: '',
    restaurantDescription: '',
    restaurantMobileNumber: '',
    diagnosticCenterName: '',
    diagnosticOwnerName: '',
    diagnosticDescription: '',
    diagnosticMobileNumber: '',
    pharmacyName: '',
    pharmacyOwnerName: '',
    pharmacyAddress: '',
    pharmacyMobileNumber: '',
    pharmacyEmail: '',
    pharmacyDescription: ''
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    const storedUserType = sessionStorage.getItem('userType');
    if (storedUserType) {
      setUserType(storedUserType);
    }
    const storedFormData = JSON.parse(sessionStorage.getItem('userTypeDetails'));
    if (storedFormData) {
      setFormData(storedFormData);
    }
    const disableBackButton = () => {
      window.history.pushState(null, "", window.location.href);
      window.onpopstate = function () {
        window.history.pushState(null, "", window.location.href);
      };
    };
    disableBackButton();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};

    // Helper function to check if a string starts with a dot
    const startsWithDot = (value) => {
        return value.trim().startsWith('.');
    };

    // Helper function to check if a string has less than 5 letters
    const lessThan5Letters = (value) => {
        return value.trim().length < 5;
    };

    if (userType === 'Food Partner') {
        if (!formData.restaurantName.trim()) {
            newErrors.restaurantName = 'Restaurant Name is required';
        } else if (startsWithDot(formData.restaurantName)) {
            newErrors.restaurantName = 'Restaurant Name should not start with a dot';
        } else if (lessThan5Letters(formData.restaurantName)) {
            newErrors.restaurantName = 'Restaurant Name should have at least 5 letters';
        }

        if (!formData.restaurantAddress.trim()) {
            newErrors.restaurantAddress = 'Restaurant Address is required';
        } else if (startsWithDot(formData.restaurantAddress)) {
            newErrors.restaurantAddress = 'Restaurant Address should not start with a dot';
        } else if (lessThan5Letters(formData.restaurantAddress)) {
            newErrors.restaurantAddress = 'Restaurant Address should have at least 5 letters';
        }

        if (!formData.restaurantOwnerName.trim()) {
            newErrors.restaurantOwnerName = 'Restaurant Owner Name is required';
        } else if (startsWithDot(formData.restaurantOwnerName)) {
            newErrors.restaurantOwnerName = 'Restaurant Owner Name should not start with a dot';
        } else if (lessThan5Letters(formData.restaurantOwnerName)) {
            newErrors.restaurantOwnerName = 'Restaurant Owner Name should have at least 5 letters';
        }

        if (!formData.restaurantDescription.trim()) {
            newErrors.restaurantDescription = 'Restaurant Description is required';
        } else if (startsWithDot(formData.restaurantDescription)) {
            newErrors.restaurantDescription = 'Restaurant Description should not start with a dot';
        } else if (lessThan5Letters(formData.restaurantDescription)) {
            newErrors.restaurantDescription = 'Restaurant Description should have at least 5 letters';
        }

        if (!formData.restaurantEmail.trim()) {
            newErrors.restaurantEmail = 'Email is required';
        } else if (!formData.restaurantEmail.endsWith('@gmail.com')) {
            newErrors.restaurantEmail = 'Please enter a valid email address ending with @gmail.com';
        } else if (startsWithDot(formData.restaurantEmail)) {
            newErrors.restaurantEmail = 'Email should not start with a dot';
        } else if (lessThan5Letters(formData.restaurantEmail)) {
            newErrors.restaurantEmail = 'Email should have at least 5 letters';
        }

        if (!formData.restaurantMobileNumber.trim()) {
            newErrors.restaurantMobileNumber = 'Mobile Number is required';
        } else if (startsWithDot(formData.restaurantMobileNumber)) {
            newErrors.restaurantMobileNumber = 'Mobile Number should not start with a dot';
        } else if (lessThan5Letters(formData.restaurantMobileNumber)) {
            newErrors.restaurantMobileNumber = 'Mobile Number should have at least 5 letters';
        } else if (formData.restaurantMobileNumber.trim().length !== 10) {
            newErrors.restaurantMobileNumber = 'Mobile Number should be exactly 10 digits';
        }
    } else if (userType === 'Lab Technician') {
        if (!formData.diagnosticCenterName.trim()) {
            newErrors.diagnosticCenterName = 'Diagnostic Center Name is required';
        } else if (startsWithDot(formData.diagnosticCenterName)) {
            newErrors.diagnosticCenterName = 'Diagnostic Center Name should not start with a dot';
        } else if (lessThan5Letters(formData.diagnosticCenterName)) {
            newErrors.diagnosticCenterName = 'Diagnostic Center Name should have at least 5 letters';
        }

        if (!formData.diagnosticOwnerName.trim()) {
            newErrors.diagnosticOwnerName = 'Diagnostic Center Owner Name is required';
        } else if (startsWithDot(formData.diagnosticOwnerName)) {
            newErrors.diagnosticOwnerName = 'Diagnostic Center Owner Name should not start with a dot';
        } else if (lessThan5Letters(formData.diagnosticOwnerName)) {
            newErrors.diagnosticOwnerName = 'Diagnostic Center Owner Name should have at least 5 letters';
        }

        if (!formData.diagnosticDescription.trim()) {
            newErrors.diagnosticDescription = 'Diagnostic Center Description is required';
        } else if (startsWithDot(formData.diagnosticDescription)) {
            newErrors.diagnosticDescription = 'Diagnostic Center Description should not start with a dot';
        } else if (lessThan5Letters(formData.diagnosticDescription)) {
            newErrors.diagnosticDescription = 'Diagnostic Center Description should have at least 5 letters';
        }

        if (!formData.diagnosticMobileNumber.trim()) {
            newErrors.diagnosticMobileNumber = 'Mobile Number is required';
        } else if (startsWithDot(formData.diagnosticMobileNumber)) {
            newErrors.diagnosticMobileNumber = 'Mobile Number should not start with a dot';
        } else if (lessThan5Letters(formData.diagnosticMobileNumber)) {
            newErrors.diagnosticMobileNumber = 'Mobile Number should have at least 5 letters';
        } else if (formData.diagnosticMobileNumber.trim().length !== 10) {
            newErrors.diagnosticMobileNumber = 'Mobile Number should be exactly 10 digits';
        }
    } else if (userType === 'Pharmacy Partner') {
        if (!formData.pharmacyName.trim()) {
            newErrors.pharmacyName = 'Pharmacy Name is required';
        } else if (startsWithDot(formData.pharmacyName)) {
            newErrors.pharmacyName = 'Pharmacy Name should not start with a dot';
        } else if (lessThan5Letters(formData.pharmacyName)) {
            newErrors.pharmacyName = 'Pharmacy Name should have at least 5 letters';
        }

        if (!formData.pharmacyOwnerName.trim()) {
            newErrors.pharmacyOwnerName = 'Pharmacy Owner Name is required';
        } else if (startsWithDot(formData.pharmacyOwnerName)) {
            newErrors.pharmacyOwnerName = 'Pharmacy Owner Name should not start with a dot';
        } else if (lessThan5Letters(formData.pharmacyOwnerName)) {
            newErrors.pharmacyOwnerName = 'Pharmacy Owner Name should have at least 5 letters';
        }

        if (!formData.pharmacyAddress.trim()) {
            newErrors.pharmacyAddress = 'Pharmacy Address is required';
        } else if (startsWithDot(formData.pharmacyAddress)) {
            newErrors.pharmacyAddress = 'Pharmacy Address should not start with a dot';
        } else if (lessThan5Letters(formData.pharmacyAddress)) {
            newErrors.pharmacyAddress = 'Pharmacy Address should have at least 5 letters';
        }

        if (!formData.pharmacyEmail.trim()) {
            newErrors.pharmacyEmail = 'Email is required';
        } else if (!formData.pharmacyEmail.endsWith('@gmail.com')) {
            newErrors.pharmacyEmail = 'Please enter a valid email address ending with @gmail.com';
        } else if (startsWithDot(formData.pharmacyEmail)) {
            newErrors.pharmacyEmail = 'Email should not start with a dot';
        } else if (lessThan5Letters(formData.pharmacyEmail)) {
            newErrors.pharmacyEmail = 'Email should have at least 5 letters';
        }

        if (!formData.pharmacyMobileNumber.trim()) {
            newErrors.pharmacyMobileNumber = 'Mobile Number is required';
        } else if (startsWithDot(formData.pharmacyMobileNumber)) {
            newErrors.pharmacyMobileNumber = 'Mobile Number should not start with a dot';
        } else if (lessThan5Letters(formData.pharmacyMobileNumber)) {
            newErrors.pharmacyMobileNumber = 'Mobile Number should have at least 5 letters';
        } else if (formData.pharmacyMobileNumber.trim().length !== 10) {
            newErrors.pharmacyMobileNumber = 'Mobile Number should be exactly 10 digits';
        }

        if (!formData.pharmacyDescription.trim()) {
            newErrors.pharmacyDescription = 'Pharmacy Description is required';
        } else if (startsWithDot(formData.pharmacyDescription)) {
            newErrors.pharmacyDescription = 'Pharmacy Description should not start with a dot';
        } else if (lessThan5Letters(formData.pharmacyDescription)) {
            newErrors.pharmacyDescription = 'Pharmacy Description should have at least 5 letters';
        }
    }

    return newErrors;
};

  const handleFormSubmit = () => {
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }
    setErrors({});
    const filteredFormData = {};
    Object.keys(formData).forEach(key => {
      if (formData[key].trim() !== '') {
        filteredFormData[key] = formData[key];
      }
    });
    if (!userType.trim()) {
      Swal.fire("Error!", "User type not specified.", "error");
      return;
    }
    filteredFormData.userType = userType;
    sessionStorage.setItem('userTypeDetails', JSON.stringify(filteredFormData));
    navigate('/Signup-documents');
  };

  return (
    <div className="container-scroller">
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex align-items-stretch auth auth-img-bg">
          <div className="row flex-grow">
            <div className="col-lg-6 d-flex align-items-center justify-content-center" style={{ paddingTop: '20px', paddingBottom: '30px' }}>
              <div className="auth-form-transparent text-left p-3" style={{ boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)', borderRadius: '20px' }}>
                <div className="brand-logo">
                  <img src={vendorlogo} alt="logo" />
                </div>
                <h4>New here?</h4>
                <h6 className="font-weight-light">Join us today! It takes only a few steps</h6>
                {userType === 'Food Partner' && (
                  <form className="pt-3">
                    <div className="form-group">
                      <label>Restaurant Name</label>
                      <div className="input-group">
                        <div className="input-group-prepend bg-transparent">
                          <span className="input-group-text bg-transparent border-right-0">
                            <i className="far fa-keyboard text-primary"></i>
                          </span>
                        </div>
                        <input type="text" className={`form-control ${errors.restaurantName ? 'is-invalid' : ''}`} placeholder="Enter restaurant name" name="restaurantName" value={formData.restaurantName} onChange={handleInputChange} />
                        {errors.restaurantName && <div className="invalid-feedback">{errors.restaurantName}</div>}
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Address of Restaurant</label>
                      <div className="input-group">
                        <div className="input-group-prepend bg-transparent">
                          <span className="input-group-text bg-transparent border-right-0">
                            <i className="fa fa-location-arrow text-primary"></i>
                          </span>
                        </div>
                        <textarea className={`form-control ${errors.restaurantAddress ? 'is-invalid' : ''}`} placeholder="Address of Restaurant" name="restaurantAddress" value={formData.restaurantAddress} onChange={handleInputChange} />
                        {errors.restaurantAddress && <div className="invalid-feedback">{errors.restaurantAddress}</div>}
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Description of Restaurant</label>
                      <div className="input-group">
                        <div className="input-group-prepend bg-transparent">
                          <span className="input-group-text bg-transparent border-right-0">
                            <i className="fa fa-location-arrow text-primary"></i>
                          </span>
                        </div>
                        <textarea className={`form-control ${errors.restaurantDescription ? 'is-invalid' : ''}`} placeholder="Description of Restaurant" name="restaurantDescription" value={formData.restaurantDescription} onChange={handleInputChange} />
                        {errors.restaurantDescription && <div className="invalid-feedback">{errors.restaurantDescription}</div>}
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Restaurant Owner Name</label>
                      <div className="input-group">
                        <div className="input-group-prepend bg-transparent">
                          <span className="input-group-text bg-transparent border-right-0">
                            <i className="fa fa-user text-primary"></i>
                          </span>
                        </div>
                        <input type="text" className={`form-control ${errors.restaurantOwnerName ? 'is-invalid' : ''}`} placeholder="Restaurant owner name" name="restaurantOwnerName" value={formData.restaurantOwnerName} onChange={handleInputChange} />
                        {errors.restaurantOwnerName && <div className="invalid-feedback">{errors.restaurantOwnerName}</div>}
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Email</label>
                      <div className="input-group">
                        <div className="input-group-prepend bg-transparent">
                          <span className="input-group-text bg-transparent border-right-0">
                            <i className="far fa-envelope-open text-primary"></i>
                          </span>
                        </div>
                        <input type="email" className={`form-control ${errors.restaurantEmail ? 'is-invalid' : ''}`} placeholder="Email" name="restaurantEmail" value={formData.restaurantEmail} onChange={handleInputChange} />
                        {errors.restaurantEmail && <div className="invalid-feedback">{errors.restaurantEmail}</div>}
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Mobile Number</label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">+91</span>
                        </div>
                        <input type="number" pattern="[0-9]{10}" className={`form-control ${errors.restaurantMobileNumber ? 'is-invalid' : ''}`} placeholder="Enter mobile number" name="restaurantMobileNumber" value={formData.restaurantMobileNumber} onChange={handleInputChange} />
                        {errors.restaurantMobileNumber && <div className="invalid-feedback">{errors.restaurantMobileNumber}</div>}
                      </div>
                    </div>
                  </form>
                )}
                {userType === 'Lab Technician' && (
                  <form className="pt-3">
                    <div className="form-group">
                      <label>Diagnostic Center Name</label>
                      <div className="input-group">
                        <div className="input-group-prepend bg-transparent">
                          <span className="input-group-text bg-transparent border-right-0">
                            <i className="far fa-keyboard text-primary"></i>
                          </span>
                        </div>
                        <input type="text" className={`form-control ${errors.diagnosticCenterName ? 'is-invalid' : ''}`} placeholder="Enter diagnostic center name" name="diagnosticCenterName" value={formData.diagnosticCenterName} onChange={handleInputChange} />
                        {errors.diagnosticCenterName && <div className="invalid-feedback">{errors.diagnosticCenterName}</div>}
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Diagnostic Center Owner Name</label>
                      <div className="input-group">
                        <div className="input-group-prepend bg-transparent">
                          <span className="input-group-text bg-transparent border-right-0">
                            <i className="fa fa-user text-primary"></i>
                          </span>
                        </div>
                        <input type="text" className={`form-control ${errors.diagnosticOwnerName ? 'is-invalid' : ''}`} placeholder="Enter Diagnostic Center Owner name" name="diagnosticOwnerName" value={formData.diagnosticOwnerName} onChange={handleInputChange} />
                        {errors.diagnosticOwnerName && <div className="invalid-feedback">{errors.diagnosticOwnerName}</div>}
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Description of Diagnostic Center</label>
                      <div className="input-group">
                        <div className="input-group-prepend bg-transparent">
                          <span className="input-group-text bg-transparent border-right-0">
                            <i className="fa fa-location-arrow text-primary"></i>
                          </span>
                        </div>
                        <textarea className={`form-control ${errors.diagnosticDescription ? 'is-invalid' : ''}`} placeholder="Description of Diagnostic Center" name="diagnosticDescription" value={formData.diagnosticDescription} onChange={handleInputChange} />
                        {errors.diagnosticDescription && <div className="invalid-feedback">{errors.diagnosticDescription}</div>}

                      </div>
                    </div>
                    <div className="form-group">
                      <label>Mobile Number</label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">+91</span>
                        </div>
                        <input type="number" pattern="[0-9]{10}" className={`form-control ${errors.diagnosticMobileNumber ? 'is-invalid' : ''}`} placeholder="Enter mobile number" name="diagnosticMobileNumber" value={formData.diagnosticMobileNumber} onChange={handleInputChange} />
                        {errors.diagnosticMobileNumber && <div className="invalid-feedback">{errors.diagnosticMobileNumber}</div>}
                      </div>
                    </div>
                  </form>
                )}
                {userType === 'Pharmacy Partner' && (
                  <form className="pt-3">
                    <div className="form-group">
                      <label>Pharmacy Name</label>
                      <div className="input-group">
                        <div className="input-group-prepend bg-transparent">
                          <span className="input-group-text bg-transparent border-right-0">
                            <i className="far fa-keyboard text-primary"></i>
                          </span>
                        </div>
                        <input type="text" className={`form-control ${errors.pharmacyName ? 'is-invalid' : ''}`} placeholder="Enter Pharmacy name" name="pharmacyName" value={formData.pharmacyName} onChange={handleInputChange} />
                        {errors.pharmacyName && <div className="invalid-feedback">{errors.pharmacyName}</div>}
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Pharmacy Owner Name</label>
                      <div className="input-group">
                        <div className="input-group-prepend bg-transparent">
                          <span className="input-group-text bg-transparent border-right-0">
                            <i className="fa fa-user text-primary"></i>
                          </span>
                        </div>
                        <input type="text" className={`form-control ${errors.pharmacyOwnerName ? 'is-invalid' : ''}`} placeholder="Enter Pharmacy Owner Name" name="pharmacyOwnerName" value={formData.pharmacyOwnerName} onChange={handleInputChange} />
                        {errors.pharmacyOwnerName && <div className="invalid-feedback">{errors.pharmacyOwnerName}</div>}
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Pharmacy Address</label>
                      <div className="input-group">
                        <div className="input-group-prepend bg-transparent">
                          <span className="input-group-text bg-transparent border-right-0">
                            <i className="fa fa-location-arrow text-primary"></i>
                          </span>
                        </div>
                        <textarea className={`form-control ${errors.pharmacyAddress ? 'is-invalid' : ''}`} placeholder="Enter address of Pharmacy" name="pharmacyAddress" value={formData.pharmacyAddress} onChange={handleInputChange} />
                        {errors.pharmacyAddress && <div className="invalid-feedback">{errors.pharmacyAddress}</div>}
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Pharmacy Mobile Number</label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">+91</span>
                        </div>
                        <input type="number" pattern="[0-9]{10}" className={`form-control ${errors.pharmacyMobileNumber ? 'is-invalid' : ''}`} placeholder="Enter pharmacy mobile number" name="pharmacyMobileNumber" value={formData.pharmacyMobileNumber} onChange={handleInputChange} />
                        {errors.pharmacyMobileNumber && <div className="invalid-feedback">{errors.pharmacyMobileNumber}</div>}
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Email</label>
                      <div className="input-group">
                        <div className="input-group-prepend bg-transparent">
                          <span className="input-group-text bg-transparent border-right-0">
                            <i className="far fa-envelope-open text-primary"></i>
                          </span>
                        </div>
                        <input type="email" className={`form-control ${errors.pharmacyEmail ? 'is-invalid' : ''}`} placeholder="Email" name="pharmacyEmail" value={formData.pharmacyEmail} onChange={handleInputChange} />
                        {errors.pharmacyEmail && <div className="invalid-feedback">{errors.pharmacyEmail}</div>}
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Description of Pharmacy</label>
                      <div className="input-group">
                        <div className="input-group-prepend bg-transparent">
                          <span className="input-group-text bg-transparent border-right-0">
                            <i className="fa fa-location-arrow text-primary"></i>
                          </span>
                        </div>
                        <textarea className={`form-control ${errors.pharmacyDescription ? 'is-invalid' : ''}`} placeholder="Description of Pharmacy" name="pharmacyDescription" value={formData.pharmacyDescription} onChange={handleInputChange} />
                        {errors.pharmacyDescription && <div className="invalid-feedback">{errors.pharmacyDescription}</div>}
                      </div>
                    </div>
                  </form>
                )}
                <div style={{ marginTop: '2rem' }}>
                  <Link to="/signup" className="btn btn-secondary mr-2">
                    Previous
                  </Link>
                  <button type="button" className="btn btn-primary" onClick={handleFormSubmit}>
                    Next
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-6 register-half-bg d-flex flex-row">
              <p className="text-white font-weight-medium text-center flex-grow align-self-end">Copyright &copy; {currentYear} All rights reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Step2;
