import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { API_URL } from '../../constants';
import Navbar from '../Navbar/Navbar';
import Sidebar from '../Navbar/Sidebar';
import Footer from '../Navbar/Footer';
import { FaTrash } from 'react-icons/fa';
import './LabTest.css'

const EditPackageDetails = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);

    const { id } = useParams();
    const [packageName, setPackageName] = useState('');
    const [packageDetails, setPackageDetails] = useState([]);
    const [newDetail, setNewDetail] = useState('');

    useEffect(() => {
        const isLoggedIn = sessionStorage.getItem('isLoggedIn');
        const storedUser = JSON.parse(sessionStorage.getItem('loggedinUser'));

        if (isLoggedIn && storedUser) {
            setUser(storedUser);
        } else {
            navigate('/');
        }
    }, [navigate]);

    useEffect(() => {
        const fetchPackageDetails = async () => {
            try {
                if (!user) return; // Check if user is available

                const response = await fetch(`${API_URL}/diagnostic-package-details/${id}/${user.diagnosticCenterName}`);
                const data = await response.json();
                if (data.success === "error") {
                    throw new Error(data.error);
                }
                const { packageName, packageDetails } = data.diagnosticPackageDetails;
                setPackageName(packageName);
                setPackageDetails(packageDetails);
            } catch (error) {
                console.error("Error fetching package details:", error);
                Swal.fire("Error!", error.message, "error");
            }
        };

        fetchPackageDetails();
    }, [id, user]);

    const handleAddMore = () => {
        setPackageDetails([...packageDetails, newDetail]);
        setNewDetail('');
    };

    const handleDeleteDetail = (index) => {
        const updatedDetails = [...packageDetails];
        updatedDetails.splice(index, 1);
        setPackageDetails(updatedDetails);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const requestBody = {
                id: id,
                diagnosticCenterName: user.diagnosticCenterName,
                packageName: packageName,
                packageDetails: packageDetails
            };
    
            const response = await fetch(`${API_URL}/diagnostic-package-details`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody)
            });
    
            const data = await response.json();
            if (data.success === "error") {
                throw new Error(data.error);
            }
            Swal.fire("Success!", "Package updated successfully.", "success");
            navigate('/package-details');
        } catch (error) {
            Swal.fire("Error!", error.message, "error");
        }
    };
    

    return (
        <div className="container-scroller">
            <Navbar />
            <div className="container-fluid page-body-wrapper">
                <Sidebar loggedInUser={user} />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="page-header">
                            <h3>Edit Package Details</h3>
                        </div>
                        <div className="row grid-margin">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <form onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <label>Package Name:</label>
                                                <input type="text" className="form-control" value={packageName} onChange={(e) => setPackageName(e.target.value)} readOnly />
                                            </div>
                                            <div className="form-group">
                                                <label>Package Details:</label>
                                                {packageDetails.map((detail, index) => (
                                                    <div key={index} className="d-flex align-items-center">
                                                        <input type="text" className="form-control mr-2" value={detail} onChange={(e) => {
                                                            const updatedDetails = [...packageDetails];
                                                            updatedDetails[index] = e.target.value;
                                                            setPackageDetails(updatedDetails);
                                                        }} />
                                                        <FaTrash className="delete-icon remove_icon" onClick={() => handleDeleteDetail(index)} />
                                                    </div>
                                                ))}
                                                <div className="mt-2">
                                                    <button type="button" className="btn btn-primary btn-sm" onClick={handleAddMore}>Add More</button>
                                                </div>
                                            </div>
                                            <button className="btn btn-warning ml-2" onClick={() => navigate('/package-details')}>Back</button>

                                            <button type="submit" className="btn btn-primary ml-2">Submit</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditPackageDetails;
