import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import { API_URL } from '../../constants';

const Sidebar = ({ loggedInUser }) => { // Receive loggedInUser as props
    const currentYear = new Date().getFullYear(); // Get the current year
    const navigate = useNavigate();
    const [userType, setUserType] = useState('');

    useEffect(() => {
        // Retrieve user type from session storage
        const storedUserType = sessionStorage.getItem('userType');
        if (storedUserType) {
            setUserType(storedUserType);
        }
    }, []);

    return (
        <nav className="sidebar sidebar-offcanvas" id="sidebar">
            <ul className="nav">
                {userType === 'Super Admin' && (
                    <div>
                        <li className="nav-item">
                            <Link className="nav-link" to='/SuperAdminDashboard'>
                                <i className="fa fa-home menu-icon"></i>
                                <span className="menu-title">Dashboard</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to='/registeredUsers'>
                                <i className="fa fa-users menu-icon"></i>
                                <span className="menu-title">All Users</span>
                            </Link>
                        </li>

                        <li className="nav-item">
                            <Link className="nav-link" to='/VerifiedUsers'>
                                <i className="fa fa-check-circle menu-icon" style={{ color: 'green' }}></i>
                                <span className="menu-title">Verified Users </span>
                            </Link>
                        </li>

                        <li className="nav-item">
                            <Link className="nav-link" to='/NotVerifiedUsers'>
                                <i className="fa fa-window-close menu-icon" style={{ color: 'green' }}></i>
                                <span className="menu-title">Not Verified Users </span>
                            </Link>
                        </li>
                    </div>
                )}
                {userType === 'Food Partner' && (
                    <div>
                        <li className="nav-item">
                            <Link className="nav-link" to='/FoodPartnerDashboard'>
                                <i className="fa fa-user menu-icon"></i>
                                <span className="menu-title">Dashboard</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to='/foodCategories'>
                                <i className="fa fa-list-alt menu-icon"></i>
                                <span className="menu-title">Categories</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to='/menuItems'>
                                <i className="fa fa-list-alt menu-icon"></i>
                                <span className="menu-title">Menu Items</span>
                            </Link>
                        </li>
                    </div>
                )}

                {userType === 'Lab Technician' && (
                    <div>
                        <li className="nav-item">
                            <Link className="nav-link" to='/LabTechnicianDashboard'>
                                <i className="fa fa-user menu-icon"></i>
                                <span className="menu-title">Dashboard</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to='/packeages'>
                                <i className="fa fa-list-alt menu-icon"></i>
                                <span className="menu-title">Package Names</span>
                            </Link>
                        </li>

                        <li className="nav-item">
                            <Link className="nav-link" to='/package-details'>
                                <i className="fa fa-list-alt menu-icon"></i>
                                <span className="menu-title">Package Details</span>
                            </Link>
                        </li>

                        <li className="nav-item">
                            <Link className="nav-link" to='/LabCategories'>
                                <i className="fa fa-list-alt menu-icon"></i>
                                <span className="menu-title">Lab Test Categories</span>
                            </Link>
                        </li>

                        <li className="nav-item">
                            <Link className="nav-link" to='/LabTests'>
                                <i class="fa fa-flask menu-icon" aria-hidden="true"></i>
                                <span className="menu-title">Lab Test Details</span>
                            </Link>
                        </li>
                    </div>
                )}

                {userType === 'Pharmacy Partner' && (
                    <div>
                        <li className="nav-item">
                            <Link className="nav-link" to='/PharmacyPartnerDashboard'>
                                <i className="fa fa-user menu-icon"></i>
                                <span className="menu-title">Dashboard</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to='/PharmacyCategories'>
                                <i className="fa fa-list-alt menu-icon"></i>
                                <span className="menu-title">Categories</span>
                            </Link>
                        </li>

                        <li className="nav-item">
                            <Link className="nav-link" to='/PharmacyMedicine'>
                                <i class="fa fa-flask menu-icon" aria-hidden="true"></i>
                                <span className="menu-title">Products</span>
                            </Link>
                        </li>
                    </div>
                )}
            </ul>
        </nav>
    );
}

export default Sidebar;
