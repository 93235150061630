import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { API_URL } from "../../constants";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Navbar/Sidebar";
import Footer from "../Navbar/Footer";
import axios from "axios";
import ReactPaginate from "react-paginate";
import './Users.css'

const VerifiedUsers = () => {
  const currentYear = new Date().getFullYear(); // Get the current year
  const navigate = useNavigate();
  const [userCount, setUserCount] = useState(null);
  const [user, setUser] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage] = useState(10);
  const [vendors, setVendors] = useState([]);
  const pageCount = Math.ceil(vendors.length / perPage);

  useEffect(() => {
    const isLoggedIn = sessionStorage.getItem("isLoggedIn");
    const storedUser = sessionStorage.getItem("user");

    // Check if user is logged in and user data is available in session storage
    if (!(isLoggedIn && storedUser)) {
      // If user is not logged in, show error message and navigate back to login page
      Swal.fire("Error!", "No access for this page. Please login and access.", "error").then(() => {
        navigate("/");
      });
    }
  }, []);

  // Function to fetch vendors from the server
  const fetchVendors = () => {
    axios
      .get(`${API_URL}/verifiedusers`)
      .then((response) => {
        setVendors(response.data);
      })
      .catch((err) => console.log("Error fetching vendors:", err));
  };

  useEffect(() => {
    // Fetch vendors when the component mounts
    fetchVendors();

    // Refresh vendors every 2 seconds
    const interval = setInterval(fetchVendors, 2000);

    // Clear the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const offset = currentPage * perPage;
  const verifiedUsers = vendors.filter(vendor => vendor.isVerified === "true");

  const currentPageData = verifiedUsers.slice(offset, offset + perPage).map((vendor) => (
    <tr key={vendor._id}>
      <td>{vendor._id}</td>
      <td>{vendor.userName}</td>
      <td>{vendor.email}</td>
      <td>{vendor.userType}</td>
      <td>{vendor.mobileNumber}</td>
      <td>
        {(() => {
          if (vendor.isVerified === "false") {
            return <label className="badge btn btn-secondary btn-rounded btn-fw"> Not Verified</label>;
          } else if (vendor.isVerified === "true") {
            return <label className="badge btn btn-success btn-rounded btn-fw">Verified</label>;
          }
        })()}
      </td>
      <td>
        {(() => {
          if (vendor.status === "false") {
            return <label className="badge btn btn-secondary btn-rounded btn-fw"> In-active</label>;
          } else if (vendor.status === "true") {
            return <label className="badge btn btn-primary btn-rounded btn-fw">Active</label>;
          }
        })()}
      </td>
      <td>
        <Link to={`/user/${vendor._id}`} className="btn btn-dark btn-rounded btn-fw">
          View
        </Link>
      </td>
    </tr>
  ));

  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper">
        <Sidebar />
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="page-header">
              <h3 className="page-title">Registered Partners</h3>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>User ID</th>
                            <th>User Name</th>
                            <th>Mail</th>
                            <th>User Type</th>
                            <th>Phone Number</th>
                            <th>is Verified</th>
                            <th>Status</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>{currentPageData}</tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
          </div>
          <Footer />
          
        </div>
      </div>
    </div>
  );
};

export default VerifiedUsers;
