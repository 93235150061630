import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Navbar/Sidebar";
import Footer from "../Navbar/Footer";
import Swal from 'sweetalert2';
import axios from 'axios';
import { API_URL } from "../../constants";
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import '../FoodPartner/MenuItems.css';

const LabTechnician = () => {
    const location = useLocation();
    const [categoryCount, setCategoryCount] = useState(0);
    const [labTestCount, setLabTestCount] = useState(0);
    const currentYear = new Date().getFullYear(); // Get the current year
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [timings, setTimings] = useState(null);
    const [editRow, setEditRow] = useState(""); // State to track the row being edited
    const [formData, setFormData] = useState({}); // State to store the form data for editing
    const [diagnosticDetails, setDiagnosticDetails] = useState(null); // State to store diagnostic details
    const [editingDiagnosticDetails, setEditingDiagnosticDetails] = useState(false); // State to track whether editing diagnostic details
    const [showCreateTimingsForm, setShowCreateTimingsForm] = useState(false); // State to show/hide create timings form
    const [verifiedDiagnosticStatus, setVerifiedDiagnosticStatus] = useState(false);

    useEffect(() => {
        const isLoggedIn = sessionStorage.getItem('isLoggedIn');
        const storedUser = JSON.parse(sessionStorage.getItem('loggedinUser'));

        // Check if user is logged in and user data is available in session storage
        if (isLoggedIn && storedUser) {
            // Set the user object directly
            setUser(storedUser);
            fetchTimings(storedUser._id);
            fetchDiagnosticDetails(storedUser._id);
            // Fetch verified Diagnostic status
            fetchVerifiedDiagnosticStatus(storedUser._id);
            fetchCounts(storedUser._id, storedUser.diagnosticCenterName);
            console.log(storedUser);
        } else {
            // If user is not logged in, show error message and navigate back to login page
            Swal.fire("Error!", "No access for this page. Please login and access.", "error").then(() => {
                navigate('/');
            });
        }
    }, []);

    
    const fetchTimings = async (userId) => {
        try {
            const timingsResponse = await axios.get(`${API_URL}/timings/${userId}`);
            if (timingsResponse.data.success) {
                setTimings(timingsResponse.data.timings.timings); // Extract timings from the response
            }
        } catch (error) {
            console.error('Error fetching timings:', error);
        }
    };

    const fetchDiagnosticDetails = async (userId) => {
        try {
            const response = await axios.get(`${API_URL}/diagnostic-details/${userId}`);
            if (response.data.success) {
                setDiagnosticDetails(response.data.diagnosticDetails);
            } else {
                console.error('Failed to fetch diagnostic details:', response.data.error);
            }
        } catch (error) {
            console.error('Error fetching diagnostic details:', error);
        }
    };

    const handleEdit = (day) => {
        // Set the row to be edited and store current timings in the form data
        setEditRow(day);
        setFormData(timings[day]);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (day) => {
        try {
            const userId = user._id;
            const response = await axios.post(`${API_URL}/all-timings`, {
                userId,
                timings: {
                    ...timings,
                    [day]: formData
                }
            });
            if (response.data.success) {
                // Show success message or update UI accordingly
                console.log('Timings updated successfully');
                fetchTimings(userId); // Fetch updated timings
                setEditRow(""); // Clear the row being edited
            } else {
                // Show error message or update UI accordingly
                console.error('Failed to update timings:', response.data.error);
            }
        } catch (error) {
            console.error('Error updating timings:', error);
        }
    };

    const handleDiagnosticDetailsEdit = () => {
        setEditingDiagnosticDetails(true); // Set editing state to true
        // Optionally, you can load the existing diagnostic details into a form for editing
        setFormData({ ...diagnosticDetails }); // Initialize form data with existing diagnostic details
    };

    const handleDiagnosticDetailsSave = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior
        try {
            const userId = user._id;
            const response = await axios.put(`${API_URL}/diagnostic-details/${userId}`, formData); // Use PUT method and correct URL
            if (response.data.success) {
                // Show success message or update UI accordingly
                Swal.fire("Success!", "Diagnostic details updated successfully", "success");
                setDiagnosticDetails(formData); // Update diagnostic details in state
                setEditingDiagnosticDetails(false); // Set editing state to false after saving
            } else {
                // Show error message or update UI accordingly
                Swal.fire("Error!", response.data.error || "Failed to update diagnostic details", "error");
            }
        } catch (error) {
            console.error('Error updating diagnostic details:', error);
            Swal.fire("Error!", error.response.data.error, "error");
        }
    };

    const handleCreateTimings = () => {
        setShowCreateTimingsForm(true); // Show create timings form
    };

    const handleSaveTimings = async (formData) => {
        try {
            const userId = user._id;
            const response = await axios.post(`${API_URL}/all-timings`, {
                userId,
                timings: formData
            });
            if (response.data.success) {
                // Show success message or update UI accordingly
                console.log('Timings created successfully');
                fetchTimings(userId); // Fetch updated timings
                setShowCreateTimingsForm(false); // Hide create timings form after saving
            } else {
                // Show error message or update UI accordingly
                console.error('Failed to create timings:', response.data.error);
            }
        } catch (error) {
            console.error('Error creating timings:', error);
        }
    };

    const fetchVerifiedDiagnosticStatus = async (userId) => {
        try {
            const response = await axios.get(`${API_URL}/verified-diagnostic-status/${userId}`);
            if (response.data.success) {
                setVerifiedDiagnosticStatus(response.data.diagnosticStatus);
            } else {
                console.error('Failed to fetch verified diagnostic status:', response.data.error);
            }
        } catch (error) {
            console.error('Error fetching verified diagnostic status:', error);
        }
    };

    const handleToggleVerifiedDiagnosticStatus = async () => {
        const newStatus = !verifiedDiagnosticStatus; // Determine the new status
        const statusText = newStatus ? 'open' : 'close';
    
        // Show confirmation dialog
        Swal.fire({
            title: `Are you sure?`,
            text: `You are about to ${statusText} diagnostic status.`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, do it!',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const userId = user._id;
                    const response = await axios.put(`${API_URL}/verified-diagnostic-status/${userId}`, { diagnosticStatus: newStatus });
                    if (response.data.success) {
                        // Update the verified diagnostic status state with the new status
                        setVerifiedDiagnosticStatus(newStatus);
                        console.log('Verified diagnostic status updated successfully');
                        Swal.fire({
                            title: 'Success!',
                            text: `Diagnostic status updated to ${statusText}.`,
                            icon: 'success'
                        });
                    } else {
                        console.error('Failed to update verified diagnostic status:', response.data.error);
                        Swal.fire({
                            title: 'Error!',
                            text: 'Failed to update diagnostic status. Please try again later.',
                            icon: 'error'
                        });
                    }
                } catch (error) {
                    console.error('Error updating verified diagnostic status:', error);
                    Swal.fire({
                        title: 'Error!',
                        text: 'Failed to update diagnostic status. Please try again later.',
                        icon: 'error'
                    });
                }
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire({
                    title: 'Cancelled',
                    text: 'Diagnostic status update was cancelled.',
                    icon: 'info'
                });
            }
        });
    };

    const fetchCounts = async (userId, diagnosticCenterName) => {
        try {
            const categoryResponse = await axios.get(`${API_URL}/diagnostic-categories/count/${userId}/${diagnosticCenterName}`);
            if (categoryResponse.data.success) {
                setCategoryCount(categoryResponse.data.count);
            } else {
                console.error('Failed to fetch category count:', categoryResponse.data.error);
            }

            const labTestResponse = await axios.get(`${API_URL}/lab-test/count/${userId}/${diagnosticCenterName}`);
            if (labTestResponse.data.success) {
                setLabTestCount(labTestResponse.data.count);
            } else {
                console.error('Failed to fetch lab tests count:', labTestResponse.data.error);
            }
        } catch (error) {
            console.error('Error fetching counts:', error);
        }
    };

    return (
        <div className="container-scroller">
            <div>
                <Navbar />
                <div className="container-fluid page-body-wrapper">
                    <Sidebar loggedInUser={user} /> {/* Pass user state as props */}
                    <div className="main-panel">
                        {/* Render user-specific content using user state */}
                        <div className="content-wrapper">
                            {!showCreateTimingsForm && !timings && (
                                <div className="row grid-margin">
                                    <div className="page-header">
                                        <h3 className="page-title">
                                            Diagnostic Center Timings
                                        </h3>
                                    </div>
                                    <div className="col-12">
                                        <div className="alert alert-info" role="alert">
                                            No timings found for this diagnostic.
                                        </div>
                                        <button className="btn btn-primary" onClick={handleCreateTimings}>Create Timings</button>
                                    </div>
                                </div>
                            )}
                            {showCreateTimingsForm && (
                                <div className="row grid-margin">
                                    <div className="col-12">
                                        <div className="page-header">
                                            <h3 className="page-title">
                                                Diagnostic Center Timings
                                            </h3>
                                        </div>
                                        <div className="card">
                                            <div className="card-body">
                                                {/* Render create timings form */}
                                                <CreateTimingsForm onSave={handleSaveTimings} onCancel={() => setShowCreateTimingsForm(false)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div class="row">
                                <div class="col-12 grid-margin">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="justify-content-between align-items-center">
                                                <div className="page-header">
                                                    
                                                    <h3 className="page-title ">
                                                        <span style={{ paddingLeft: "5px", textTransform: 'capitalize' }}>
                                                            {user ? user.diagnosticCenterName : 'Guest'}
                                                            {user && user.isVerified && ( // Check if user exists and is verified
                                                                <i className="fas fa-check-circle" style={{ color: 'green', marginLeft: '5px' }}></i>
                                                            )}
                                                        </span>
                                                    </h3>
                                                    {/* Toggle button for verified diagnostic status */}
                                                    <div className="ml-3" style={{ display: 'flex', alignItems: 'center' }}>
                                                        <span className={verifiedDiagnosticStatus ? "toggle-text-open" : "toggle-text-closed"}>
                                                            {verifiedDiagnosticStatus ? (
                                                                <span style={{ paddingRight: "10px",fontSize:"18px", fontWeight:"800" ,color:"green" }}>Diagnostic Center Opened</span>
                                                            ) : (
                                                                <span style={{ paddingRight: "10px",fontSize:"18px", fontWeight:"800" ,color:"red" }}>Diagnostic Center Closed</span>
                                                            )}
                                                        </span>
                                                        <Toggle
                                                            checked={verifiedDiagnosticStatus}
                                                            onChange={handleToggleVerifiedDiagnosticStatus}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="row grid-margin">
                                <div className="col-12">
                                    <div className="card card-statistics">
                                        <div className="card-body">
                                            <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
                                                <div className="statistics-item">
                                                    <p>
                                                        <i className="icon-sm fa fa-list-alt mr-2"></i>
                                                        <Link to='/LabCategories' style={{ color: 'white' }}>Total Categories</Link>
                                                    </p>
                                                    <h2>{categoryCount}</h2>
                                                    <label className="badge badge-outline-success badge-pill">2.7% increase</label>
                                                </div>
                                                <div className="statistics-item">
                                                    <p>
                                                        <i className="icon-sm fas fa-utensils mr-2"></i>
                                                        <Link style={{ color: 'white' }} to='/LabTests'>Total Tests</Link>
                                                    </p>
                                                    <h2>{labTestCount}</h2>
                                                    <label className="badge badge-outline-success badge-pill">2.7% increase</label>
                                                </div>
                                                <div className="statistics-item">
                                                    <p>
                                                        <i className="icon-sm fa fa-user mr-2"></i>
                                                        Total Orders
                                                    </p>
                                                    <h2>2051.00</h2>
                                                    <label className="badge badge-outline-success badge-pill">2.7% increase</label>
                                                </div>
                                                <div className="statistics-item">
                                                    <p>
                                                        <i className="icon-sm fas fa-check-circle mr-2"></i>
                                                        Average Daily Orders
                                                    </p>
                                                    <h2>7500</h2>
                                                    <label className="badge badge-outline-success badge-pill">57% increase</label>
                                                </div>
                                                <div className="statistics-item">
                                                    <p>
                                                        <i className="icon-sm fas fa-chart-line mr-2"></i>
                                                        Sales
                                                    </p>
                                                    <h2>9000</h2>
                                                    <label className="badge badge-outline-success badge-pill">10% increase</label>
                                                </div>
                                                <div className="statistics-item">
                                                    <p>
                                                        <i className="icon-sm fas fa-circle-notch mr-2"></i>
                                                        Pending
                                                    </p>
                                                    <h2>7500</h2>
                                                    <label className="badge badge-outline-danger badge-pill">16% decrease</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="row grid-margin">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="page-header">
                                                <h3 className="page-title">
                                                    Diagnostic Center Details
                                                    {editingDiagnosticDetails ? (
                                                        <button className="btn btn-primary btn-rounded btn-sm ml-3" onClick={handleDiagnosticDetailsSave}>Save</button>
                                                    ) : (
                                                        <button className="btn btn-dark btn-rounded btn-sm ml-3" onClick={handleDiagnosticDetailsEdit}>Edit</button>
                                                    )}
                                                </h3>
                                            </div>
                                            {diagnosticDetails && !editingDiagnosticDetails && (
                                                <div className="table-responsive">
                                                    <table className="table">
                                                        <tbody>
                                                            <tr>
                                                                <td><strong>Diagnostic Name:</strong></td>
                                                                <td>{diagnosticDetails.diagnosticCenterName}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><strong>Diagnostic OwnerName:</strong></td>
                                                                <td>{diagnosticDetails.diagnosticOwnerName}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><strong>Mobile Number:</strong></td>
                                                                <td>{diagnosticDetails.diagnosticMobileNumber}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><strong>Diagnostic Description:</strong></td>
                                                                <td>{diagnosticDetails.diagnosticDescription}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            )}

                                            {editingDiagnosticDetails && (
                                                // Render a form for editing diagnostic details
                                                <div>
                                                    <form onSubmit={handleDiagnosticDetailsSave}>
                                                        <div className="form-group">
                                                            <label htmlFor="diagnosticCenterName">Diagnostic Name:</label>
                                                            <input type="text" className="form-control" id="diagnosticCenterName" name="diagnosticCenterName" value={formData.diagnosticCenterName} onChange={handleChange} disabled />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="diagnosticOwnerName">Diagnostic OwnerName:</label>
                                                            <input type="text" className="form-control" id="diagnosticOwnerName" name="diagnosticOwnerName" value={formData.diagnosticOwnerName} onChange={handleChange} disabled />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="diagnosticMobileNumber">Mobile Number:</label>
                                                            <input type="text" className="form-control" id="diagnosticMobileNumber" name="diagnosticMobileNumber" value={formData.diagnosticMobileNumber} onChange={handleChange} disabled />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="diagnosticDescription">Diagnostic Description:</label>
                                                            <input type="text" className="form-control" id="diagnosticDescription" name="diagnosticDescription" value={formData.diagnosticDescription} onChange={handleChange} />
                                                        </div>
                                                        <button type="submit" className="btn btn-primary">Save</button>
                                                        <button type="button" className="btn btn-secondary ml-2" onClick={() => setEditingDiagnosticDetails(false)}>Cancel</button>
                                                    </form>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {!showCreateTimingsForm && timings && (
                                <div className="row grid-margin">
                                    <div className="col-12">

                                        <div className="card">
                                            <div className="card-body">
                                                <div className="page-header">
                                                    <h3 className="page-title">
                                                        Diagnostic Center Timings
                                                    </h3>
                                                </div>
                                                {/* Render timings in table format if available */}
                                                <div className="table-responsive">
                                                    <table className="table table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th>Day</th>
                                                                <th>Open From</th>
                                                                <th>Open To</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {Object.entries(timings).map(([day, { from, to }]) => (
                                                                <tr key={day}>
                                                                    <td style={{ textTransform: 'capitalize' }}>{day}</td>
                                                                    <td>{editRow === day ? <input type="time" name="from" value={formData.from} onChange={(e) => handleChange(e)} /> : from}</td>
                                                                    <td>{editRow === day ? <input type="time" name="to" value={formData.to} onChange={(e) => handleChange(e)} /> : to}</td>
                                                                    <td>
                                                                        {editRow === day ? (
                                                                            <button className="btn btn-primary btn-rounded btn-sm" onClick={() => handleSubmit(day)}>Save</button>
                                                                        ) : (
                                                                            <button className="btn btn-dark btn-rounded btn-sm" onClick={() => handleEdit(day)}>Edit</button>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </div>
    );
}

// CreateTimingsForm component for creating timings
const CreateTimingsForm = ({ onSave, onCancel }) => {
    const [formData, setFormData] = useState({
        monday: { from: "", to: "" },
        tuesday: { from: "", to: "" },
        wednesday: { from: "", to: "" },
        thursday: { from: "", to: "" },
        friday: { from: "", to: "" },
        saturday: { from: "", to: "" },
        sunday: { from: "", to: "" }
    });

    const handleChange = (e, day) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [day]: {
                ...prevState[day],
                [name]: value
            }
        }));
    };

    const handleSave = () => {
        // Check if any timing field is empty
        const isEmpty = Object.values(formData).some(({ from, to }) => from === "" || to === "");
        if (isEmpty) {
            alert("Please fill in all timing fields.");
            return;
        }

        onSave(formData);
    };

    return (
        <form>
            <table className="table">
                <thead>
                    <tr>
                        <th>Day</th>
                        <th>From</th>
                        <th>To</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(formData).map(([day, { from, to }]) => (
                        <tr key={day}>
                            <td>{day.charAt(0).toUpperCase() + day.slice(1)}</td>
                            <td>
                                <input type="time" name="from" value={from} onChange={(e) => handleChange(e, day)} required />
                            </td>
                            <td>
                                <input type="time" name="to" value={to} onChange={(e) => handleChange(e, day)} required />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <button type="button" className="btn btn-primary" onClick={handleSave}>Save</button>
            <button type="button" className="btn btn-secondary ml-2" onClick={onCancel}>Cancel</button>
        </form>
    );
}


export default LabTechnician;
