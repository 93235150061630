import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import { API_URL } from '../../constants';


const Footer = () => {
    const currentYear = new Date().getFullYear(); // Get the current year
    const navigate = useNavigate();

    return (
        <footer class="footer" style={{backgroundColor:'#fff'}}>
        <div class="d-sm-flex justify-content-center justify-content-sm-between">
          <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © {currentYear} . All rights reserved.</span>
          <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">AI Hawks Software Solutions Private Limited</span>
        </div>
      </footer>
    );
}

export default Footer;
